<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// icons
import PlusIcon from "@/assets/icons/plus-icon.vue";
import EyeOpenedIcon from "@/assets/icons/eye-opened-icon.vue";
import DeleteIcon from "@/assets/icons/delete-icon.vue";
import InfoIcon from "@/assets/icons/info-icon.vue";
import ChevronLeftSmallIcon from "@/assets/icons/chevron-left.vue";
import ChevronRightSmallIcon from "@/assets/icons/chevron-right.vue";
// helpers
import formatNumber from "@commons/helpers/formatters/formatNumber";
// style
import "@/assets/styles/algorithm_card.scss";
import "@/assets/styles/algo_allocation_modal.scss";
// composables
import { useCustomToast } from "@/composables/toast/useCustomToast";
//components
import AlgorithmReallocationModal from "@/components/commons/AlgorithmReallocationModal.vue";
import ConfirmationModal from "@/components/commons/ConfirmationModal.vue";

const router = useRouter();
const store = useStore();
const emit = defineEmits(["nextItem", "prevItem"]);
const props = defineProps({
  algorithm: Object,
});
const algoOpenedId = ref(null);
const chartOptions = {
  colors: ["#0EB770"],

  chart: {
    type: "area",
    height: 75,
    sparkline: {
      enabled: true,
    },
  },
  stroke: {
    curve: "smooth",
    width: 1,
  },
  fill: {
    colors: ["#0EB770", "#0EB77000"],
    opacity: 0.1,
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: undefined,
      inverseColors: false,
      opacityFrom: 0.3,
      opacityTo: 0,
      stops: [0, 100],
      colorStops: [],
    },
  },
  label: {
    enabled: false,
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex }) {
      return `<div class="chart_tooltip">
        <span>$ ${formatNumber(series[seriesIndex][dataPointIndex])}</span>
        </div>`;
    },
    followCursor: false,
    style: {
      fontSize: "5px",
      fontFamily: undefined,
    },
  },
  markers: {
    size: 0,
    colors: undefined,
    strokeColors: "#fff",
    strokeWidth: 0,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    onClick: undefined,
    onDblClick: undefined,
    showNullDataPoints: true,
    hover: {
      size: undefined,
      sizeOffset: 3,
    },
  },
};
const confirmation_modal_info = ref({
  title: "",
  message: "",
  confirmText: "",
});

const nextItemHandler = () => {
  emit("nextItem");
};
const prevItemHandler = () => {
  emit("prevItem");
};
const classByRisk = (risk) => {
  return risk === "high" ? "error" : risk === "medium" ? "warning" : "success";
};
const computedPerformance = computed(() => {
  return [{ data: props?.algorithm?.perfomrance }];
});
const algorithmsDataLoading = computed(
  () => store.getters["dashboard/algorithmsList"]._status._on_loading
);
const closeAlgoLoading = computed(
  () => store.state.algorithms.closeAllocation._status._on_loading
);

const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
  }
};

const reAllocateHandler = () => {
  modalHandler(true, "algo_reallocate");
};
const onAlgoReAllocatedHandler = () => {};

const goToDetail = (id) => {
  router.push(`/algorithms/${id}`);
};

const openCloseAllModal = (id) => {
  algoOpenedId.value = id;
  confirmation_modal_info.value = {
    title: "Close Investment in the Algorithm",
    message:
      "With this action, you will withdraw your entire investment from the algorithm. The available balance will be transferred to your wallet within the next 48 hours.",
    confirmText: "Delete",
  };
  modalHandler(true, "algo_close_confirmation");
};

const CloseAllPosition = async () => {
  const response = await store.dispatch("algorithms/closeAllocatedAlgorithm", {
    params: algoOpenedId.value,
  });
  if (response.success) {
    useCustomToast({
      text: response.data.message,
      type: "success",
    });
    modalHandler(false, "algo_close_confirmation");
  } else {
    useCustomToast({
      text: response.data.message,
      type: "error",
    });
  }
};
</script>
<template>
  <div class="algorithm_card_container">
    <div class="algorithm_actions">
      <PlusIcon @click="reAllocateHandler" />
      <EyeOpenedIcon @click="goToDetail(algorithm.id)" />
      <DeleteIcon @click="openCloseAllModal(algorithm.id)" />
    </div>
    <div :class="['algorithm_graph', { skeleton: algorithmsDataLoading }]">
      <apexchart
        type="area"
        height="75"
        :options="chartOptions"
        :series="computedPerformance"
      ></apexchart>
    </div>
    <div class="algorithm_details">
      <div
        :class="[
          'algorithm_details__title',
          { skeleton: algorithmsDataLoading },
        ]"
      >
        {{ algorithm?.name }}
      </div>
      <div
        :class="[
          'algorithm_details__desc',
          { skeleton: algorithmsDataLoading },
        ]"
      >
        BWFS Neural accounts for <span class="is_number">30%</span> of your $1
        million portfolio, with a current exposure of
        <span class="is_number">20%</span>, equivalent to
        <span class="is_number">$250,000.</span>
      </div>
    </div>
    <div class="algorithm_analysis">
      <div class="analysis_box striped">
        <div class="title">Minimum investment</div>
        <div :class="['detail is_number', { skeleton: algorithmsDataLoading }]">
          ${{ formatNumber(algorithm?.minimumInvestment) }}
        </div>
      </div>

      <div class="analysis_box">
        <div class="title">Community risk</div>
        <div :class="['risk_detail', { skeleton: algorithmsDataLoading }]">
          <div :class="['risk', classByRisk(algorithm?.communityRisk)]">
            {{ algorithm?.communityRisk }}
          </div>
          <div class="info_icon">
            <InfoIcon />
          </div>
        </div>
      </div>

      <div class="analysis_box striped">
        <div class="title">Followers of the algorithm</div>
        <div :class="['detail is_number', { skeleton: algorithmsDataLoading }]">
          {{ formatNumber(algorithm?.followers, 0) }}
        </div>
      </div>

      <div class="analysis_box">
        <div class="title">Allocation</div>
        <div :class="['detail is_number', { skeleton: algorithmsDataLoading }]">
          ${{ formatNumber(algorithm?.allocation) }}
        </div>
      </div>
      <div class="algorithm_card_footer-tablet">
        <div>
          <ChevronLeftSmallIcon @click="prevItemHandler" />
          <ChevronRightSmallIcon @click="nextItemHandler" />
        </div>
      </div>
    </div>
    <div class="algorithm_card_footer">
      <div>
        <ChevronLeftSmallIcon @click="prevItemHandler" />
        <ChevronRightSmallIcon @click="nextItemHandler" />
      </div>
    </div>
    <AlgorithmReallocationModal
      @algoReAllocated="onAlgoReAllocatedHandler"
      ref="algoReallocationModal"
    />
    <ConfirmationModal
      confirmation_modal_name="algo_close_confirmation"
      :title="confirmation_modal_info.title"
      :message="confirmation_modal_info.message"
      :confirmText="confirmation_modal_info.confirmText"
      cancelText="Cancel"
      :confirmButtonLoading="closeAlgoLoading"
      @onConfirm="CloseAllPosition"
      @onCancel="modalHandler"
    />
  </div>
</template>

<style>
.chart_tooltip {
  font-size: 10px;
  background: #ffffff00;
  color: #898c9b;
  font-family: Inconsolata;
  padding: 4px 6px;
  backdrop-filter: blur(10px);
}
</style>
