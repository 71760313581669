<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.62488 7.16317L6.75613 4.29442C6.63903 4.17801 6.48062 4.11267 6.3155 4.11267C6.15039 4.11267 5.99198 4.17801 5.87488 4.29442C5.8163 4.35252 5.7698 4.42164 5.73807 4.49781C5.70634 4.57397 5.69 4.65566 5.69 4.73817C5.69 4.82067 5.70634 4.90236 5.73807 4.97853C5.7698 5.05469 5.8163 5.12381 5.87488 5.18192L8.74988 8.04442C8.80846 8.10252 8.85495 8.17164 8.88668 8.24781C8.91841 8.32397 8.93475 8.40566 8.93475 8.48817C8.93475 8.57067 8.91841 8.65237 8.88668 8.72853C8.85495 8.80469 8.80846 8.87382 8.74988 8.93192L5.87488 11.7944C5.75719 11.9113 5.69074 12.0701 5.69015 12.236C5.68957 12.4018 5.75489 12.5611 5.87175 12.6788C5.98861 12.7965 6.14744 12.8629 6.31329 12.8635C6.47914 12.8641 6.63844 12.7988 6.75613 12.6819L9.62488 9.81317C9.976 9.4616 10.1732 8.98504 10.1732 8.48817C10.1732 7.99129 9.976 7.51473 9.62488 7.16317Z"
      fill="currentColor"
    />
  </svg>
</template>
