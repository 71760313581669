<script setup>
import { computed, onMounted, ref } from "vue";
import { Tooltip } from "bootstrap";
import { useStore } from "vuex";

//icons
import ArrowIncrease from "@/assets/icons/arrow-increase.vue";
import ChevronRight from "@/assets/icons/chevron-right.vue";
import ChevronLeft from "@/assets/icons/chevron-left.vue";
import InfoIcon from "@/assets/icons/info-icon.vue";

//helpers
import formatNumber from "@commons/helpers/formatters/formatNumber";

//styles
import "@/assets/styles/algorithm_performance_card.scss";

const currentItem = ref(0);
const store = useStore();

const setupTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip-nav"]'
  );
  tooltipTriggerList.forEach((el) => {
    const title = el.getAttribute("data-bs-title");
    if (title) {
      new Tooltip(el);
    }
  });
};

const algoPerformance = computed(
  () => store.getters["dashboard/algorithm_performance"]._data?.data
);

const algoPerformanceLoading = computed(
  () => store.getters["dashboard/algorithm_performance"]._status._on_loading
);

const nextItem = () => {
  if (algoPerformance.value.length - 1 > currentItem.value) {
    currentItem.value = currentItem.value + 1;
  } else {
    currentItem.value = 0;
  }
};
const prevItem = () => {
  if (currentItem.value > 0) {
    currentItem.value = currentItem.value - 1;
  } else {
    currentItem.value = algoPerformance.value.length - 1;
  }
};

onMounted(() => {
  setupTooltips();
});
</script>

<template>
  <div class="component_algo_performance_card">
    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between">
          <h1 :class="{ skeleton: algoPerformanceLoading }">
            {{ algoPerformance[currentItem]?.name }}
          </h1>

          <span
            role="button"
            data-bs-toggle="tooltip-nav"
            data-bs-placement="top"
            data-bs-title="Assesses the specific performance of this algorithm within the account."
            ><InfoIcon
          /></span>
        </div>
      </div>
      <div class="card-body">
        <div class="performance-card-container">
          <div class="value">
            <p :class="{ skeleton: algoPerformanceLoading }">
              ${{ formatNumber(algoPerformance[currentItem]?.performance) }}
            </p>
            <ArrowIncrease v-if="!algoPerformanceLoading" />
            <span :class="{ skeleton: algoPerformanceLoading }"
              >{{
                formatNumber(
                  algoPerformance[currentItem]?.performance_percentage
                )
              }}%</span
            >
          </div>
          <div>
            <p :class="{ skeleton: algoPerformanceLoading }">
              Based on a total portfolio of
              <span
                >${{
                  formatNumber(algoPerformance[currentItem]?.portfolio_total)
                }}</span
              >
              distributed in the different algorithms in which it has invested.
            </p>
            <div class="footer-performance-card">
              <chevron-left role="button" @click="prevItem" />
              <ChevronRight role="button" @click="nextItem" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
