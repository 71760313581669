import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const invalidCredentialsLogout = () => ({
  success: false,
  data: {
    message: 'An error has ocuured',
  },
});

const successfullLogout = () => ({
  success: true,
  data: {
    message: 'Logout successfully',
  },
});

const logoutScenarios = [successfullLogout, invalidCredentialsLogout];

export const mockLogout = () => {
  const rand = randomNumber(0, logoutScenarios.length);

  if (typeof logoutScenarios[rand] !== 'undefined') {
    return logoutScenarios[rand]();
  }

  return successfullLogout();
};
