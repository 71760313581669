import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const invalidCredentialsForgot = () => ({
  success: false,
  message: "Email address doesn't exists",
});

const successfullForgot = () => ({
  success: true,
  message: 'Code sent successfully.',
});

const forgotScenarios = [successfullForgot, invalidCredentialsForgot];

export const mockForgot = () => {
  const rand = randomNumber(0, forgotScenarios.length);

  if (typeof forgotScenarios[rand] !== 'undefined') {
    return forgotScenarios[rand]();
  }

  return successfullForgot();
};
