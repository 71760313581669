<template>
  <div class="icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_177_7903)">
        <path
          d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
          fill="#50e7ff"
        />
        <path
          d="M11.9999 10.0002H10.9999C10.7347 10.0002 10.4803 10.1056 10.2928 10.2931C10.1052 10.4807 9.99988 10.735 9.99988 11.0002C9.99988 11.2655 10.1052 11.5198 10.2928 11.7074C10.4803 11.8949 10.7347 12.0002 10.9999 12.0002H11.9999V18.0002C11.9999 18.2655 12.1052 18.5198 12.2928 18.7074C12.4803 18.8949 12.7347 19.0002 12.9999 19.0002C13.2651 19.0002 13.5194 18.8949 13.707 18.7074C13.8945 18.5198 13.9999 18.2655 13.9999 18.0002V12.0002C13.9999 11.4698 13.7892 10.9611 13.4141 10.586C13.039 10.211 12.5303 10.0002 11.9999 10.0002Z"
          fill="#50e7ff"
        />
        <path
          d="M12 7.99963C12.8284 7.99963 13.5 7.32806 13.5 6.49963C13.5 5.67121 12.8284 4.99963 12 4.99963C11.1716 4.99963 10.5 5.67121 10.5 6.49963C10.5 7.32806 11.1716 7.99963 12 7.99963Z"
          fill="#50e7ff"
        />
      </g>
      <defs>
        <clipPath id="clip0_177_7903">
          <rect width="24" height="24" fill="#50e7ff" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
