<script setup>
import { nextTick, onBeforeUnmount, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
//components
import BaseChart from "@commons/components/charts/BaseChart";
import CustomDateModal from "./Modals/CustomDateModal.vue";
import SettingsOvervieModal from "./Modals/SettingsOverviewModal.vue";

//helpers
import formatNumber from "@commons/helpers/formatters/formatNumber";

//icons
import SettingsIcon from "@/assets/icons/settings-icon.vue";

//styles
import "@/assets/styles/overview_card.scss";

const store = useStore();

const chartData = computed(() => store.getters["dashboard/overviewChart"]);
const chartRef = ref(null);

const series = computed(() => [
  {
    name: "Exposure",
    type: "column",
    data: chartData.value.map((item) => item.exposure),
  },
  {
    name: "Daily NAV",
    type: "area",
    data: chartData.value.map((item) => item.nav),
  },
  {
    name: "Maximum NAV",
    type: "line",
    data: chartData.value.map((item) => item.maxMinNav),
  },
]);

const chartOptions = computed(() => ({
  chart: {
    height: 280,
    type: "line",
    stacked: false,
    toolbar: {
      show: false,
    },
  },
  colors: ["#CACBD0", "#3A3C46", "#0EB770"],
  stroke: {
    width: [8, 2, 2],
    curve: "smooth",
    dashArray: [0, 0, 1],
  },
  plotOptions: {
    bar: {
      columnWidth: "8px",
      color: "#CACBD0",
    },
  },
  legend: {
    show: true,
  },
  fill: {
    type: "solid",
    opacity: [1, 0.1, 1],
  },
  grid: {
    borderColor: "#232429",
  },
  labels: chartData.value.map((item) => item.date),
  markers: {
    size: 0,
  },
  xaxis: {
    type: "datetime",
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      format: "MMM dd",
      style: {
        colors: "#898C9B",
        fontSize: "10",
        fontFamily: "Roboto",
      },
      offsetY: -4,
      offsetX: 0,
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    min: 0,
    labels: {
      style: {
        colors: "#898C9B",
        fontSize: "10",
        fontFamily: "Roboto",
      },
      formatter: function (num) {
        if (num >= 1e12) {
          return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
        } else if (num >= 1e9) {
          return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
        } else if (num >= 1e6) {
          return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
        } else if (num >= 1e3) {
          return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
        } else {
          return num.toString();
        }
      },
    },
  },
  tooltip: {
    enabled: true,
    custom: function ({ dataPointIndex, w }) {

      const date = new Date(w.config.labels[dataPointIndex]);
      const options = { month: 'short', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      
      const nav = chartData.value[dataPointIndex]["nav"];
      const exposure = chartData.value[dataPointIndex]["exposure"];
      const exposure_percentage =
        chartData.value[dataPointIndex]["exposure_percentage"];
      const maxMinNav = chartData.value[dataPointIndex]["maxMinNav"];
      return `<div class="overview-chart">
          <div class="title">${formattedDate}</div>
          <div class="chart-col">
            <div class="chart-row"><div class="label"><span class="legend gray"></span>Daily NAV</div><div class="value ${nav > 0 ? "green" : nav < 0 ? "red" : ""}">$${formatNumber(nav)}</div></div>
            <div class="chart-row"><div class="label"><span class="legend white"></span>Exposure</div><div class="value">$${exposure} / ${exposure_percentage}%</div></div>
            <div class="chart-row"><div class="label"><span class="legend green"></span>Max NAV</div><div class="value ${maxMinNav > 0 ? "green" : maxMinNav < 0 ? "red" : ""}">$${formatNumber(maxMinNav)}</div></div>
          </div>
        </div>`;
    },
    x: {
      show: false,
    },
  },
}));

const handleFocusNavCard = () => {
  nextTick(() => {
    const navCard = document.getElementById("navCard");

    if (navCard && typeof navCard.scrollIntoView === "function") {
      navCard.classList.add("highlight");
      setTimeout(() => {
        navCard.classList.remove("highlight");
      }, 5000);
    }
  });
};

onBeforeUnmount(() => {
  window.removeEventListener("focus-nav-card", handleFocusNavCard);
});

onMounted(() => {
  window.addEventListener("focus-nav-card", handleFocusNavCard);
});

const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
  }
};

const applyFilters = (filters) => {
  if (filters.showDailyNav) {
    chartRef.value.showSeries("Daily NAV");
  } else {
    chartRef.value.hideSeries("Daily NAV");
  }

  if (filters.showExposure) {
    chartRef.value.showSeries("Exposure");
  } else {
    chartRef.value.hideSeries("Exposure");
  }

  if (filters.showMaxMinNav) {
    chartRef.value.showSeries("Maximum or minimum NAV");
  } else {
    chartRef.value.hideSeries("Maximum or minimum NAV");
  }
};

const handleChartMounted = (chartContext) => {
  chartRef.value = chartContext;
};
</script>

<template>
  <div class="component_dahsborard_daily_nav">
    <div class="h-100">
      <div class="card h-100" id="navCard">
        <div class="card-header">
          <div>Overview</div>
          <div class="filter-container">
            <div class="filter">
              <div>1D</div>
              <div>7D</div>
              <div>1M</div>
              <div>3M</div>
              <div class="active">1Y</div>
              <div role="button" @click="modalHandler(true, 'custom_date')">
                Custom
              </div>
            </div>

            <span @click="modalHandler(true, 'settings_overview')"
              ><SettingsIcon
            /></span>
          </div>
        </div>

        <div class="card-body">
          <BaseChart
            :ref="chartRef"
            type="line"
            height="280"
            width="99%"
            :options="chartOptions"
            :series="series"
            @mounted="(chartContext) => handleChartMounted(chartContext)"
          />
        </div>
      </div>
    </div>
    <CustomDateModal />
    <SettingsOvervieModal :applyFilters="applyFilters" />
  </div>
</template>
