import randomNumber from '@commons/helpers/generators/generateRandomNumber';
const invalidReAllocation = () => ({
  success: false,
  data: {
    message: 'Your investment in the algorithm could not be increased. Please try again later.',
  },
});
const successfullReAllocation = () => ({
  success: true,
  data: {
    message: 'Your investment in the algorithm has been successfully increased.',
  },
});
const reAllocationScenarios = [successfullReAllocation, invalidReAllocation];
export const mockReAlgorithmAllocation = () => {
  const rand = randomNumber(0, reAllocationScenarios.length);
  if (typeof reAllocationScenarios[rand] !== 'undefined') {
    return reAllocationScenarios[rand]();
  }
  return successfullReAllocation();
};
