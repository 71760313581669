<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  iconPosition: {
    default: () => "right",
    type: String,
  },
  inputGroupClass: {
    default: () => "input-group mb-3",
    type: [Object, String],
  },
  modelValue: [Number, String],
  label: [String],
  placeholder: [String],
  id: [String],
  name: [String],
  autocomplete: [String],
  readOnly: Boolean,
  type: String,
});
const emit = defineEmits(["update:modelValue", "iconClick"]);

const innerValue = ref(props.modelValue);

const handleClick = () => {
  emit("iconClick");
};

watch(
  () => props.modelValue,
  (newValue) => {
    innerValue.value = newValue;
  }
);

watch(innerValue, (newValue) => {
  emit("update:modelValue", newValue);
});
</script>

<template>
  <label class="form-label">{{ label }}</label>
  <div class="input-group" :class="inputGroupClass">
    <span
      v-if="iconPosition == 'left'"
      class="input-group-text text-white"
      type="button"
      @click="handleClick"
    >
      <slot name="inputIcon"></slot>
    </span>
    <input
      v-model="innerValue"
      class="form-control"
      :type="props.type ? props.type : 'text'"
      :placeholder="placeholder"
      :readonly="readOnly"
      :autocomplete="autocomplete"
    />
    <span
      v-if="iconPosition == 'right'"
      class="input-group-text text-white"
      type="button"
      @click="handleClick"
    >
      <slot name="inputIcon"></slot>
    </span>
  </div>
</template>
