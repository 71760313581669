<template>
  <div class="icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.9977 12.5714V6.28571C10.9977 5.70952 11.4479 5.2381 11.9982 5.2381C12.5485 5.2381 12.9988 5.70952 12.9988 6.28571V12.5714C12.9988 13.1476 12.5485 13.619 11.9982 13.619C11.4479 13.619 10.9977 13.1476 10.9977 12.5714ZM11.9982 14.6667C11.1678 14.6667 10.4974 15.3686 10.4974 16.2381C10.4974 17.1076 11.1678 17.8095 11.9982 17.8095C12.8287 17.8095 13.4991 17.1076 13.4991 16.2381C13.4991 15.3686 12.8287 14.6667 11.9982 14.6667ZM23.5846 19.779C22.8843 21.1933 21.4134 22 19.5724 22H4.43405C2.58303 22 1.12222 21.1933 0.421837 19.779C-0.288556 18.3543 -0.0884454 16.5314 0.922113 15.0019L8.96656 1.67619C9.67695 0.607619 10.7976 0 11.9982 0C13.1989 0 14.3195 0.607619 14.9999 1.64476L23.0844 15.0229C24.0949 16.5524 24.285 18.3648 23.5746 19.779H23.5846ZM21.4335 16.1962C21.4335 16.1962 21.4134 16.1752 21.4134 16.1543L13.339 2.79714C13.0488 2.36762 12.5485 2.09524 11.9982 2.09524C11.4479 2.09524 10.9477 2.36762 10.6375 2.83905L2.58303 16.1543C1.96269 17.0762 1.82261 18.0819 2.18281 18.8048C2.533 19.5171 3.33345 19.9048 4.42405 19.9048H19.5524C20.643 19.9048 21.4435 19.5171 21.7937 18.8048C22.1539 18.0819 22.0138 17.0762 21.4234 16.1962H21.4335Z"
        fill="#F1FF4D"
      />
    </svg>
  </div>
</template>
