import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import { algorithmsName, riskLevel } from '@/config/constants';

export default () => ({
  success: true,
  data: [
    {
      pnl: 57.777594,
      pnl_percentage: 0.070316,
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      investors: randomNumber(100, 1000),
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      win_loss_ratio: randomNumber(0, 10),
      max_drawdown: randomNumber(0, 100000),
      max_drawdown_percentage: 55.3,
      gains_days: randomNumber(0, 100),
      losses_days: randomNumber(0, 100),
      exposure: 1054.5936754332413,
      exposure_percentage: 68.61,
      is_active: 0,
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 100009, 20008, 50000, 25000, 20800, 11000, 52000, 90000, 10000, 40000, 51000, 42000,
        20000,
      ],
      description:
        'BWFS Neural accounts for 20% of your $1 million portfolio, with a current exposure of 10%, equivalent to $100,000.',
      minimumInvestment: 25000,
    },
    {
      pnl: -739.04,
      pnl_percentage: -3.69,
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      investors: randomNumber(100, 1000),
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      win_loss_ratio: randomNumber(0, 10),
      max_drawdown: randomNumber(0, 100000),
      max_drawdown_percentage: 55.3,
      gains_days: randomNumber(0, 100),
      losses_days: randomNumber(0, 100),
      exposure: 520951.0,
      exposure_percentage: 52.14,
      is_active: 1,
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 100009, 20008, 50000, 25000, 20800, 11000, 52000, 90000, 10000, 40000, 51000, 42000,
        20000,
      ],
      description:
        'BWFS Neural accounts for 20% of your $1 million portfolio, with a current exposure of 10%, equivalent to $100,000.',
      minimumInvestment: 25000,
    },
    {
      pnl: -478.046997,
      pnl_percentage: -0.732516,
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      investors: randomNumber(100, 1000),
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      win_loss_ratio: randomNumber(0, 10),
      max_drawdown: randomNumber(0, 100000),
      max_drawdown_percentage: 55.3,
      gains_days: randomNumber(0, 100),
      losses_days: randomNumber(0, 100),
      exposure: 790.245094,
      exposure_percentage: 1.848397,
      is_active: 1,
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 100009, 20008, 50000, 25000, 20800, 11000, 52000, 90000, 10000, 40000, 51000, 42000,
        20000,
      ],
      description:
        'BWFS Neural accounts for 20% of your $1 million portfolio, with a current exposure of 10%, equivalent to $100,000.',
      minimumInvestment: 25000,
    },
    {
      pnl: -546.910014,
      pnl_percentage: -0.641424,
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      investors: randomNumber(100, 1000),
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      win_loss_ratio: randomNumber(0, 10),
      max_drawdown: randomNumber(0, 100000),
      max_drawdown_percentage: 55.3,
      gains_days: randomNumber(0, 100),
      losses_days: randomNumber(0, 100),
      exposure: 1486.6784792845845,
      exposure_percentage: 46.78,
      is_active: 0,
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 100009, 20008, 50000, 25000, 20800, 11000, 52000, 90000, 10000, 40000, 51000, 42000,
        20000,
      ],
      description:
        'BWFS Neural accounts for 20% of your $1 million portfolio, with a current exposure of 10%, equivalent to $100,000.',
      minimumInvestment: 25000,
    },
    {
      pnl: 760.438,
      pnl_percentage: 2.2245,
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      investors: randomNumber(100, 1000),
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      win_loss_ratio: randomNumber(0, 10),
      max_drawdown: randomNumber(0, 100000),
      max_drawdown_percentage: 55.3,
      gains_days: randomNumber(0, 100),
      losses_days: randomNumber(0, 100),
      exposure: 34184.512584,
      exposure_percentage: 75.1761,
      is_active: 0,
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 100009, 20008, 50000, 25000, 20800, 11000, 52000, 90000, 10000, 40000, 51000, 42000,
        20000,
      ],
      description:
        'BWFS Neural accounts for 20% of your $1 million portfolio, with a current exposure of 10%, equivalent to $100,000.',
      minimumInvestment: 25000,
    },
    {
      pnl: 111985.083603,
      pnl_percentage: 119.029234,
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      investors: randomNumber(100, 1000),
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      win_loss_ratio: randomNumber(0, 10),
      max_drawdown: randomNumber(0, 100000),
      max_drawdown_percentage: 55.3,
      gains_days: randomNumber(0, 100),
      losses_days: randomNumber(0, 100),
      exposure: 34184.512584,
      exposure_percentage: 78.507,
      is_active: 1,
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 100009, 20008, 50000, 25000, 20800, 11000, 52000, 90000, 10000, 40000, 51000, 42000,
        20000,
      ],
      description:
        'BWFS Neural accounts for 20% of your $1 million portfolio, with a current exposure of 10%, equivalent to $100,000.',
      minimumInvestment: 25000,
    },
  ],
  paginate: {
    current_page: 1,
    total_pages: 138,
    per_page: 10,
    records: 1375,
  },
});
