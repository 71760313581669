import { algorithmsName } from '@/config/constants';
import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import randomDecimal from '@commons/helpers/generators/generateRandomDecimal';

const netAssetValueMock = () => ({
  success: true,
  data: {
    highest_nav: randomNumber(0, 100000),
    lowest_nav: randomNumber(0, 100000),
    total_nav: randomNumber(0, 100000),
    algorithm_list: [
      {
        id: 'algo1',
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
      },
      {
        id: 'algo2',
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
      },
      {
        id: 'algo3',
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
      },
      {
        id: 'algo4',
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
      },
    ],
    algorithm_name: algorithmsName[randomNumber(0, algorithmsName.length)],
    percentage: randomDecimal(100, 10),
    portfolio: randomNumber(0, 1000000),
    current_exposure_percentage: randomDecimal(10, 10),
    current_exposure: randomDecimal(1000000, 10),
    chart: {
      serie1: randomNumber(0, 1000),
      serie2: randomNumber(0, 1000),
    },
  },
});

export default netAssetValueMock;
