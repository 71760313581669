import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import { riskLevel, algorithmsName } from '@/config/constants';

export default () => ({
  success: true,
  data: {
    id: randomNumber(100000, 10000000000),
    name: algorithmsName[randomNumber(0, algorithmsName.length)],
    risk: riskLevel[randomNumber(0, riskLevel.length)],
    performance: {
      profit_rate: randomNumber(0, 10),
      profit_days: randomNumber(0, 100),
      days_with_losses: randomNumber(0, 10),
    },
    initial_investment: randomNumber(0, 100000),
    current_investment: randomNumber(0, 100000),
    monthly_yield: randomNumber(0, 10),
  },
});
