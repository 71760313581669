export async function loadLayoutMiddleware(route) {
  try {
    let layout = route.meta.layout;
    let layoutComponent = await import(`@/components/layouts/${layout}.vue`);
    route.meta.layoutComponent = layoutComponent.default;
  } catch (e) {
    let layout = 'auth';
    let layoutComponent = await import(`@/components/layouts/${layout}.vue`);
    route.meta.layoutComponent = layoutComponent.default;
    console.error('Error occurred in processing of layouts: ', e);
  }
}
