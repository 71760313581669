export default (value, fractionDigits = 2) => {
  const num = Number(value)
  if (isNaN(num)) {
    return value
  }

  return num.toLocaleString('en-US', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  })
}
