import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const invalidCredentialsLogin = () => ({
  success: false,
  message: 'Invalid credentials',
});

const successfullLogin = () => ({
  success: true,
  data: {
    token: '8r5493c386e6bat469f4136989724d60',
    email: 'user@creedandbear.com',
    totp_token:
      'gAAAAABk-wbSc54Fgm5k0l0yvub_AXFyLmYsry_1ZLhp7d-mlZBzvmxmmgbvWy7bXs8b9_Jp7RgIu4FlJATquBzYhJ85Jo_qt9nZnlq0ZcT0nzZr-3NImRDhOg_CV23LGNM1TZFcEpdC',
    created_at: '1970-01-01 00:00:00',
    updated_at: '1970-01-01 00:00:00',
  },
  token:
    'Bearer OGI0ZmRmMDQ0YjcwNDI4M2VhODQ5OTc0YTE1NWMxZWQ3NGYzZmY3ZmI3OWU3ZDczZDhhMTliOWQ2ZDM3OGJiMWY2am9yeVZsK3k2b1lsK0hpaGVCTnpCV01CQUdCeXFHU000OUFnRUdCU3VCQkFBS0EwSUFCSG1BM2Jla05NdS83OUFkc0tDcVFEMk0zdGp1eEV2QTd1Z25ZUFAxTGFwUHh4S0NOdVFTdWdmVDBpN2JIWDJSUGVXUmRIcEw4RW9JOGF3Q3J2cUx4TUU9',
  cookie_auth: 'xbd9EDJzAbEJazefpPRV7VnNlFTLBgAJ',
  authorized: true,
});

const loginScenarios = [successfullLogin, invalidCredentialsLogin];

export const mockLogin = () => {
  const rand = randomNumber(0, loginScenarios.length);

  if (typeof loginScenarios[rand] !== 'undefined') {
    return loginScenarios[rand]();
  }

  return successfullLogin();
};
