<script setup>
import ArrowDownIcon from "@commons/assets/images/icons/arrow-down-icon.vue";
import ArrowUpIcon from "@commons/assets/images/icons/arrow-up-icon.vue";
import AlertHorizontal from "../alerts/AlertHorizontal.vue";

const emit = defineEmits(["sortChange"]);
defineProps({
  applySkeleton: {
    default: () => true,
    type: Boolean,
  },
  columns: {
    type: Array,
    required: true,
  },
  data: {
    type: [Array, Object],
  },
  isStriped: {
    default: () => true,
    type: Boolean,
  },
  skeletonRows: {
    default: () => 8,
    type: [Number],
  },
  status: Object,
  headerWithIcon: {
    default: () => false,
    type: Boolean,
  },
});

const getDataBsPlacement = (column) => {
  return column.titleTooltip ? column.titleTooltip.dataBsPlacement || "top" : null;
};

const getDataBsTitle = (column) => {
  return column.titleTooltip ? column.titleTooltip.title : null;
};

const getDataBsToggle = (column) => {
  return column.titleTooltip ? column.titleTooltip.dataBsToggle || "tooltip" : null;
};
const sortHandler = async (field) => {
  if (field.sortable) {
    emit("sortChange", field);
  }
};
</script>

<template>
  <div class="table-responsive">
    <table
      :class="{
        table: true,
        'dynamic-table': true,
        'table-striped': isStriped,
      }"
      class="align-middle"
    >
      <thead>
        <tr>
          <th
            v-for="(column, i) in columns"
            :key="i"
            :data-bs-toggle="getDataBsToggle(column)"
            :data-bs-placement="getDataBsPlacement(column)"
            :data-bs-title="getDataBsTitle(column)"
          >
            <div class="th_container" @click="sortHandler(column)">
              <div class="th_text">
                {{ column.title }}
              </div>
              <div v-if="headerWithIcon && column?.sortable">
                <ArrowUpIcon v-if="column?.sortItem.order == 'asc'" />
                <ArrowDownIcon v-if="column?.sortItem.order == 'desc'" />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="applySkeleton && status?._on_loading">
          <tr v-for="(row, i) in skeletonRows" :key="i">
            <td v-for="(column, j) in columns" :key="j">
              <div class="skeleton"></div>
            </td>
          </tr>
        </template>
        <template v-else-if="status?._on_ready">
          <template v-if="data.length">
            <tr v-for="row in data" :key="row.id">
              <td
                v-for="(column, i) in columns"
                :key="i"
                :class="`${
                  column.class && (Array.isArray(row[column.field]) || row[column.field])
                    ? column.class
                    : ''
                }`"
              >
                <!-- :class="`${column.class && (Array.isArray(row[column.field]) || row[column.field]) ? column.class : ''}`" -->
                <slot :name="column.slotName" :row="row">
                  <div v-if="Array.isArray(row[column.field])">
                    <span v-for="(value, index) in row[column.field]" :key="index">{{
                      value
                    }}</span>
                  </div>
                  <div v-else>
                    <span
                      v-if="!row[column.field] && column.badge_no_data"
                      class="badge badge-plain"
                      >no data</span
                    >
                    <!-- isNaN(row[column.field]) &&  -->
                    <span v-else-if="typeof row[column.field] !== undefined">
                      {{ column.prefix ? column.prefix : "" }}
                      {{
                        column.formatter
                          ? column.formatter(row[column.field])
                          : row[column.field]
                      }}
                      {{ column.suffix ? column.suffix : "" }}</span
                    >
                  </div>
                </slot>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td class="px-0 pb-0" :colspan="columns.length">
                <AlertHorizontal title="Oops" />
              </td>
            </tr>
          </template>
        </template>
        <template v-else>
          <tr>
            <td class="px-0 pb-0" :colspan="columns.length">
              <AlertHorizontal
                title="Oops"
                description="Something went wrong here."
                type="error"
              />
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <slot name="footer" :data="data" :columns="columns"></slot>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
