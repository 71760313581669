import { createStore } from 'vuex';

import algorithms from './modules/algorithms';
import dashboard from './modules/dashboard';
import modals from './modules/modals';
import subscription from './modules/subscription';
import user from './modules/user';
import wallet from './modules/wallet';

const store = createStore({
  modules: {
    algorithms,
    dashboard,
    modals,
    subscription,
    user,
    wallet,
  },
});

export default store;
