<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M7.64192 11.0834C7.52656 11.0829 7.41392 11.0482 7.31827 10.9837C7.22261 10.9192 7.14822 10.8278 7.10452 10.7211C7.06081 10.6143 7.04974 10.497 7.07272 10.3839C7.09569 10.2708 7.15168 10.1671 7.23359 10.0859L9.91693 7.41422C9.9716 7.35999 10.015 7.29548 10.0446 7.22439C10.0742 7.15331 10.0895 7.07706 10.0895 7.00006C10.0895 6.92305 10.0742 6.8468 10.0446 6.77572C10.015 6.70463 9.9716 6.64012 9.91693 6.58589L7.23359 3.91422C7.17891 3.85999 7.13552 3.79548 7.1059 3.72439C7.07629 3.65331 7.06104 3.57706 7.06104 3.50006C7.06104 3.42305 7.07629 3.3468 7.1059 3.27572C7.13552 3.20463 7.17891 3.14012 7.23359 3.08589C7.34288 2.97724 7.49073 2.91626 7.64484 2.91626C7.79895 2.91626 7.9468 2.97724 8.05609 3.08589L10.7336 5.76339C11.0613 6.09152 11.2454 6.5363 11.2454 7.00006C11.2454 7.46381 11.0613 7.9086 10.7336 8.23672L8.05609 10.9142C8.00159 10.9683 7.93694 11.0111 7.86587 11.0401C7.7948 11.0691 7.7187 11.0838 7.64192 11.0834Z"
      fill="currentColor"
    />
    <path
      d="M3.55843 11.0834C3.44306 11.0829 3.33043 11.0482 3.23477 10.9837C3.13911 10.9192 3.06473 10.8278 3.02102 10.7211C2.97731 10.6143 2.96625 10.497 2.98922 10.3839C3.0122 10.2708 3.06818 10.1671 3.15009 10.0859L6.23593 7.00006L3.15009 3.91422C3.09542 3.85999 3.05202 3.79548 3.02241 3.72439C2.99279 3.65331 2.97754 3.57706 2.97754 3.50006C2.97754 3.42305 2.99279 3.3468 3.02241 3.27572C3.05202 3.20463 3.09542 3.14012 3.15009 3.08589C3.25939 2.97724 3.40724 2.91626 3.56134 2.91626C3.71545 2.91626 3.8633 2.97724 3.97259 3.08589L7.47259 6.58589C7.52727 6.64012 7.57066 6.70463 7.60028 6.77572C7.6299 6.8468 7.64514 6.92305 7.64514 7.00006C7.64514 7.07706 7.6299 7.15331 7.60028 7.22439C7.57066 7.29548 7.52727 7.35999 7.47259 7.41422L3.97259 10.9142C3.91809 10.9683 3.85344 11.0111 3.78237 11.0401C3.7113 11.0691 3.6352 11.0838 3.55843 11.0834Z"
      fill="currentColor"
    />
  </svg>
</template>
