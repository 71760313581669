import { registerUrls } from '@/api/apiUrls';
import http from '@/helpers/http';

import adapterRegister from './adapters/register.adapter';
import { mockRegister } from './mocks/register.mock';

const registerService = async ({ body }) => {
  return await http({
    method: 'POST',
    urlService: registerUrls.register(),
    body,
    mockdata: mockRegister,
    adapter: adapterRegister,
    debug: false,
  });
};

export { registerService };
