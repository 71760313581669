import generateRandomNumber from '@commons/helpers/generators/generateRandomNumber';

const successfullChangeEmailAddress = () => ({
  success: true,
  data: {
    message: 'Email has been successfully changed.',
  },
});
const failedChangeEmailAddress = () => ({
  success: false,
  data: {
    message: 'Failed to change the email. Please check the provided details and try again.',
  },
});
const changeEmailAddressScenario = [successfullChangeEmailAddress, failedChangeEmailAddress];
export const changeEmailAddress = () => {
  const rand = generateRandomNumber(0, changeEmailAddressScenario.length);
  if (typeof changeEmailAddressScenario[rand] !== 'undefined') {
    return changeEmailAddressScenario[rand]();
  }
  return successfullChangeEmailAddress();
};
