<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 16"
    fill="none"
  >
    <g clip-path="url(#clip0_446_73)">
      <path
        d="M13.125 4.26172H3.125C2.58812 4.26172 2.08 4.02922 1.72813 3.63734C2.07188 3.25359 2.57125 3.01172 3.125 3.01172H14.375C14.7206 3.01172 15 2.73172 15 2.38672C15 2.04172 14.7206 1.76172 14.375 1.76172H3.125C1.39938 1.76172 0 3.16109 0 4.88672V11.1367C0 12.8623 1.39938 14.2617 3.125 14.2617H13.125C14.1606 14.2617 15 13.4223 15 12.3867V6.13672C15 5.10109 14.1606 4.26172 13.125 4.26172ZM13.75 12.3867C13.75 12.7311 13.47 13.0117 13.125 13.0117H3.125C2.09125 13.0117 1.25 12.1705 1.25 11.1367V4.88547C1.78375 5.28484 2.44 5.51172 3.125 5.51172H13.125C13.47 5.51172 13.75 5.79234 13.75 6.13672V12.3867ZM12.5 9.26172C12.5 9.60672 12.22 9.88672 11.875 9.88672C11.53 9.88672 11.25 9.60672 11.25 9.26172C11.25 8.91672 11.53 8.63672 11.875 8.63672C12.22 8.63672 12.5 8.91672 12.5 9.26172Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_446_73">
        <rect width="15" height="15" fill="white" transform="translate(0 0.511719)" />
      </clipPath>
    </defs>
  </svg>
</template>
