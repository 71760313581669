<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
//  components
import BasicModal from "@commons/components/modals/ModalBase.vue";
import Button from "@commons/components/buttons/ButtonComponent.vue";
import DatepickerVue from "@commons/components/inputs/Datepicker.vue";

//composables
import { useCustomToast } from "@/composables/toast/useCustomToast";

//styles
import "@/assets/styles/custom_date_modal.scss";

const store = useStore();
const date_range = ref([]);

const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
    resetModal();
  }
};


const filterByDate = async () => {
  try {
    const res = await store.dispatch("dashboard/getOverviewChart", {
      dateRange: date_range,
      showDailyNav:null
    });
    if (res.success) {
      store.dispatch("modals/closeModal");
  
      return;
    } else {
      useCustomToast({ text: res.data.error, type: "error" });
    }
  } catch (error) {
    console.log(error);
  }
};

const resetModal = () => {
  date_range.value = [];
};
</script>

<template>
  <div class="component_custom_date_modal">
    <BasicModal
      headerTitle="Custom"
      name="custom_date"
      classNameHeader="card-bg-dark"
      className="custom_date"
      :onClose="modalHandler"
    >
      <div class="modal-container">
        <h2>
          In this section, you can use the date filter to select the time range
          of the information you want to display in the chart. This allows you
          to focus on the most relevant data according to your needs.
        </h2>

        <div class="form-group">
          <label>Date range</label>
          <DatepickerVue
            v-model="date_range"
            :clearable="false"
            :range="true"
          />
        </div>
      </div>
      <div slot:footer class="modal-footer justify-content-end">
        <div class="btn-container">
          <Button
            text="Cancel"
            type="text"
            className="text-white"
            class="btn-border"
            @click="modalHandler(false)"
          />

          <Button
            type="secondary"
            text="Apply"
            :class="{ btn_custom: date_range.length }"
            @click="filterByDate()"
            :buttonDisable="!date_range.length"
          />
        </div>
      </div>
    </BasicModal>
  </div>
</template>
