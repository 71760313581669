<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

// components
import AlgoReduceSection from "../views/algorithms/AlgorithmsListTable/AlgoReduceSection.vue";
import AlgoIncreaseSection from "../views/algorithms/AlgorithmsListTable/AlgoIncreaseSection.vue";
import BasicModal from "@commons/components/modals/ModalBase.vue";
import Button from "@commons/components/buttons/ButtonComponent.vue";
// icons
import InfoIcon from "@/assets/icons/info-icon.vue";
// composables
import { useCustomToast } from "@/composables/toast/useCustomToast";
// styles
import "@/assets/styles/algorithm_lists.scss";

const emit = defineEmits(["algoReAllocated"]);
const store = useStore();
const increaseInvestmentForm = ref(true);
const increaseInvestment = ref(null);
const utilizedAmount = ref(0);
const reAllocationLoading = computed(
  () => store.state.algorithms.algoReAllocation._status._on_loading
);

const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
  }
};

const applyAlgorithm = async (algoId) => {
  try {
    const res = await store.dispatch("algorithms/algorithmReAllocation", {
      algorithm_id: algoId,
      action: increaseInvestmentForm.value ? "increase" : "reduce",
      amount: utilizedAmount.value,
    });
    if (res.success) {
      useCustomToast({
        text: res.data.message,
        type: "success",
      });
      modalHandler(false);
      emit("algoAllocated");
    } else {
      useCustomToast({
        text: res.data.message,
        type: "error",
      });
    }
  } catch (error) {
    console.log(error);
  }
};
const openConfirmation = async () => {
  let increaseConfirmationTitle = {
    title: "Confirm Investment Increase",
    message:
      "Are you sure you want to increase your investment in the algorithm? This action will increase the amount of invested funds.",
    confirmText: "Proceed",
  };
  let reduceConfirmationTitle = {
    title: "Confirm Investment Reduction",
    message:
      "Are you sure you want to reduce your investment in the algorithm? This action will decrease the amount of invested funds.",
    confirmText: "Proceed",
  };
  let closeInvestmentConfirmationTitle = {
    title: "Close Investment in the Algorithm",
    message:
      "If you choose to set it to 100%, you will close your entire investment in the algorithm. Please make sure you are certain about this action. The available funds will be transferred to your wallet within the next 48 hours.",
    confirmText: "Proceed",
  };
  if (await increaseInvestment.value.checkValidation()) {
    utilizedAmount.value = increaseInvestment.value.utilizedAmount;
    emit(
      "algoReAllocated",
      increaseInvestmentForm.value
        ? increaseConfirmationTitle
        : increaseInvestment.value.investedBalance ==
          increaseInvestment.value.utilizedAmount
        ? closeInvestmentConfirmationTitle
        : reduceConfirmationTitle
    );
  }
};
defineExpose({
  applyAlgorithm,
  applyAlgorithmLoading: reAllocationLoading,
});
</script>

<template>
  <BasicModal
    headerTitle="Re-allocate"
    name="algo_reallocate"
    classNameHeader="card-bg-dark"
    :onClose="modalHandler"
  >
    <div class="modal-container allocation_modal">
      <div class="top_section">
        <div class="allocated_switcher_container">
          <div
            :class="['allocated_switcher-btn', { active: increaseInvestmentForm }]"
            @click="increaseInvestmentForm = true"
          >
            Increase investment
          </div>
          <div
            :class="['allocated_switcher-btn', { active: !increaseInvestmentForm }]"
            @click="increaseInvestmentForm = false"
          >
            Reduce investment
          </div>
        </div>
        <div class="top_section-desc">
          Here you can increase or decrease your investment in the algorithm according to
          your needs and financial goals.
        </div>
        <AlgoIncreaseSection v-if="increaseInvestmentForm" ref="increaseInvestment" />
        <AlgoReduceSection v-if="!increaseInvestmentForm" ref="increaseInvestment" />
      </div>
      <div class="bottom_section">
        <div class="bottom_section-header">
          <div class="bottom_section-header-icon">
            <InfoIcon />
          </div>
          <div class="bottom_section-header-title">
            Rules for Investing in the Algorithm
          </div>
        </div>
        <div class="bottom_section-rules">
          <ul>
            <li>
              <span>Minimum Investment Amount:</span>If you wish to reduce your
              investment, you must maintain a minimum investment amount of $100.00.
            </li>
            <li>
              <span>Sufficient Funds: </span>To increase your investment, make sure you
              have sufficient funds in your wallet.
            </li>
            <li>
              <span>Fees:</span> A fee of 15% will be charged on the adjusted amount.
            </li>
            <li>
              <span>Adjustment Process: </span>Select the desired option (increase or
              decrease) and follow the instructions to complete the adjustment.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div slot:footer class="modal-footer">
      <Button
        text="Cancel"
        type="text"
        className="outlined_btn"
        @click="modalHandler(false)"
      />
      <Button
        type="secondary"
        text="Save"
        @click="openConfirmation"
        class-name="solid_btn"
        :isLoading="reAllocationLoading"
        :disabled="increaseInvestment?.utilizedAmount == 0"
      />
    </div>
  </BasicModal>
</template>
