import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const invalidAllocation = () => ({
  success: false,
  data: {
    message: 'Your investment could not be completed. Please try again later.',
  },
});

const successfullAllocation = () => ({
  success: true,
  data: {
    message: 'Your investment in the algorithm has been successfully completed.',
  },
});

const allocationScenarios = [successfullAllocation, invalidAllocation];

export const mockAlgorithmAllocation = () => {
  const rand = randomNumber(0, allocationScenarios.length);

  if (typeof allocationScenarios[rand] !== 'undefined') {
    return allocationScenarios[rand]();
  }
  return successfullAllocation();
};
