import randomDecimal from '@commons/helpers/generators/generateRandomDecimal';

export default () => ({
  success: true,
  data: {
    total_balance: randomDecimal(1000000, 2),
    total_balance_percentage: randomDecimal(100, 2),
    available_balance: randomDecimal(1000000, 2),
    invested_balance: randomDecimal(1000000, 2),
    blocked_balance: randomDecimal(1000000, 2),
    reserved_balance: randomDecimal(1000000, 2),
  },
});
