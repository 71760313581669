<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 16"
    fill="none"
  >
    <path
      d="M11.875 2.38672H3.125C1.40188 2.38672 0 3.78859 0 5.51172V10.5117C0 12.2348 1.40188 13.6367 3.125 13.6367H11.875C13.5981 13.6367 15 12.2348 15 10.5117V5.51172C15 3.78859 13.5981 2.38672 11.875 2.38672ZM13.75 10.5117C13.75 11.5455 12.9087 12.3867 11.875 12.3867H3.125C2.09125 12.3867 1.25 11.5455 1.25 10.5117V5.51172C1.25 4.47797 2.09125 3.63672 3.125 3.63672H11.875C12.9087 3.63672 13.75 4.47797 13.75 5.51172V10.5117ZM12.5 5.51172C12.5 5.85734 12.22 6.13672 11.875 6.13672H9.375C9.03 6.13672 8.75 5.85734 8.75 5.51172C8.75 5.16609 9.03 4.88672 9.375 4.88672H11.875C12.22 4.88672 12.5 5.16609 12.5 5.51172ZM12.5 8.01172C12.5 8.35734 12.22 8.63672 11.875 8.63672H9.375C9.03 8.63672 8.75 8.35734 8.75 8.01172C8.75 7.66609 9.03 7.38672 9.375 7.38672H11.875C12.22 7.38672 12.5 7.66609 12.5 8.01172ZM11.25 10.5117C11.25 10.8573 10.97 11.1367 10.625 11.1367H9.375C9.03 11.1367 8.75 10.8573 8.75 10.5117C8.75 10.1661 9.03 9.88672 9.375 9.88672H10.625C10.97 9.88672 11.25 10.1661 11.25 10.5117ZM7.78438 7.37609C7.85187 7.56109 7.79625 7.76859 7.64563 7.89547L6.53687 8.79859L6.99563 10.1955C7.05813 10.3842 6.995 10.5923 6.83687 10.7142C6.67875 10.8355 6.46125 10.8442 6.295 10.7355L5.00437 9.89484L3.735 10.7442C3.65625 10.7973 3.565 10.8236 3.47438 10.8236C3.375 10.8236 3.27625 10.7923 3.19313 10.7298C3.03375 10.6105 2.9675 10.4036 3.02688 10.2142L3.46812 8.80047L2.35375 7.89359C2.20375 7.76609 2.14937 7.55922 2.21688 7.37484C2.28437 7.19047 2.46 7.06797 2.65687 7.06797H4.06375L4.56187 5.67109C4.63 5.48797 4.80562 5.36609 5.00125 5.36609C5.19688 5.36609 5.3725 5.48797 5.44063 5.67109L5.93875 7.06797H7.34562C7.5425 7.06797 7.71875 7.19109 7.78625 7.37609H7.78438Z"
      fill="currentColor"
    />
  </svg>
</template>
