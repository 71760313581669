<template>
  <div class="icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <g clip-path="url(#clip0_901_1950)">
        <path
          d="M9.29976 1.84625L3.54184 7.60375C3.50313 7.64262 3.45712 7.67346 3.40646 7.6945C3.3558 7.71554 3.30149 7.72637 3.24663 7.72637C3.19178 7.72637 3.13746 7.71554 3.0868 7.6945C3.03614 7.67346 2.99013 7.64262 2.95142 7.60375L0.724756 5.375C0.686044 5.33614 0.640038 5.3053 0.589379 5.28426C0.538719 5.26321 0.484403 5.25238 0.429548 5.25238C0.374692 5.25238 0.320376 5.26321 0.269717 5.28426C0.219057 5.3053 0.173052 5.33614 0.134339 5.375C0.095474 5.41372 0.0646354 5.45972 0.043593 5.51038C0.0225507 5.56104 0.0117187 5.61536 0.0117188 5.67021C0.0117187 5.72507 0.0225507 5.77938 0.043593 5.83004C0.0646354 5.8807 0.095474 5.92671 0.134339 5.96542L2.36184 8.1925C2.59682 8.42705 2.91525 8.55877 3.24726 8.55877C3.57926 8.55877 3.89769 8.42705 4.13267 8.1925L9.89017 2.43625C9.92898 2.39755 9.95976 2.35157 9.98077 2.30095C10.0018 2.25033 10.0126 2.19606 10.0126 2.14125C10.0126 2.08645 10.0018 2.03218 9.98077 1.98156C9.95976 1.93094 9.92898 1.88496 9.89017 1.84625C9.85146 1.80739 9.80546 1.77655 9.7548 1.75551C9.70414 1.73446 9.64982 1.72363 9.59496 1.72363C9.54011 1.72363 9.48579 1.73446 9.43513 1.75551C9.38447 1.77655 9.33847 1.80739 9.29976 1.84625Z"
          fill="#0EB770"
        />
      </g>
      <defs>
        <clipPath id="clip0_901_1950">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
