import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueApexCharts from 'vue3-apexcharts';
import '@commons/styles/vars.scss';
import '@commons/styles/main.scss';
import '@/assets/styles/commons.scss';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import '@/assets/styles/toast.scss';
createApp(App).use(store).use(router).use(VueApexCharts).use(Toast).mount('#app');
