<script setup>
import { useStore } from "vuex";
//  components
import BasicModal from "@commons/components/modals/ModalBase.vue";

//icons
import CopyIcon from "@/assets/icons/copy-icon.vue";
import Qr from "@/assets/icons/qr-icon.vue";
import InfoIcon from "@/assets/icons/info-icon.vue";

//styles
import "@/assets/styles/deposit_modal.scss";

const emit = defineEmits(["onClose"]);

defineProps({
  title: {
    default: "Deposit",
    type: String,
  },
  address: {
    default: "35NWND1XTqVi7uU3L3J5X3LpjFyT7xsdaW",
    type: String,
  },
  insufficientBalance: {
    default: false,
    type: Boolean,
  },
  name: {
    type: String,
  },
});

const store = useStore();

const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
  }
};

const close = () => {
  modalHandler();
  emit("onClose");
};
</script>

<template>
  <div class="component_deposit_modal">
    <BasicModal
      :headerTitle="title"
      :name="name"
      classNameHeader="card-bg-dark"
      className="deposit"
      :onClose="close"
    >
      <div class="modal-container">
        <h2>Scan the QR code below</h2>

        <Qr />

        <div class="separator">
          <span class="line"></span>
          <span class="sub_text">or</span>
          <span class="line"></span>
        </div>

        <h3>Manually enter the address below</h3>

        <div class="code-container">
          <div>{{ address }}</div>

          <CopyIcon />
        </div>

        <div class="info">
          <p>
            <span><InfoIcon /></span> Information
          </p>
          <ul>
            <li>
              Please allow up to 24 hours for the transaction to be processed
              and received in your account.
            </li>
            <li v-if="insufficientBalance">
              You can then activate the subscription once the amount is
              reflected in your wallet.
            </li>
          </ul>
        </div>
      </div>
      <div slot:footer class="modal-footer justify-content-center">
        <p>Need Help? <a>Contact Support</a></p>
      </div>
    </BasicModal>
  </div>
</template>
