import { algorithmsName, riskLevel } from '@/config/constants';
import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const dashboardAlgorithmsListMock = () => ({
  success: true,
  data: [
    {
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      description:
        'BWFS Neural accounts for 30% of your $1 million portfolio, with a current exposure of 20%, equivalent to $250,000.',
      minimumInvestment: 10000,
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 50000, 25000, 20800, 11000, 52000, 90000, 10000, 40000, 20008, 51000, 42000, 100009,
        20000,
      ],
    },
    {
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      description:
        'BWFS Neural accounts for 10% of your $1 million portfolio, with a current exposure of 30%, equivalent to $50,000.',
      minimumInvestment: 50000,
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 11000, 90000, 50000, 25000, 20800, 52000, 10000, 40000, 20008, 51000, 42000, 100009,
        20000,
      ],
    },
    {
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      description:
        'BWFS Neural accounts for 20% of your $1 million portfolio, with a current exposure of 10%, equivalent to $100,000.',
      minimumInvestment: 25000,
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 100009, 20008, 50000, 25000, 20800, 11000, 52000, 90000, 10000, 40000, 51000, 42000,
        20000,
      ],
    },
    {
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      description:
        'BWFS Neural accounts for 40% of your $1 million portfolio, with a current exposure of 40%, equivalent to $400,000.',
      minimumInvestment: 5000,
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 20008, 50000, 25000, 20800, 11000, 52000, 90000, 10000, 40000, 51000, 42000, 100009,
        20000,
      ],
    },
    {
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      description:
        'BWFS Neural accounts for 50% of your $1 million portfolio, with a current exposure of 50%, equivalent to $500,000.',
      minimumInvestment: 10000,
      communityRisk: riskLevel[randomNumber(0, riskLevel.length)],
      followers: randomNumber(500, 10000),
      allocation: randomNumber(500, 100000),
      perfomrance: [
        10000, 50000, 25000, 20800, 11000, 52000, 90000, 10000, 40000, 20008, 51000, 42000, 100009,
        20000,
      ],
    },
  ],
});

export default dashboardAlgorithmsListMock;
