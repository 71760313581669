import generateLoadingMutations from '@commons/helpers/store/generateLoadingMutations';
import setData from '@commons/helpers/store/setData';
import setState from '@commons/helpers/store/setState';

import downloadReport from './actions/downloadReport';
import getAlgorithmPerformance from './actions/getAlgorithmPerformance';
import getDashboardAlgorithmsList from './actions/getDashboardAlgorithms.action';
import getNetAssetValue from './actions/getNetAssetValue';
import getOverviewChart from './actions/getOverviewChart';
import getPerformance from './actions/getPerformance';
import getRiskLevel from './actions/getRiskLevel';
import getTransactionList from './actions/getTransactionList';
import getTransactionListCount from './actions/getTransactionListCount';

import adapterAlgorithmsList from '@/services/dashboard/adapters/getDashboardAlgorithmsList';
import adapterGetAlgorithmPerformance from '@/services/dashboard/adapters/getAlgorithmPerformance';
import adapterGetNetAssetValue from '@/services/dashboard/adapters/getNetAssetValue';
import adapterGetOverviewChart from '@/services/dashboard/adapters/getOverviewChart';
import adapterGetPerformance from '@/services/dashboard/adapters/getPerformance';
import adapterGetRiskLevel from '@/services/dashboard/adapters/getRiskLevel';
import adapterGetTransactionList from '@/services/dashboard/adapters/getTransactionList';
import adapterGetTransactionListCount from '@/services/dashboard/adapters/getTransactionListCount';
import adapterDataJson from '@/services/common/adapters/dataResJson';

const state = {
  algorithmsList: setState(adapterAlgorithmsList),
  algorithm_performance: setState(adapterGetAlgorithmPerformance),
  downloadReport: setState(adapterDataJson),
  net_asset_value: setState(adapterGetNetAssetValue),
  overview_chart: setState(adapterGetOverviewChart),
  performance: setState(adapterGetPerformance),
  risk_level: setState(adapterGetRiskLevel),
  transactionList: setState(adapterGetTransactionList),
  transactionListCount: setState(adapterGetTransactionListCount),
};

const actions = {
  downloadReport,
  getAlgorithmPerformance,
  getDashboardAlgorithmsList,
  getNetAssetValue,
  getOverviewChart,
  getPerformance,
  getRiskLevel,
  getTransactionList,
  getTransactionListCount,
};

const getters = {
  algorithmsList: (state) => state.algorithmsList,
  algorithm_performance: (state) => state.algorithm_performance,
  net_asset_value: (state) => state.net_asset_value,
  overviewChart: (state) => state.overview_chart._data.data,
  performance: (state) => state.performance,
  risk_level: (state) => state.risk_level,
  transactionListCount: (state) => state.transactionListCount,
};

const mutations = {
  ...generateLoadingMutations(),
  setData,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
