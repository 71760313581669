export default () => ({
  success: true,
  data: [],
  paginate: {
    current_page: 1,
    total_pages: 138,
    per_page: 10,
    records: 1385,
  },
});
