import { computed, onMounted } from "vue";
import { useStore } from "vuex";

export function useTableManagement(
  moduleName,
  stateName,
  fetchDataCallback = () => {},
  loadOnMounted = true
) {
  const store = useStore();
  const statusFetch = computed(
    () => store.state[moduleName][stateName]._status
  );
  const tableData = computed(
    () => store.state[moduleName][stateName]._data?.data
  );
  const current_page = computed(
    () => store.state[moduleName][stateName]._data.paginate?.current_page
  );
  const records = computed(
    () => store.state[moduleName][stateName]._data.paginate?.records
  );
  const total_pages = computed(
    () => store.state[moduleName][stateName]._data.paginate?.total_pages
  );
  const per_page = computed(
    () => store.state[moduleName][stateName]._data.paginate?.per_page
  );

  // To refresh table after an action made for a table item
  const handleSuccessModalClose = () => {
    store.dispatch("modals/closeModal");
    fetchDataCallback({ page: current_page.value });
  };

  // Pagination Management
  const handleGoFirstPage = () => {
    if (!statusFetch.value._on_loading) fetchDataCallback({ page: 1 });
  };
  const handleGoLastPage = () => {
    if (!statusFetch.value._on_loading)
      fetchDataCallback({ page: total_pages.value });
  };
  const handleGoNextPage = () => {
    if (
      !statusFetch.value._on_loading &&
      current_page.value < total_pages.value
    )
      fetchDataCallback({ page: current_page.value + 1 });
  };
  const handleGoPrevPage = () => {
    if (!statusFetch.value._on_loading && current_page.value > 1)
      fetchDataCallback({ page: current_page.value - 1 });
  };
  const handleChangeItemsPerPage = (itemsValue) => {
    if (!statusFetch.value._on_loading)
      fetchDataCallback({ limit: itemsValue });
  };

  // Hooks Cycle
  onMounted(() => {
    if (loadOnMounted) fetchDataCallback();
  });

  return {
    statusFetch,
    tableData,
    current_page,
    records,
    total_pages,
    per_page,
    handleSuccessModalClose,
    handleGoFirstPage,
    handleGoLastPage,
    handleGoNextPage,
    handleGoPrevPage,
    handleChangeItemsPerPage,
  };
}
