<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
// components
import AlgorithmList from "@/components/views/dashboard/AlgorithmList.vue";
import AlgoPerformanceCard from "@/components/views/dashboard/AlgorithmPerformanceCard.vue";
import NetAssetValueCard from "@/components/views/dashboard/NetAssetValueCard.vue";
import OverviewCard from "@/components/views/dashboard/OverviewCard.vue";
import PerformanceCard from "@/components/views/dashboard/PerformanceCard.vue";
import RiskCard from "@/components/views/dashboard/RiskCard.vue";
import TransactionsTable from "@/components/views/dashboard/TransactionsTable.vue";
import StepByStepComponent from "@/components/views/dashboard/StepByStepComponent.vue";

//icon
import CircleIcon from "@/assets/icons/circle-icon.vue";
// styles
import "@/assets/styles/dashboard-view.scss";

const store = useStore();

onMounted(() => {
  store.dispatch("dashboard/getAlgorithmPerformance");
  store.dispatch("dashboard/getNetAssetValue");
  store.dispatch("dashboard/getPerformance");
  store.dispatch("dashboard/getRiskLevel");
  store.dispatch("dashboard/getOverviewChart", {
    dateRange: null,
    showDailyNav: null,
    showExposure: null,
    showMaxMinNav: null,
  });
  store.dispatch("dashboard/getOverviewChart", {
    dateRange: null,
    showDailyNav: null,
    showExposure: null,
    showMaxMinNav: null,
  });
});

function emitFocusNavCard() {
  window.dispatchEvent(new CustomEvent("focus-nav-card"));
}

const isSubscribed = ref(false);
const toggle = () => {
  isSubscribed.value = !isSubscribed.value;
};
</script>

<template>
  <div class="p-3 dashboard-view_container">
    <div class="step-by-step-screen" v-show="!isSubscribed">
      <StepByStepComponent />
    </div>

    <div class="dashboard-view" v-show="isSubscribed">
      <PerformanceCard @focus-nav-card="emitFocusNavCard" /> 
      <AlgoPerformanceCard />
      <RiskCard />
      <NetAssetValueCard />

      <OverviewCard />
      <AlgorithmList />
      <TransactionsTable />
    </div>
    <div class="sidenavbar__header__toggle" @click="toggle"  >
      <CircleIcon v-if="isSubscribed"/>
    </div>
  </div>
</template>
