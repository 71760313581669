import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const invalidFields = () => ({
  success: false,
  message: 'Error validation',
  errors: {
    password: ['The team name must be a string.'],
  },
});

const successRegister = () => ({
  success: true,
  data: {
    session: {
      jwt_token: 'jwt_token_example',
      cookie_auth: 'cookie_auth',
    },
    user: {
      token: '004209b9-9d06-4072-904d-8e52b6659159',
      username: 'jhon_doe',
      email: 'jhondoe@hotmail.com',
    },
  },
});

const registerScenarios = [successRegister, invalidFields];

export const mockRegister = () => {
  const rand = randomNumber(0, registerScenarios.length);

  if (typeof registerScenarios[rand] !== 'undefined') {
    return registerScenarios[rand]();
  }
  return 'fmdks';
};
