import { getInvestmentDetailsService as service } from '../../../../services/algorithms/algorithmsService';

const field = 'investmentDetails';

export default async ({ commit }, { id }) => {
  commit('setIsLoading', { field });

  try {
    const { response } = await service({ id });

    if (!response.error) {
      commit('setIsReady', { field });
      commit('setData', {
        _state: field,
        _data: response,
      });
    } else {
      commit('setIsError', { field });
    }

    return response;
  } catch (error) {
    commit('setIsError', { field });
    throw error;
  }
};
