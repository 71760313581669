import { setCookie } from '@/helpers/cookies/setCookies';
import { loginService } from '@/services/auth/authService';

const field = 'login';

export default async ({ commit }, body) => {
  try {
    commit('setIsLoading', { field });
    const { response } = await loginService(body);
    if (response.success) {
      const customResponse = {
        success: response.success,
        data: response.data.user,
        token: response.data.session.jwt_token,
        authorized: true,
        cookie_auth: '',
      };
      await loginByResponse(commit, customResponse);
    }

    return response;
  } catch (error) {
    commit('setIsError', { field });
    throw error;
  } finally {
    commit('setIsReady', { field });
  }
};

export const loginByResponse = async (commit, response) => {
  const algoName = process.env.VUE_APP_CRYPTO_ALGO;
  const password = process.env.VUE_APP_CRYPTO_KEY;

  async function encryptData(plainText) {
    const enc = new TextEncoder();
    const algo = { name: algoName, length: 256 };
    const key = await window.crypto.subtle.importKey('raw', enc.encode(password), algo, false, [
      'encrypt',
    ]);
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encrypted = await window.crypto.subtle.encrypt(
      { name: algoName, iv },
      key,
      enc.encode(plainText),
    );
    return {
      iv: Array.from(iv),
      data: Array.from(new Uint8Array(encrypted)),
    };
  }
  if (!response.success) {
    commit('setIsError', { field });
  } else if (response.authorized) {
    const stringUserData = JSON.stringify(response.data);
    const encryptedUserData = await encryptData(stringUserData);
    const encryptedToken = await encryptData(response.token);
    const seconds = Math.floor(new Date().getTime() / 1000);
    const expiryInSecs = (seconds + 3600) * 1000;
    const expiration = new Date(expiryInSecs).toUTCString();
    setCookie('token', JSON.stringify(encryptedToken), expiration);
    setCookie('userData', JSON.stringify(encryptedUserData), expiration);
    setCookie('cookie_auth', response.cookie_auth, expiration);
    commit('setData', {
      _state: field,
      _data: response,
    });
    commit('setToken', response.token);
  } else {
    commit('setIsReady', { field });
  }
};
