import { getWalletListService as service } from '../../../../services/wallet/walletService';

const field = 'walletList';

export default async ({ commit }) => {
  commit('setIsLoading', { field });

  try {
    const { response } = await service({});

    if (response?.success) {
      commit('setIsReady', { field });
      commit('setData', {
        _state: field,
        _data: response,
      });
    } else {
      commit('setIsError', { field });
    }

    return response;
  } catch (error) {
    commit('setIsError', { field });
    throw error;
  }
};
