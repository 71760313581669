<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M10.3308 7.41425C10.2766 7.35957 10.2121 7.31618 10.141 7.28656C10.0699 7.25695 9.99365 7.2417 9.91664 7.2417C9.83963 7.2417 9.76339 7.25695 9.6923 7.28656C9.62122 7.31618 9.5567 7.35957 9.50247 7.41425L7.58331 9.33342V3.50008C7.58331 3.34537 7.52185 3.197 7.41245 3.0876C7.30306 2.97821 7.15468 2.91675 6.99997 2.91675C6.84526 2.91675 6.69689 2.97821 6.5875 3.0876C6.4781 3.197 6.41664 3.34537 6.41664 3.50008V9.33342L4.49747 7.41425C4.44325 7.35957 4.37873 7.31618 4.30764 7.28656C4.23656 7.25695 4.16031 7.2417 4.08331 7.2417C4.0063 7.2417 3.93006 7.25695 3.85897 7.28656C3.78789 7.31618 3.72337 7.35957 3.66914 7.41425C3.56049 7.52354 3.49951 7.67139 3.49951 7.8255C3.49951 7.97961 3.56049 8.12745 3.66914 8.23675L6.17747 10.7392C6.39526 10.9583 6.69105 11.0821 6.99997 11.0834C7.30687 11.0806 7.60028 10.9569 7.81664 10.7392L10.325 8.23675C10.4344 8.12823 10.4964 7.98081 10.4975 7.8267C10.4986 7.67259 10.4387 7.52431 10.3308 7.41425Z"
      fill="currentColor"
    />
  </svg>
</template>
