import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const invalidCloseAllocation = () => ({
  success: false,
  data: {
    message: 'Your investment in the algorithm could not be closed. Please try again later.',
  },
});

const successfullCloseAllocation = () => ({
  success: true,
  data: {
    message:
      'Your investment in the algorithm has been successfully closed. The remaining funds will be sent to your wallet within the next 48 hours.',
  },
});

const allocationCloseScenarios = [successfullCloseAllocation, invalidCloseAllocation];

export const mockCloseAlgorithmAllocation = () => {
  const rand = randomNumber(0, allocationCloseScenarios.length);

  if (typeof allocationCloseScenarios[rand] !== 'undefined') {
    return allocationCloseScenarios[rand]();
  }
  return successfullCloseAllocation();
};
