import { algorithmsName } from '@/config/constants';
import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import randomDecimal from '@commons/helpers/generators/generateRandomDecimal';

const algorithmPerformanceMock = () => ({
  success: true,
  data: [
    {
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      performance: randomDecimal(1000000, 2),
      performance_percentage: randomDecimal(10, 10),
      portfolio_total: randomDecimal(1000000, 2),
    },
    {
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      performance: randomDecimal(1000000, 2),
      performance_percentage: randomDecimal(10, 10),
      portfolio_total: randomDecimal(1000000, 2),
    },
    {
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      performance: randomDecimal(1000000, 2),
      performance_percentage: randomDecimal(10, 10),
      portfolio_total: randomDecimal(1000000, 2),
    },
  ],
});

export default algorithmPerformanceMock;
