import { updateUserProfileService } from '@/services/profile/userProfileService';

const field = 'updateUser';

export default async ({ commit, dispatch }, body) => {
  try {
    commit('setIsLoading', { field });
    const { response } = await updateUserProfileService({ body });
    if (response.success) {
      dispatch('userProfile');
    } else {
      commit('setIsError', { field });
    }
    return response;
  } catch (error) {
    commit('setIsError', { field });
    return error;
  } finally {
    commit('setIsReady', { field });
  }
};
