<template>
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5535 0.375H11.2204C11.0709 0.375039 10.9247 0.428555 10.8004 0.528785C10.6761 0.629014 10.5792 0.771456 10.522 0.938103C10.4648 1.10475 10.4499 1.28812 10.479 1.46503C10.5082 1.64194 10.5802 1.80445 10.6859 1.93202L11.5069 2.9226L8.79579 6.19261C8.65174 6.3587 8.46015 6.45139 8.2609 6.45139C8.06164 6.45139 7.87006 6.3587 7.72601 6.19261L7.59748 6.03663C7.1655 5.53867 6.59104 5.26079 5.99357 5.26079C5.39611 5.26079 4.82165 5.53867 4.38966 6.03663L0.533929 10.6885C0.391867 10.8604 0.312217 11.0933 0.312501 11.3361C0.312784 11.5788 0.392979 11.8115 0.535441 11.9828C0.677904 12.1542 0.870966 12.2503 1.07216 12.25C1.27334 12.2497 1.46618 12.1529 1.60824 11.981L5.46398 7.32913C5.60794 7.16282 5.79955 7.06999 5.99887 7.06999C6.19818 7.06999 6.3898 7.16282 6.53376 7.32913L6.66228 7.4851C7.09444 7.98265 7.66884 8.26025 8.26619 8.26025C8.86354 8.26025 9.43794 7.98265 9.8701 7.4851L12.5812 4.21418L13.4023 5.20476C13.5083 5.33023 13.6427 5.4152 13.7886 5.44908C13.9345 5.48295 14.0854 5.46422 14.2225 5.39523C14.3596 5.32624 14.4768 5.21005 14.5595 5.06116C14.6422 4.91227 14.6867 4.73729 14.6875 4.55806V1.7432C14.6875 1.38033 14.568 1.03233 14.3553 0.775738C14.1427 0.51915 13.8542 0.375 13.5535 0.375Z"
      fill="#0EB770"
    />
  </svg>
</template>
