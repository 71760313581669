<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <g clip-path="url(#clip0_491_5118)">
      <path
        d="M15 1.875C15 2.21875 14.7188 2.5 14.375 2.5H0.625C0.28125 2.5 0 2.21875 0 1.875C0 1.53125 0.28125 1.25 0.625 1.25H14.375C14.7188 1.25 15 1.53125 15 1.875ZM9.375 12.5H5.625C5.28125 12.5 5 12.7812 5 13.125C5 13.4688 5.28125 13.75 5.625 13.75H9.375C9.71875 13.75 10 13.4688 10 13.125C10 12.7812 9.71875 12.5 9.375 12.5ZM11.875 6.875H3.125C2.78125 6.875 2.5 7.15625 2.5 7.5C2.5 7.84375 2.78125 8.125 3.125 8.125H11.875C12.2188 8.125 12.5 7.84375 12.5 7.5C12.5 7.15625 12.2188 6.875 11.875 6.875Z"
        fill="#898C9B"
      />
    </g>
    <defs>
      <clipPath id="clip0_491_5118">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
