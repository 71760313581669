<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <g clip-path="url(#clip0_446_178)">
      <path
        d="M14.375 14.2617H3.125C2.62772 14.2617 2.15081 14.0642 1.79917 13.7125C1.44754 13.3609 1.25 12.884 1.25 12.3867V1.13672C1.25 0.970958 1.18415 0.811987 1.06694 0.694777C0.949732 0.577567 0.79076 0.511719 0.625 0.511719C0.45924 0.511719 0.300269 0.577567 0.183058 0.694777C0.065848 0.811987 0 0.970958 0 1.13672L0 12.3867C0.000992411 13.2152 0.330551 14.0095 0.916387 14.5953C1.50222 15.1812 2.2965 15.5107 3.125 15.5117H14.375C14.5408 15.5117 14.6997 15.4459 14.8169 15.3287C14.9342 15.2115 15 15.0525 15 14.8867C15 14.721 14.9342 14.562 14.8169 14.4448C14.6997 14.3276 14.5408 14.2617 14.375 14.2617Z"
        fill="currentColor"
      />
      <path
        d="M3.75 13.0117C3.91576 13.0117 4.07473 12.9458 4.19194 12.8286C4.30915 12.7114 4.375 12.5524 4.375 12.3867V8.01169C4.375 7.84593 4.30915 7.68696 4.19194 7.56975C4.07473 7.45254 3.91576 7.38669 3.75 7.38669C3.58424 7.38669 3.42527 7.45254 3.30806 7.56975C3.19085 7.68696 3.125 7.84593 3.125 8.01169V12.3867C3.125 12.5524 3.19085 12.7114 3.30806 12.8286C3.42527 12.9458 3.58424 13.0117 3.75 13.0117Z"
        fill="currentColor"
      />
      <path
        d="M6.25 6.76172V12.3867C6.25 12.5525 6.31585 12.7114 6.43306 12.8287C6.55027 12.9459 6.70924 13.0117 6.875 13.0117C7.04076 13.0117 7.19974 12.9459 7.31695 12.8287C7.43416 12.7114 7.50001 12.5525 7.50001 12.3867V6.76172C7.50001 6.59596 7.43416 6.43699 7.31695 6.31978C7.19974 6.20257 7.04076 6.13672 6.875 6.13672C6.70924 6.13672 6.55027 6.20257 6.43306 6.31978C6.31585 6.43699 6.25 6.59596 6.25 6.76172Z"
        fill="currentColor"
      />
      <path
        d="M9.375 8.63672V12.3867C9.375 12.5525 9.44085 12.7114 9.55806 12.8287C9.67527 12.9459 9.83424 13.0117 10 13.0117C10.1658 13.0117 10.3247 12.9459 10.4419 12.8287C10.5592 12.7114 10.625 12.5525 10.625 12.3867V8.63672C10.625 8.47096 10.5592 8.31199 10.4419 8.19478C10.3247 8.07757 10.1658 8.01172 10 8.01172C9.83424 8.01172 9.67527 8.07757 9.55806 8.19478C9.44085 8.31199 9.375 8.47096 9.375 8.63672Z"
        fill="currentColor"
      />
      <path
        d="M12.5 6.13666V12.3867C12.5 12.5524 12.5658 12.7114 12.6831 12.8286C12.8003 12.9458 12.9592 13.0117 13.125 13.0117C13.2908 13.0117 13.4497 12.9458 13.5669 12.8286C13.6842 12.7114 13.75 12.5524 13.75 12.3867V6.13666C13.75 5.9709 13.6842 5.81193 13.5669 5.69472C13.4497 5.57751 13.2908 5.51166 13.125 5.51166C12.9592 5.51166 12.8003 5.57751 12.6831 5.69472C12.5658 5.81193 12.5 5.9709 12.5 6.13666Z"
        fill="currentColor"
      />
      <path
        d="M3.74987 6.13667C3.91561 6.13663 4.07456 6.07076 4.19174 5.95354L6.43299 3.71229C6.55212 3.59881 6.71034 3.53551 6.87487 3.53551C7.0394 3.53551 7.19762 3.59881 7.31674 3.71229L8.67424 5.06979C9.02586 5.4213 9.50268 5.61877 9.99987 5.61877C10.497 5.61877 10.9739 5.4213 11.3255 5.06979L14.8167 1.57854C14.9306 1.46067 14.9936 1.30279 14.9922 1.13892C14.9907 0.975044 14.925 0.818287 14.8091 0.702406C14.6932 0.586526 14.5365 0.520796 14.3726 0.519372C14.2087 0.517948 14.0509 0.580944 13.933 0.694793L10.4417 4.18542C10.3245 4.30259 10.1656 4.36841 9.99987 4.36841C9.83414 4.36841 9.6752 4.30259 9.55799 4.18542L8.20049 2.82854C7.84888 2.47703 7.37205 2.27957 6.87487 2.27957C6.37768 2.27957 5.90086 2.47703 5.54924 2.82854L3.30799 5.06979C3.22061 5.1572 3.16111 5.26855 3.137 5.38977C3.1129 5.51099 3.12527 5.63664 3.17257 5.75083C3.21986 5.86501 3.29995 5.96262 3.40271 6.03129C3.50546 6.09997 3.62627 6.13664 3.74987 6.13667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_446_178">
        <rect width="15" height="15" fill="white" transform="translate(0 0.511719)" />
      </clipPath>
    </defs>
  </svg>
</template>
