import { getCookie } from '@/helpers/cookies/getCookies';

export default async ({ commit }) => {
  let user = getCookie('userData');
  let cookie_autorized = getCookie('cookie_auth');
  let token = getCookie('token');
  const algoName = process.env.VUE_APP_CRYPTO_ALGO;
  const password = process.env.VUE_APP_CRYPTO_KEY;

  async function decryptData(encryptedData) {
    const dec = new TextDecoder();
    const algo = { name: algoName, length: 256 };
    const key = await window.crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(password),
      algo,
      false,
      ['decrypt'],
    );
    const iv = new Uint8Array(encryptedData.iv);
    const data = new Uint8Array(encryptedData.data);
    const decrypted = await window.crypto.subtle.decrypt({ name: algoName, iv }, key, data);
    return dec.decode(decrypted);
  }

  if (user && token) {
    try {
      user = JSON.parse(user);
      token = JSON.parse(token);
      const decryptedUser = await decryptData(user);
      const decryptedToken = await decryptData(token);
      const response = {
        data: JSON.parse(decryptedUser),
        cookie_auth: cookie_autorized,
        token: decryptedToken,
      };
      commit('setData', {
        _state: 'login',
        _data: response,
      });
      commit('setToken', decryptedToken);
    } catch (error) {
      console.error('Error descifrando los datos', error);
      // Manejar el error, posiblemente limpiando los datos corruptos o inválidos
    }
  }
};
