export default () => ({
  success: true,
  data: [
    {
      id: 1,
      type: 'Withdrawal',
      request_date: '2024/03/23',
      status: 'Pending',
      processed_date: '2024/03/23',
      amount: 1000,
      source: 'Wallet - External wallet',
    },

    {
      id: 3,
      type: 'Withdrawal',
      request_date: '2024/03/25',
      status: 'Rejected',
      processed_date: '2024/03/25',
      amount: 1500,
      source: 'Wallet - External wallet',
    },
    {
      id: 4,
      type: 'Deposit',
      request_date: '2024/03/26',
      status: 'Success',
      processed_date: '2024/03/26',
      amount: 3000,
      source: 'BWFS 04 - Wallet',
    },
    {
      id: 5,
      type: 'Transfer out',
      request_date: '2024/03/27',
      status: 'Pending',
      processed_date: '2024/03/27',
      amount: 2500,
      source: 'Wallet - External wallet',
    },
    {
      id: 6,
      type: 'Deposit',
      request_date: '2024/03/28',
      status: 'Success',
      processed_date: '2024/03/28',
      amount: 4000,
      source: 'Bank Transfer',
    },
    {
      id: 7,
      type: 'Withdrawal',
      request_date: '2024/03/29',
      status: 'Rejected',
      processed_date: '2024/03/29',
      amount: 3500,
      source: 'LMDD - Wallet',
    },
    {
      id: 8,
      type: 'Transfer in',
      request_date: '2024/03/30',
      status: 'Success',
      processed_date: '2024/03/30',
      amount: 5000,
      source: 'BWFS 04 - Wallet',
    },
    {
      id: 8,
      type: 'Transfer in',
      request_date: '2024/03/30',
      status: 'Success',
      processed_date: '2024/03/30',
      amount: 5000,
      source: 'BWFS 04 - Wallet',
    },
    {
      id: 8,
      type: 'Transfer in',
      request_date: '2024/03/30',
      status: 'Success',
      processed_date: '2024/03/30',
      amount: 5000,
      source: 'BWFS 04 - Wallet',
    },
  ],
  paginate: {
    current_page: 1,
    total_pages: 138,
    per_page: 10,
    records: 1385,
  },
});
