<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
// components
import ErrorField from "@commons/components/inputs/ErrorField.vue";
import InputGroup from "@commons/components/inputs/InputGroup.vue";
// schema
import { reduceAllocateRules } from "@/schemas/rules/allocateAlgorithimRules";
// helper
import formatNumber from "@commons/helpers/formatters/formatNumber";


const store = useStore();

const percentagesToShow = [0, 20, 40, 60, 80, 100];
const utilizedAmount = ref(0);
const utilizedPercentage = ref(0);
const investedBalance = ref(1000);
const availableAmount = computed(
  () => store.getters["algorithms/availableBalance"]?.available_balance
);

const rules = computed(() => reduceAllocateRules(investedBalance.value));
let vReduceAllocateRules$ = useVuelidate(rules, {
  utilizedAmount,
});

const checkValidation = async () => {
  return await vReduceAllocateRules$.value.$validate();
};
const setAmount = (amount) => {
  utilizedPercentage.value = (amount / investedBalance.value) * 100;
  vReduceAllocateRules$.value.$validate();
};
const setPercentage = (percentage) => {
  utilizedPercentage.value = percentage;
  utilizedAmount.value = (percentage * investedBalance.value) / 100;
};
watch(
  rules,
  () =>
    (vReduceAllocateRules$ = useVuelidate(rules, {
      utilizedAmount,
    }))
);
defineExpose({
  checkValidation,
  utilizedAmount,
  investedBalance,
});
</script>
<template>
  <div class="top_section-detail">
    <div class="form-group">
      <label for="amount"
        >Amount
        <span
          >Invested balance:
          {{ formatNumber(investedBalance - utilizedAmount, 2) }} USDT</span
        ></label
      >
      <InputGroup
        v-model="utilizedAmount"
        autocomplete="off"
        name="amount"
        id="amount"
        type="number"
        placeholder="Enter amount"
        icon-position="right"
        inputGroupClass="mb-0"
        @update:modelValue="setAmount"
      >
        <template #inputIcon> USDT </template>
      </InputGroup>
      <ErrorField errorIcon :validationErrors="vReduceAllocateRules$.utilizedAmount" />
    </div>
    <div class="top_section-detail-stepper-container">
      <div class="top_section-detail-stepper-progress">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :aria-valuenow="utilizedPercentage"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="`width: calc(${utilizedPercentage}%)`"
          ></div>
        </div>
      </div>
      <div class="top_section-detail-stepper-percentage">
        <div
          v-for="(item, index) in percentagesToShow"
          :key="index"
          :class="['main_percentage', { active: utilizedPercentage >= item }]"
          @click="setPercentage(item)"
          :style="`left: ${item}%`"
        >
          <div class="circle"></div>
          <div class="perc">-{{ item }}%</div>
        </div>
      </div>
    </div>
    <div class="top_section-detail-avail-bal">
      Available balance:
      {{ formatNumber(availableAmount + utilizedAmount, 2) }} USDT
    </div>
  </div>
</template>
