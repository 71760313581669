import generateRandomNumber from '@commons/helpers/generators/generateRandomNumber';

const successfullRecoverTwoFactorAuth = () => ({
  success: true,
  data: {
    message: 'Two-Factor Authentication code has been successfully recovered.',
  },
});
const failedRecoverTwoFactorAuth = () => ({
  success: false,
  data: {
    message:
      'Failed to recover Two-Factor Authentication code. Please check the provided codes and try again.',
  },
});
const recoverTwoFactorAuthScenario = [successfullRecoverTwoFactorAuth, failedRecoverTwoFactorAuth];
export const recoverTwoFactorAuth = () => {
  const rand = generateRandomNumber(0, recoverTwoFactorAuthScenario.length);
  if (typeof recoverTwoFactorAuthScenario[rand] !== 'undefined') {
    return recoverTwoFactorAuthScenario[rand]();
  }
  return successfullRecoverTwoFactorAuth();
};
