import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { deleteAllCookies } from '../cookies/deleteAllCookies';
import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import { useCustomToast } from '@/composables/toast/useCustomToast';

const captchaKey = process.env.VUE_APP_RECAPTCHA_KEY;

const addURLParams = (url, params) => {
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === 'number' || value) {
      url.searchParams.append(key, value);
    }
  });
};

const configureHeaders = async (isContentTypeJson, authHeaders, recaptcha_token) => {
  let access_token = store.getters['user/getToken'];

  const headers = {
    'Content-Type': isContentTypeJson ? 'application/json' : 'multipart/form-data',
  };

  if (recaptcha_token) {
    headers.recaptcha_token = recaptcha_token;
  }

  if (authHeaders) {
    Object.assign(headers, authHeaders);
  }

  if (access_token) {
    headers.Authorization = access_token;
  }
  return headers;
};

const createFormData = (body) => {
  try {
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  } catch (error) {
    console.log(error);
  }
};

export default async ({
  method,
  urlService,
  body,
  params = {},
  mockdata,
  adapter,
  debug,
  auth_headers = null,
  is_content_type_json = false,
}) => {
  if (debug) {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          resolve({ response: { ...adapter, ...mockdata() } });
        },
        randomNumber(500, 1500),
      );
    });
  }
  const url = new URL(urlService);
  if (params) {
    addURLParams(url, params);
  }
  const recaptcha_token = await new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha.execute(captchaKey, { action: 'api_connect' }).then(resolve).catch(reject);
    });
  });

  let data = is_content_type_json ? body : body ? createFormData(body) : null;
  const headers = await configureHeaders(is_content_type_json, auth_headers, recaptcha_token);

  try {
    const response = await axios({
      method,
      url: url.href,
      responseType: 'json',
      data,
      headers,
    });

    let responseData = response.data;

    if (responseData.success && responseData.data && !responseData.paginate && adapter) {
      // responseData = Array.isArray(responseData.data)
      //   ? [...adapter, ...responseData.data]
      //   : { ...adapter, ...responseData };
      responseData = { ...adapter, ...responseData };
    }
    return { response: responseData };
  } catch (err) {
    console.log(err);
    if (err?.response?.status === 401 && store.getters['user/getToken']) {
      deleteAllCookies();
      store.commit('user/setToken', '');
      router.push({ name: 'login' });
      useCustomToast({
        text: 'Session expired, please login again',
        type: 'error',
      });
    }
    console.log(err);
    const responseError = err.response;
    return { response: responseError };
  }
};
