import generateRandomNumber from '@commons/helpers/generators/generateRandomNumber';

const successfullSetupPhoneNumber = () => ({
  success: true,
  data: {
    message: 'Phone number has been successfully set up.',
  },
});
const failedSetupPhoneNumber = () => ({
  success: false,
  data: {
    message: 'Failed to set up phone number. Please check the provided details and try again.',
  },
});
const setupPhoneNumberScenario = [successfullSetupPhoneNumber, failedSetupPhoneNumber];
export const setupPhoneNumber = () => {
  const rand = generateRandomNumber(0, setupPhoneNumberScenario.length);
  if (typeof setupPhoneNumberScenario[rand] !== 'undefined') {
    return setupPhoneNumberScenario[rand]();
  }
  return successfullSetupPhoneNumber();
};
