import generateRandomNumber from '@commons/helpers/generators/generateRandomNumber';

const successfullSetupAntiPhishingCode = () => ({
  success: true,
  data: {
    message: 'You have successfully setup your anti-phishing code.',
  },
});
const failedSetupAntiPhishingCode = () => ({
  success: false,
  data: {
    message:
      'Failed to setup your anti-phishing code. Please check the provided codes and try again.',
  },
});
const setupAntiPhishingCodeScenario = [
  successfullSetupAntiPhishingCode,
  failedSetupAntiPhishingCode,
];
export const setupAntiPhishingCode = () => {
  const rand = generateRandomNumber(0, setupAntiPhishingCodeScenario.length);
  if (typeof setupAntiPhishingCodeScenario[rand] !== 'undefined') {
    return setupAntiPhishingCodeScenario[rand]();
  }
  return successfullSetupAntiPhishingCode();
};
