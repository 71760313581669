<template>
  <Datepicker
    v-model="innerValue"
    :clearable="clearable"
    :disabled="disabled"
    :enableTimePicker="enableTimePicker"
    :format="format"
    :is-24="is24"
    :min-date="minimumDate"
    :max-date="maximumDate"
    :placeholder="placeholder"
    :preview-format="previewFormat"
    :range="range"
    :utc="utc"
    :start-date="openDate"
  >
    <!--  -->
    <template #input-icon>
      <span><CalendarIcon /></span>
    </template>
    <template #clear-icon="{ clear }">
      <span @click="clear"><i class="bi bi-x"></i></span>
    </template>
  </Datepicker>
</template>

<script>
import { ref, computed, watch } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import CalendarIcon from "../../assets/images/icons/calendar-icon.vue";

export default {
  name: "datepicker_input",
  components: {
    Datepicker,
    CalendarIcon,
  },
  props: {
    modelValue: [String, Date, Array],
    clearable: [Boolean],
    disabled: [Boolean],
    enableTimePicker: [Boolean],
    format: {
      default: () => "yyyy/MM/dd",
      type: String,
    },
    is24: [Boolean],
    maxDate: {
      default: () => undefined,
      type: [Date, String],
    },
    minDate: {
      default: () => undefined,
      type: [Date, String],
    },
    placeholder: {
      default: () => "Select date",
      type: [String],
    },
    previewFormat: [String],
    range: {
      default: () => false,
      type: Boolean,
    },
    startDate: [Date, String],
    utc: {
      default: () => true,
      type: [Boolean],
    },
  },
  setup(props, { emit }) {
    const innerValue = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newValue) => {
        innerValue.value = newValue;
      }
    );

    watch(innerValue, (newValue) => {
      emit("update:modelValue", newValue);
    });

    const parseDate = (date) => (date ? new Date(date) : undefined);
    const minimumDate = computed(() => parseDate(props.minDate));
    const maximumDate = computed(() => parseDate(props.maxDate));
    const openDate = maximumDate.value;

    return {
      innerValue,
      maximumDate,
      minimumDate,
      openDate,
    };
  },
};
</script>
