<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import columns from "../../../schemas/table_columns/transactionDashboardTableColumns";
// components
import Paginate from "@commons/components/tables/PaginateComponent.vue";
import Table from "@commons/components/tables/TableComponent.vue";
import TransactionFiltersModal from "@/components/commons/TransactionFiltersModal.vue";
// composables
import { useTableManagement } from "@commons/composables/useTableManagement";
// Helpers
import { optionsItemsPerPage } from "@/config/constants";
import formatNumber from "@commons/helpers/formatters/formatNumber";
// styles
import "@/assets/styles/transaction_table.scss";
// icons
import FilterIcon from "@/assets/icons/filter-icon.vue";
import Multiselect from "@commons/components/inputs/Multiselect.vue";
import GroupIcon from "@/assets/icons/group-icon.vue";

const store = useStore();
const stateModuleName = "dashboard";
const stateFetch = "transactionList";
const stateFetchMethod = "getTransactionList";
const sorting_state = ref({
  sort_by: "",
  order: "",
});
const filter_state = ref({
  search: "",
  type: "",
  from: "",
  to: "",
  request_date: "",
  processed_date: "",
  status: "",
  amount: "",
});
const appliedFilterLength = computed(() => {
  return Object.keys(filter_state.value).filter((key) => filter_state.value[key]).length;
});
const fetchData = async (rest) => {
  const res = await store.dispatch(`${stateModuleName}/${stateFetchMethod}`, {
    ...rest,
    ...sorting_state.value,
    ...filter_state.value,
  });
  return res;
};

const {
  statusFetch,
  tableData,
  current_page,
  total_pages,
  per_page,
  handleGoFirstPage,
  handleGoLastPage,
  handleGoNextPage,
  handleGoPrevPage,
  handleChangeItemsPerPage,
} = useTableManagement(stateModuleName, stateFetch, fetchData);
const statusClass = (statusFetch) => {
  return {
    success: statusFetch === "Success",
    error: statusFetch === "Rejected",
    warning: statusFetch === "Pending",
  };
};
const windowsWidth = ref(window.innerWidth);
const selectedColumns = computed({
  get: () => {
    return columns.map((col, idx) => {
      col.selected = true;
      if (idx > 3 && windowsWidth.value <= 1024) {
        col.selected = false;
      }
      return col;
    });
  },
  set: (value) => {
    return value;
  },
});
const newColumns = ref(selectedColumns.value.filter((i) => i.selected));
const wallet_list = computed(() => store.getters["wallet/getWalletList"]._data?.data);

const sortHandler = (field) => {
  newColumns.value.forEach((col) => {
    if (col.field == field.field) {
      col.sortItem.order == "desc"
        ? (col.sortItem.order = "asc")
        : (col.sortItem.order = "desc");
      sorting_state.value = {
        sort_by: field.sortItem.field,
        order: field.sortItem.order,
      };
    } else {
      col.sortItem.order = "desc";
    }
  });
  fetchData({
    page: current_page.value,
    per_page: per_page.value,
  });
};
const fetchByFilters = async (filters) => {
  filter_state.value = filters;
  const res = await fetchData({
    page: 1,
    per_page: per_page.value,
  });
  if (res.success) {
    modalHandler(false);
  }
};
const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
  }
};
onMounted(() => {
  window.addEventListener("resize", () => {
    windowsWidth.value = window.innerWidth;
    if (windowsWidth.value > 1024) {
      selectedColumns.value = columns;
    } else {
      selectedColumns.value = columns.map((col, idx) => {
        col.selected = true;
        if (idx > 3) {
          col.selected = false;
        }
        return col;
      });
    }
  });
});
onUnmounted(() => {
  window.removeEventListener("resize", () => {
    windowsWidth.value = window.innerWidth;
  });
});
const columnChange = (e) => {
  selectedColumns.value.forEach((i) => {
    if (i.field == e.target.id) {
      if (newColumns.value.length == 4 && e.target.checked) {
        e.target.checked = false;
        return;
      } else {
        i.selected = e.target.checked;
      }
    }
  });
  newColumns.value = selectedColumns.value.filter((i) => i.selected);
};
const clearAllColumn = () => {
  selectedColumns.value.forEach((i) => {
    if (i.field != "type") {
      i.selected = false;
    }
  });
  newColumns.value = selectedColumns.value.filter((i) => i.selected);
};

</script>

<template>
  <div
    class="component_dashboard_transactions"
  >
    <div>
      <div class="table_header_container">
        <div class="table_heading">Transactions</div>
        <div class="table_filter">
          <div class="dropdown">
            <GroupIcon
              class="dropdown-toggle column_filter-icon"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            />
            <ul class="dropdown-menu">
              <li
                class="dropdown_list-item"
                v-for="(col, idx) in selectedColumns"
                :key="idx"
              >
                <input
                  class="form-check-input"
                  :value="col.selected"
                  :checked="col.selected"
                  :disabled="idx == 0"
                  type="checkbox"
                  :id="col.field"
                  @change="columnChange"
                />
                <label :for="col.field">{{ col.title }}</label>
              </li>
              <li class="dropdown_list-item">
                <div>Selected:{{ newColumns.length }}/4</div>
                <button class="clear_all" type="button" @click="clearAllColumn">
                  Clear all
                </button>
              </li>
            </ul>
          </div>
          <span
            class="filter_icon"
            @click="
              !statusFetch._on_loading ? modalHandler(true, 'transaction_filters') : null
            "
          >
            <span class="badge" v-if="appliedFilterLength">
              {{ appliedFilterLength }}
            </span>
            <FilterIcon />
          </span>
        </div>
      </div>
      <Table
        :columns="newColumns"
        :data="tableData"
        :status="statusFetch"
        class="w-100 custom_table"
        headerWithIcon
        @sort-change="sortHandler"
        :skeleton-rows="7"
      >
        <template v-slot:amountSlot="{ row }">
          <span class="is-number">${{ formatNumber(row.amount) }}</span></template
        >
        <template v-slot:statusSlot="{ row }">
          <div :class="['table_status', statusClass(row.status)]">
            <span class="status_dot"></span>
            {{ row.status }}
          </div>
        </template>
      </Table>
      <div class="pagination_container">
        <div class="pagination_by_page">
          <Multiselect
            v-model="per_page"
            :options="optionsItemsPerPage"
            :canClear="false"
            @change="handleChangeItemsPerPage"
            openPlacement="top"
          />
          <span>items per page</span>
        </div>
        <Paginate
          :pagination="{
            current_page: current_page,
            total_pages: total_pages,
          }"
          @goFirstPage="handleGoFirstPage"
          @goLastPage="handleGoLastPage"
          @goNextPage="handleGoNextPage"
          @goPrevPage="handleGoPrevPage"
        />
      </div>
    </div>

    <TransactionFiltersModal @fetchByFilters="fetchByFilters" />
  </div>
</template>
