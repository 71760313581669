<template>
  <div class="toggle-switch">
    <label>
      <input type="checkbox" :checked="modelValue" @change="toggleSwitch" />
      <span class="slider" :class="{ checked: modelValue }"></span>
    </label>
    <span>{{ label }}</span>
  </div>
</template>

<script setup>

import "@/assets/styles/toggle_switch.scss"

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const toggleSwitch = () => {
  emit("update:modelValue", !props.modelValue);
};
</script>
