import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import randomString from '@commons/helpers/generators/generateRandomString';
import { algorithmsName } from '@/config/constants';

export default () => ({
  success: true,
  data: [
    {
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      address: randomString(20),
      default: false,
    },
    {
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      address: randomString(20),
      default: true,
    },
    {
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      address: randomString(15),
      default: false,
    },
    {
      id: randomNumber(0, 100000000000000000),
      name: algorithmsName[randomNumber(0, algorithmsName.length)],
      address: randomString(15),
      default: false,
    },
  ],
  paginate: {
    current_page: 1,
    total_pages: 138,
    per_page: 10,
    records: 1375,
  },
});
