<script setup>
import { computed } from "vue";

const props = defineProps({
  description: {
    default: () => "You have no information to display.",
    type: String,
  },
  title: {
    default: () => "Hey!",
    type: String,
  },
  type: {
    default: () => "warning",
    type: String,
  },
});

const iconsClassess = {
  error: "bi bi-exclamation-circle text-danger",
  success: "bi bi-check-circle text-success",
  warning: "bi bi-info-circle text-warning",
};

const currentIconClasses = computed(() => {
  const alertType = props.type?.toLocaleLowerCase();
  return iconsClassess[alertType];
});
</script>

<template>
  <div class="alert-box horizontal">
    <div class="d-flex align-items-baseline gap-2">
      <span><i :class="currentIconClasses"></i></span>
      <h2 class="alert-title mb-0">{{ title }}</h2>
    </div>
    <div v-if="description" class="alert-description">{{ description }}</div>
  </div>
</template>
