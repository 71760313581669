<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_177_7902)">
      <path
        d="M13.0001 20.0001C14.3257 19.9985 15.5966 19.4712 16.5339 18.5339C17.4712 17.5965 17.9985 16.3257 18.0001 15.0001V6.24308C18.0017 5.71744 17.8989 5.19672 17.6977 4.7111C17.4965 4.22548 17.2009 3.78462 16.8281 3.41408L14.5861 1.17208C14.2156 0.799252 13.7747 0.503681 13.2891 0.302499C12.8035 0.101316 12.2828 -0.00147692 11.7571 7.84247e-05H7.00012C5.67453 0.00166628 4.40368 0.52896 3.46634 1.4663C2.529 2.40364 2.00171 3.67448 2.00012 5.00008V15.0001C2.00171 16.3257 2.529 17.5965 3.46634 18.5339C4.40368 19.4712 5.67453 19.9985 7.00012 20.0001H13.0001ZM4.00012 15.0001V5.00008C4.00012 4.20443 4.31619 3.44137 4.8788 2.87876C5.44141 2.31615 6.20447 2.00008 7.00012 2.00008C7.00012 2.00008 11.9191 2.01408 12.0001 2.02408V4.00008C12.0001 4.53051 12.2108 5.03922 12.5859 5.41429C12.961 5.78936 13.4697 6.00008 14.0001 6.00008H15.9761C15.9861 6.08108 16.0001 15.0001 16.0001 15.0001C16.0001 15.7957 15.6841 16.5588 15.1214 17.1214C14.5588 17.684 13.7958 18.0001 13.0001 18.0001H7.00012C6.20447 18.0001 5.44141 17.684 4.8788 17.1214C4.31619 16.5588 4.00012 15.7957 4.00012 15.0001ZM22.0001 8.00008V19.0001C21.9985 20.3257 21.4712 21.5965 20.5339 22.5339C19.5966 23.4712 18.3257 23.9985 17.0001 24.0001H8.00012C7.73491 24.0001 7.48055 23.8947 7.29302 23.7072C7.10548 23.5196 7.00012 23.2653 7.00012 23.0001C7.00012 22.7349 7.10548 22.4805 7.29302 22.293C7.48055 22.1054 7.73491 22.0001 8.00012 22.0001H17.0001C17.7958 22.0001 18.5588 21.684 19.1214 21.1214C19.6841 20.5588 20.0001 19.7957 20.0001 19.0001V8.00008C20.0001 7.73486 20.1055 7.48051 20.293 7.29297C20.4806 7.10544 20.7349 7.00008 21.0001 7.00008C21.2653 7.00008 21.5197 7.10544 21.7072 7.29297C21.8948 7.48051 22.0001 7.73486 22.0001 8.00008Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_177_7902">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
