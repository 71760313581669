import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const invalidFields = () => ({
  success: false,
  data: {
    message: 'Error validation',
  },
  errors: {
    wallet: ['The team mail must be valid.'],
    amount: ['amount.'],
    email_code: ['email_code must be valid.'],
    phone_code: ['phone_code must be valid.'],
  },
});

const successWithdraw = () => ({
  success: true,
  data: {
    message: 'your request for withdrawal fund is submitted successfully',
  },
});

const withdrawScenarios = [successWithdraw, invalidFields];

export const getWithdrawWalletMock = () => {
  const rand = randomNumber(0, withdrawScenarios.length);

  if (typeof withdrawScenarios[rand] !== 'undefined') {
    return withdrawScenarios[rand]();
  }
  return '';
};
