<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_601_1323)">
      <path
        d="M7.5 0C6.01664 0 4.5666 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324965 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C14.9979 5.51154 14.207 3.60513 12.8009 2.19907C11.3949 0.793018 9.48847 0.00215068 7.5 0V0ZM7.5 13.75C6.26387 13.75 5.0555 13.3834 4.02769 12.6967C2.99988 12.0099 2.1988 11.0338 1.72576 9.89177C1.25271 8.74973 1.12894 7.49307 1.3701 6.28069C1.61125 5.06831 2.20651 3.95466 3.08059 3.08058C3.95466 2.2065 5.06831 1.61125 6.28069 1.37009C7.49307 1.12893 8.74974 1.25271 9.89178 1.72575C11.0338 2.1988 12.0099 2.99988 12.6967 4.02769C13.3834 5.05549 13.75 6.26387 13.75 7.5C13.7482 9.15705 13.0891 10.7457 11.9174 11.9174C10.7457 13.0891 9.15705 13.7482 7.5 13.75Z"
        fill="currentColor"
      />
      <path
        d="M7.49988 6.25018H6.87488C6.70912 6.25018 6.55015 6.31603 6.43294 6.43324C6.31573 6.55045 6.24988 6.70942 6.24988 6.87518C6.24988 7.04094 6.31573 7.19991 6.43294 7.31712C6.55015 7.43433 6.70912 7.50018 6.87488 7.50018H7.49988V11.2502C7.49988 11.4159 7.56572 11.5749 7.68293 11.6921C7.80014 11.8093 7.95911 11.8752 8.12487 11.8752C8.29063 11.8752 8.44961 11.8093 8.56682 11.6921C8.68403 11.5749 8.74987 11.4159 8.74987 11.2502V7.50018C8.74987 7.16866 8.61818 6.85072 8.38376 6.6163C8.14934 6.38188 7.8314 6.25018 7.49988 6.25018Z"
        fill="currentColor"
      />
      <path
        d="M7.5 4.99982C8.01777 4.99982 8.4375 4.58008 8.4375 4.06232C8.4375 3.54455 8.01777 3.12482 7.5 3.12482C6.98223 3.12482 6.5625 3.54455 6.5625 4.06232C6.5625 4.58008 6.98223 4.99982 7.5 4.99982Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_601_1323">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
