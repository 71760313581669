<template>
  <ul class="pagination" role="menubar" aria-label="Pagination">
    <li :class="{ disabled: pagination.current_page == 1 }">
      <a @click="pagination.current_page > 1 ? goFirstPage() : () => {}">
        <span>
          <DoubleChevronLeftIcon />
        </span>
      </a>
    </li>
    <!--  -->
    <li :class="{ disabled: pagination.current_page == 1 }">
      <a @click="pagination.current_page > 1 ? goPrevPage() : () => {}">
        <span>
          <ChevronLeftSmallIcon />
        </span>
      </a>
    </li>
    <!--  -->
    <li class="current">
      <a>{{ pagination.current_page }}</a>
    </li>
    <!--  -->
    <li :class="{ disabled: pagination.current_page >= pagination.total_pages }">
      <a
        @click="
          pagination.current_page < pagination.total_pages ? goNextPage() : () => {}
        "
      >
        <span>
          <ChevronRightSmallIcon />
        </span>
      </a>
    </li>
    <li :class="{ disabled: pagination.current_page >= pagination.total_pages }">
      <a
        @click="
          pagination.current_page < pagination.total_pages ? goLastPage() : () => {}
        "
        ><span>
          <DoubleChevronRightIcon /> </span
      ></a>
    </li>
  </ul>
</template>

<script setup>
import ChevronLeftSmallIcon from "@commons/assets/images/icons/chevron-left-small-icon.vue";
import ChevronRightSmallIcon from "@commons/assets/images/icons/chevron-right-small-icon.vue";
import DoubleChevronLeftIcon from "@commons/assets/images/icons/double-chevron-left-icon.vue";
import DoubleChevronRightIcon from "@commons/assets/images/icons/double-chevron-right-icon.vue";

// Props
defineProps({
  pagination: {
    default: () => {
      return {
        current_page: 1,
        total_pages: 1,
      };
    },
    type: Object,
  },
});

// Emit
const emit = defineEmits(["goFirstPage", "goLastPage", "goNextPage", "goPrevPage"]);

// Métodos como funciones
const goFirstPage = () => emit("goFirstPage");
const goLastPage = () => emit("goLastPage");
const goNextPage = () => emit("goNextPage");
const goPrevPage = () => emit("goPrevPage");
</script>
