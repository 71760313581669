import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const successOverviewChart = () => ({
  success: true,
  data: [
    {
      date: '2024-01-01T00:00:00Z',
      nav: 4569269.26,
      exposure: 1174823.38,
      exposure_percentage: 10,
      maxMinNav: 7250757.14,
    },
    {
      date: '2024-01-02T00:00:00Z',
      nav: 6856852.35,
      exposure: 2558547.36,
      exposure_percentage: 21,
      maxMinNav: 7532000.35,
    },
    {
      date: '2024-01-03T00:00:00Z',
      nav: 5056984.65,
      exposure: 2587093.54,
      exposure_percentage: 33.25,
      maxMinNav: 5620789.84,
    },
    {
      date: '2024-01-04T00:00:00Z',
      nav: 5289657.98,
      exposure: 6584268.87,
      exposure_percentage: 54.2,
      maxMinNav: 4205815.36,
    },
    {
      date: '2024-01-05T00:00:00Z',
      nav: 6238536.12,
      exposure: 2884632.89,
      exposure_percentage: 42.67,
      maxMinNav: 4508985.98,
    },
    {
      date: '2024-01-06T00:00:00Z',
      nav: 2375205.35,
      exposure: 8467858.25,
      exposure_percentage: 54.89,
      maxMinNav: 1548752.44,
    },
    {
      date: '2024-01-07T00:00:00Z',
      nav: 5687845.25,
      exposure: 4587689.85,
      exposure_percentage: 57.32,
      maxMinNav: 2345468.98,
    },
    {
      date: '2024-01-08T00:00:00Z',
      nav: 7045895.25,
      exposure: 3255698.23,
      exposure_percentage: 15.39,
      maxMinNav: 8765154.25,
    },
    {
      date: '2024-01-09T00:00:00Z',
      nav: 6545785.96,
      exposure: 4452635.98,
      exposure_percentage: 42.78,
      maxMinNav: 2136458.36,
    },
    {
      date: '2024-01-10T00:00:00Z',
      nav: 4578326.85,
      exposure: 6586487.23,
      exposure_percentage: 21.36,
      maxMinNav: 4568784.23,
    },
    {
      date: '2024-01-11T00:00:00Z',
      nav: 5748685.26,
      exposure: 5956785.26,
      exposure_percentage: 12.56,
      maxMinNav: 3926285.25,
    },
  ],
});

const overviewChartScenarios = [successOverviewChart];

export const mockOverviewChart = () => {
  const rand = randomNumber(0, overviewChartScenarios.length);

  if (typeof overviewChartScenarios[rand] !== 'undefined') {
    return overviewChartScenarios[rand]();
  }
  return successOverviewChart;
};
