<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

//  components
import BasicModal from "@commons/components/modals/ModalBase.vue";
import Button from "@commons/components/buttons/ButtonComponent.vue";
import ToggleSwitch from "@/components/commons/ToggleSwitch.vue";

//styles
import "@/assets/styles/settings_overview.scss";

const store = useStore();

const showDailyNav = ref(false);
const showExposure = ref(false);
const showMaxMinNav = ref(false);
const legendTab = ref(true);

const props = defineProps({
  applyFilters: Function,
});

const fetchData = async () => {
  const filters = {
    showDailyNav: showDailyNav.value,
    showExposure: showExposure.value,
    showMaxMinNav: showMaxMinNav.value,
  };
  props.applyFilters(filters);
  modalHandler(false, "settings_overview");
};

const disableButton = computed(() => {
  return !!(showDailyNav.value || showExposure.value || showMaxMinNav.value);
});

const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
  }
};
</script>

<template>
  <div class="component_settings_overview_modal">
    <BasicModal
      headerTitle="Settings"
      name="settings_overview"
      classNameHeader="card-bg-dark"
      className="settings_overview"
      :onClose="modalHandler"
    >
      <div class="modal-container">
        <div class="settings_container">
          <div
            :class="['settings-btn', { active: legendTab }]"
            @click="legendTab = true"
          >
            Legend
          </div>
          <div
            :class="['settings-btn', { active: !legendTab }]"
            @click="legendTab = false"
          >
            Filters
          </div>
        </div>
        <div class="settings_content">
          <div v-if="legendTab" class="legend_tab">
            <h2>
              In this section, you can show or hide the lines or bars of the
              chart according to your preferences. Simply select or deselect the
              available options to customize the data display.
            </h2>

            <ToggleSwitch v-model="showDailyNav" label="Show daily NAV" />
            <ToggleSwitch v-model="showExposure" label="Show exposure" />
            <ToggleSwitch
              v-model="showMaxMinNav"
              label="Show maximum or minimum NAV"
            />
          </div>
          <div v-if="!legendTab">filters content</div>
        </div>
      </div>
      <div slot:footer class="modal-footer justify-content-end">
        <div class="btn-container">
          <Button
            text="Cancel"
            type="text"
            className="text-white"
            class="btn-border"
            @click="modalHandler(false)"
          />

          <Button
            type="secondary"
            text="Apply"
            :class="{ btn_download: disableButton }"
            @click="fetchData()"
          />
        </div>
      </div>
    </BasicModal>
  </div>
</template>
