import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const errorResponse = () => ({
  success: false,
  data: {
    message: 'Error, something wrong happened',
  },
});

const successDownloadReport = () => ({
  success: true,
  data: {
    fileUrl: 'https://example.com/report.pdf',
  },
});

const downloadReportScenarios = [successDownloadReport, errorResponse];

export const mockDownloadReport = () => {
  const rand = randomNumber(0, downloadReportScenarios.length);

  if (typeof downloadReportScenarios[rand] !== 'undefined') {
    return downloadReportScenarios[rand]();
  }
  return mockDownloadReport;
};
