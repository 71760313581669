import { setupPhoneNumberService } from '@/services/profile/userProfileService';

const field = 'setupPhoneNumber';

export default async ({ commit }, body) => {
  try {
    commit('setIsLoading', { field });
    const { response } = await setupPhoneNumberService({ body });
    if (response.error) {
      commit('setIsError', { field });
    } else {
      commit('setIsReady', { field });
    }
    return response;
  } catch (e) {
    commit('setIsError', { field });
    return {
      success: false,
      message: e.message,
    };
  }
};
