import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import { algorithmsName, transactionsType, transactionsStatus } from '@/config/constants';

export default () => ({
  success: true,
  data: [
    {
      id: randomNumber(0, 100000000000000000),
      user_id: randomNumber(0, 100000000000000000),
      quantity: randomNumber(0, 1000000),
      type: transactionsType[randomNumber(0, transactionsType.length)],
      date: '2024-03-11 05:21:39',
      source: 'Wallet',
      destiny: algorithmsName[randomNumber(0, algorithmsName.length)],
      status: transactionsStatus[randomNumber(0, transactionsStatus.length)],
    },
    {
      id: randomNumber(0, 100000000000000000),
      user_id: randomNumber(0, 100000000000000000),
      quantity: randomNumber(0, 1000000),
      type: transactionsType[randomNumber(0, transactionsType.length)],
      date: '2024-03-11 05:21:39',
      source: 'Wallet',
      destiny: algorithmsName[randomNumber(0, algorithmsName.length)],
      status: transactionsStatus[randomNumber(0, transactionsStatus.length)],
    },
    {
      id: randomNumber(0, 100000000000000000),
      user_id: randomNumber(0, 100000000000000000),
      quantity: randomNumber(0, 1000000),
      type: transactionsType[randomNumber(0, transactionsType.length)],
      date: '2024-03-11 05:21:39',
      source: 'Wallet',
      destiny: algorithmsName[randomNumber(0, algorithmsName.length)],
      status: transactionsStatus[randomNumber(0, transactionsStatus.length)],
    },
    {
      id: randomNumber(0, 100000000000000000),
      user_id: randomNumber(0, 100000000000000000),
      quantity: randomNumber(0, 1000000),
      type: transactionsType[randomNumber(0, transactionsType.length)],
      date: '2024-03-11 05:21:39',
      source: 'Wallet',
      destiny: algorithmsName[randomNumber(0, algorithmsName.length)],
      status: transactionsStatus[randomNumber(0, transactionsStatus.length)],
    },
    {
      id: randomNumber(0, 100000000000000000),
      user_id: randomNumber(0, 100000000000000000),
      quantity: randomNumber(0, 1000000),
      type: transactionsType[randomNumber(0, transactionsType.length)],
      date: '2024-03-11 05:21:39',
      source: 'Wallet',
      destiny: algorithmsName[randomNumber(0, algorithmsName.length)],
      status: transactionsStatus[randomNumber(0, transactionsStatus.length)],
    },
  ],
  paginate: {
    current_page: 1,
    total_pages: 138,
    per_page: 10,
    records: 1375,
  },
});
