<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_601_1366)">
      <path
        d="M6.17389 11.3263C6.34802 11.5005 6.55477 11.6387 6.78233 11.733C7.0099 11.8273 7.25381 11.8759 7.50014 11.8759C7.74647 11.8759 7.99039 11.8273 8.21795 11.733C8.44551 11.6387 8.65226 11.5005 8.82639 11.3263L10.8333 9.31938C10.9409 9.20037 10.9986 9.04455 10.9945 8.88416C10.9903 8.72378 10.9247 8.57112 10.8111 8.45779C10.6976 8.34447 10.5448 8.27916 10.3844 8.27538C10.224 8.2716 10.0683 8.32965 9.94951 8.4375L8.12077 10.2669L8.12514 0.625C8.12514 0.45924 8.05929 0.300269 7.94208 0.183058C7.82487 0.065848 7.6659 0 7.50014 0V0C7.33438 0 7.17541 0.065848 7.0582 0.183058C6.94099 0.300269 6.87514 0.45924 6.87514 0.625L6.86952 10.255L5.05077 8.4375C4.93349 8.32031 4.77446 8.2545 4.60867 8.25456C4.44288 8.25462 4.2839 8.32054 4.1667 8.43781C4.04951 8.55509 3.98371 8.71412 3.98376 8.87991C3.98382 9.0457 4.04974 9.20468 4.16702 9.32188L6.17389 11.3263Z"
        fill="#898C9B"
      />
      <path
        d="M14.375 9.99994C14.2092 9.99994 14.0503 10.0658 13.9331 10.183C13.8158 10.3002 13.75 10.4592 13.75 10.6249V13.1249C13.75 13.2907 13.6842 13.4497 13.5669 13.5669C13.4497 13.6841 13.2908 13.7499 13.125 13.7499H1.875C1.70924 13.7499 1.55027 13.6841 1.43306 13.5669C1.31585 13.4497 1.25 13.2907 1.25 13.1249V10.6249C1.25 10.4592 1.18415 10.3002 1.06694 10.183C0.949732 10.0658 0.79076 9.99994 0.625 9.99994C0.45924 9.99994 0.300269 10.0658 0.183058 10.183C0.065848 10.3002 0 10.4592 0 10.6249L0 13.1249C0 13.6222 0.197544 14.0991 0.549175 14.4508C0.900805 14.8024 1.37772 14.9999 1.875 14.9999H13.125C13.6223 14.9999 14.0992 14.8024 14.4508 14.4508C14.8025 14.0991 15 13.6222 15 13.1249V10.6249C15 10.4592 14.9342 10.3002 14.8169 10.183C14.6997 10.0658 14.5408 9.99994 14.375 9.99994Z"
        fill="#898C9B"
      />
    </g>
    <defs>
      <clipPath id="clip0_601_1366">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
