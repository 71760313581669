<script setup>
// components
import BaseModal from "@commons/components/modals/ModalBase.vue";
import Button from "@commons/components/buttons/ButtonComponent.vue";

// icons
import WarningTriangleIcon from "@/assets/icons/warning-triangle-icon.vue";

// style
import "@/assets/styles/confirmation_modal.scss";

const props = defineProps({
  title: String,
  message: String,
  confirmText: {
    default: "Yes",
    type: String,
  },
  cancelText: {
    default: "No",
    type: String,
  },
  confirmButtonLoading: {
    default: false,
    type: Boolean,
  },
  confirmation_modal_name: {
    default: "confirmation_modal",
    type: String,
  },
  modalIcon: {
    default: WarningTriangleIcon,
    type: Object,
  },
});
const emit = defineEmits(["onConfirm", "onCancel"]);
</script>
<template>
  <BaseModal :name="confirmation_modal_name" classNameHeader="d-none">
    <template #default>
      <div class="confirmation_modal_container">
        <div class="confirmation_modal-icon">
          <component :is="modalIcon" />
        </div>
        <div class="">
          <h4 class="confirmation_modal-heading">{{ props.title }}</h4>
          <p class="confirmation_modal-message">{{ props.message }}</p>
        </div>
        <div class="confirmation_modal_footer">
          <Button
            :text="props.cancelText"
            type="text"
            className="confirmation_modal-cancel_btn"
            @click="emit('onCancel')"
          />
          <Button
            type="secondary"
            :text="props.confirmText"
            className="confirmation_modal-confirm_btn"
            @click="emit('onConfirm')"
            :isLoading="confirmButtonLoading"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>
