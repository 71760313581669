import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import randomDecimal from '@commons/helpers/generators/generateRandomDecimal';

const performanceMock = () => ({
  success: true,
  data: {
    performance: randomNumber(0, 1000000),
    performance_percentage: randomDecimal(10, 10),
    radial_bar_percentage: randomNumber(0, 100),
  },
});

export default performanceMock;
