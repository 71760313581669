const generateLoadingMutations = () => {
  const updateStatus = (state, field, { onReady, onLoading, onError }) => {
    if (state[field]) {
      state[field]._status = {
        _on_ready: !!onReady,
        _on_loading: !!onLoading,
        _on_error: !!onError
      }
    }
  }

  return {
    resetStatus: (state, { field }) =>
      updateStatus(state, field, { onReady: false, onLoading: false, onError: false }),
    setIsLoading: (state, { field }) =>
      updateStatus(state, field, { onReady: false, onLoading: true, onError: false }),
    setIsReady: (state, { field }) =>
      updateStatus(state, field, { onReady: true, onLoading: false, onError: false }),
    setIsError: (state, { field }) =>
      updateStatus(state, field, { onReady: false, onLoading: false, onError: true })
  }
}

export default generateLoadingMutations
