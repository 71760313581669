export default {
  success: true,
  data: {
    token: null,
    email: null,
    totp_token: null,
    created_at: null,
    updated_at: null,
  },
  token: null,
  cookie_auth: '',
  message: '',
};
