<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <g clip-path="url(#clip0_721_1089)">
      <path
        d="M13.1249 2.5H11.1874C11.0424 1.79463 10.6586 1.16084 10.1007 0.705449C9.54286 0.250056 8.84507 0.000909078 8.12494 0L6.87494 0C6.15481 0.000909078 5.45701 0.250056 4.89916 0.705449C4.3413 1.16084 3.9575 1.79463 3.81244 2.5H1.87494C1.70918 2.5 1.55021 2.56585 1.433 2.68306C1.31579 2.80027 1.24994 2.95924 1.24994 3.125C1.24994 3.29076 1.31579 3.44973 1.433 3.56694C1.55021 3.68415 1.70918 3.75 1.87494 3.75H2.49994V11.875C2.50093 12.7035 2.83049 13.4978 3.41633 14.0836C4.00216 14.6694 4.79644 14.999 5.62494 15H9.37494C10.2034 14.999 10.9977 14.6694 11.5836 14.0836C12.1694 13.4978 12.4989 12.7035 12.4999 11.875V3.75H13.1249C13.2907 3.75 13.4497 3.68415 13.5669 3.56694C13.6841 3.44973 13.7499 3.29076 13.7499 3.125C13.7499 2.95924 13.6841 2.80027 13.5669 2.68306C13.4497 2.56585 13.2907 2.5 13.1249 2.5ZM6.87494 1.25H8.12494C8.51261 1.25047 8.89064 1.37086 9.2072 1.59465C9.52375 1.81844 9.76332 2.13468 9.89306 2.5H5.10681C5.23655 2.13468 5.47613 1.81844 5.79268 1.59465C6.10923 1.37086 6.48727 1.25047 6.87494 1.25ZM11.2499 11.875C11.2499 12.3723 11.0524 12.8492 10.7008 13.2008C10.3491 13.5525 9.87222 13.75 9.37494 13.75H5.62494C5.12766 13.75 4.65074 13.5525 4.29911 13.2008C3.94748 12.8492 3.74994 12.3723 3.74994 11.875V3.75H11.2499V11.875Z"
        fill="currentColor"
      />
      <path
        d="M6.25 11.2495C6.41576 11.2495 6.57473 11.1837 6.69194 11.0664C6.80915 10.9492 6.875 10.7903 6.875 10.6245V6.87451C6.875 6.70875 6.80915 6.54978 6.69194 6.43257C6.57473 6.31536 6.41576 6.24951 6.25 6.24951C6.08424 6.24951 5.92527 6.31536 5.80806 6.43257C5.69085 6.54978 5.625 6.70875 5.625 6.87451V10.6245C5.625 10.7903 5.69085 10.9492 5.80806 11.0664C5.92527 11.1837 6.08424 11.2495 6.25 11.2495Z"
        fill="currentColor"
      />
      <path
        d="M8.74994 11.2495C8.9157 11.2495 9.07468 11.1837 9.19189 11.0664C9.3091 10.9492 9.37495 10.7903 9.37495 10.6245V6.87451C9.37495 6.70875 9.3091 6.54978 9.19189 6.43257C9.07468 6.31536 8.9157 6.24951 8.74994 6.24951C8.58418 6.24951 8.42521 6.31536 8.308 6.43257C8.19079 6.54978 8.12494 6.70875 8.12494 6.87451V10.6245C8.12494 10.7903 8.19079 10.9492 8.308 11.0664C8.42521 11.1837 8.58418 11.2495 8.74994 11.2495Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_721_1089">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
