<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <circle cx="3.99984" cy="4.00594" r="3.33333" fill="#EDEDED" />
  </svg>
</template>
