<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.25418 0.68955C8.2365 -0.22985 9.7635 -0.22985 10.7458 0.68955L11.5964 1.48568C11.8362 1.71007 12.1493 1.83975 12.4775 1.85061L13.6419 1.88914C14.9866 1.93363 16.0664 3.01338 16.1109 4.3581L16.1494 5.52255C16.1602 5.85072 16.2899 6.1638 16.5143 6.40356L17.3104 7.25418C18.2299 8.2365 18.2299 9.7635 17.3104 10.7458L16.5143 11.5964C16.2899 11.8362 16.1602 12.1493 16.1494 12.4775L16.1109 13.6419C16.0664 14.9866 14.9866 16.0664 13.6419 16.1109L12.4774 16.1494C12.1493 16.1602 11.8362 16.2899 11.5964 16.5143L10.7458 17.3104C9.7635 18.2299 8.2365 18.2299 7.25418 17.3104L6.40356 16.5143C6.1638 16.2899 5.85072 16.1602 5.52255 16.1494L4.3581 16.1109C3.01338 16.0664 1.93363 14.9866 1.88914 13.6419L1.85061 12.4775C1.83975 12.1493 1.71007 11.8362 1.48568 11.5964L0.68955 10.7458C-0.22985 9.7635 -0.22985 8.2365 0.68955 7.25418L1.48568 6.40356C1.71007 6.1638 1.83975 5.85072 1.85061 5.52255L1.88914 4.3581C1.93363 3.01338 3.01338 1.93363 4.3581 1.88914L5.52255 1.85061C5.85072 1.83975 6.1638 1.71007 6.40356 1.48568L7.25418 0.68955ZM12.738 7.01004C12.9645 6.76815 12.952 6.38844 12.71 6.162C12.4681 5.93553 12.0884 5.94804 11.862 6.18996L7.80639 10.5221L6.138 8.73996C5.91156 8.49804 5.53185 8.48553 5.28996 8.712C5.04804 8.93844 5.03553 9.31815 5.262 9.56004L7.36836 11.81C7.48182 11.9312 7.6404 12 7.80639 12C7.97238 12 8.13096 11.9312 8.24439 11.81L12.738 7.01004Z"
      fill="currentColor"
    />
  </svg>
</template>
