import { useToast } from 'vue-toastification';
import CommonToast from '@/components/toast/CommonToast.vue';

export function useCustomToast(content) {
  const toast = useToast();
  const newContent = {
    component: CommonToast,
    props: {
      text: content.text,
      type: content.type,
      title: content.title,
    },
  };
  const Position = content.position ? content.position : 'bottom-right';
  const options = {
    position: Position,
    timeout: content.timeout ? content.timeout : 3000,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: false,
    icon: false,
    rtl: false,
  };
  if (content.type == 'error') {
    toast.error(newContent, options);
  } else if (content.type == 'success') {
    toast.success(newContent, options);
  } else if (content.type == 'warning') {
    toast.warning(newContent, options);
  } else if (content.type == 'info') {
    toast.info(newContent, options);
  }
}
