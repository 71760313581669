import { closeAllocatedAlgorithmService as service } from '../../../../services/algorithms/algorithmsService';

const field = 'closeAllocation';

export default async ({ commit }, { params }) => {
  commit('setIsLoading', { field });
  try {
    const { response } = await service({ params });

    if (!response.error) {
      commit('setIsReady', { field });
      commit('setData', {
        _state: field,
        _data: response,
      });
    } else {
      commit('setIsError', { field });
    }

    return response;
  } catch (error) {
    commit('setIsError', { field });
    throw error;
  }
};
