import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const walletListMock = () => ({
  success: true,
  data: [
    {
      id: randomNumber(0, 100000000000000000),
      name: 'TRC 20',
    },
    {
      id: randomNumber(0, 100000000000000000),
      name: 'BTC',
    },
    {
      id: randomNumber(0, 100000000000000000),
      name: 'ERC 20',
    },
  ],
});

export default walletListMock;
