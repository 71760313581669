import { closeModal, openModal } from './actions';
const state = {
  currentModal: {
    name: '',
  },
};
const actions = {
  closeModal,
  openModal,
};
const getters = {
  currentModal: (state) => state.currentModal,
};
const mutations = {
  setModals(state, modals) {
    state.currentModal = modals;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
