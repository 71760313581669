<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

//Components
import DepositModal from "@/components/commons/DepositModal.vue";
import StepsDropDown from "./StepsDropDown.vue";

//icons
import AlgorithmsIcon from "@/assets/icons/algorithms-icon.vue";
import ArrowRightIcon from "@/assets/icons/arrow-right-icon.vue";
import GreenTickIcon from "@/assets/icons/verified-icon.vue";
import SubscriptionIcon from "@/assets/icons/subscription-icon.vue";
import VerificationIcon from "@/assets/icons/verification2-icon.vue";
import VerifiedIcon from "@/assets/icons/verification-icon.vue";
import WalletIcon from "@/assets/icons/wallet-icon.vue";

// styles
import "@/assets/styles/step_by_step_component.scss";

const step1Completed = ref(false);
const step2Completed = ref(false);
const step3Completed = ref(false);

const store = useStore();
const router = useRouter();

const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
  }
};

const isStep2Allowed = computed(() => {
  return step1Completed.value == true;
});

const isStep3Allowed = computed(() => {
  return step2Completed.value == true;
});

const isStep4Allowed = computed(() => {
  return step3Completed.value == true;
});

const goToSubscription = () => {
  if(isStep3Allowed.value){
    window.open("/subscription", "_blank");
    step3Completed.value = true
  }
};

const goToAlgorithm = () => {
  if(isStep4Allowed.value){
    router.push({ name: 'algorithms' });
  }
}
</script>
<template>
  <div class="step_by_step_component">
    <div class="welcome_message">
      <div class="message_title">Great job! you are now registered.</div>
      <div class="message_description">
        Now, let's start by verifying and depositing funds into your account
      </div>
      <div class="message_description">
        Once done, you'll access our C&B platform to expand your portfolio and
        reap investment benefits. You can also monitor your profits directly
        through our portal.
      </div>
    </div>
    <div class="steps">
      <StepsDropDown
        :icon="VerificationIcon"
        title="Step 1: Verify your account"
        description="Complete the verification process to unlock full access to our platform's services and features"
        successMessage="Account is verified."
        :stepCompleted="step1Completed"
      >
        <template #before>
          <div class="card-container">
            <div class="title">Start your standard identity verification</div>
            <div class="description">
              <p>It only takes 3-5 minutes to verify your account.</p>
              <p>
                Complete to unlock deposit and trading permissions. After you
                submit, it can take up to 24 hours to be verified.
              </p>
            </div>
            <button class="action_btn active" @click="step1Completed = true">
              <span>Verify Now</span>
              <ArrowRightIcon />
            </button>
            <div class="footer-notification">
              <VerifiedIcon />
              <span>Your information with C&B is safe and secure.</span>
            </div>
          </div>
        </template>
        <template #after>
          <div class="card-container">
            <div class="title">
              <GreenTickIcon />Congratulations, your account is verified.
            </div>
            <div class="description">
              <p>
                You now have full access to all of C&B's product and features.
              </p>
              <p>
                Get ready to explore dynamic investment possibilities for your
                future.
              </p>
            </div>
            <div class="footer-notification">
              <VerifiedIcon />
              <span>Your information with C&B is safe and secure.</span>
            </div>
          </div>
        </template>
      </StepsDropDown>
      <StepsDropDown
        :icon="WalletIcon"
        title="Step 2: Deposit funds"
        description="Initiate the process of adding funds to your account to start investing and exploring opportunities"
        successMessage="Funds have been deposited successfully"
        :stepCompleted="step2Completed"
      >
        <template #before>
          <div class="card-container">
            <div class="title">Start adding funds</div>
            <div class="description">
              <p>
                You can deposit funds into your account by scanning the QR code,
                or entering the wallet address manually.
              </p>
              <p>Please click below to view the QR code or wallet address.</p>
            </div>
            <button
              class="action_btn"
              :class="{ active: isStep2Allowed }"
              @click="
                isStep2Allowed ? modalHandler(true, 'step_deposit') : () => {}
              "
            >
              <span>Deposit</span>
            </button>
          </div>
        </template>
        <template #after>
          <div class="card-container">
            <div class="title">Add additional funds</div>
            <div class="description">
              <p>
                You can deposit funds into your account by scanning the QR code,
                or entering the wallet address manually.
              </p>
              <p>
                Please click below to view the QR code or wallet address, if you
                wish to add more funds.
              </p>
            </div>
            <button
              class="action_btn"
              :class="{ active: isStep2Allowed }"
              @click="
                isStep2Allowed ? modalHandler(true, 'step_deposit') : () => {}
              "
            >
              <span>Deposit</span>
            </button>
            <div class="footer-notification">
              <span>Available balance: $2500.00</span>
            </div>
          </div>
        </template>
      </StepsDropDown>
      <StepsDropDown
        :icon="SubscriptionIcon"
        title="Step 3: Calculate and activate your subscription"
        description="Based on your investment details, we will customize a subscription plan that perfectly suits your needs."
        successMessage="Subscription has been added successfully."
        :stepCompleted="step3Completed"
      >
        <template #before>
          <div class="card-container">
            <div class="title">Subscribe and become a member</div>
            <div class="description">
              <p>
                The subscription will allow you to access the investment
                algorithms.
              </p>
              <p>
                To calculate your subscription amount and view its associated
                benefits, please click below.
              </p>
            </div>
            <button
              class="action_btn"
              :class="{ active: isStep3Allowed }"
              @click="goToSubscription"
            >
              <span>Check subscription</span>
              <ArrowRightIcon />
            </button>
          </div>
        </template>
        <template #after>
          <div class="card-container">
            <div class="title">Congratulations, you are now a member.</div>
            <div class="description">
              <p>
                The subscription will allow you to access the investment
                algorithms. You can now check it's associated benefits.
              </p>
            </div>
          </div>
        </template>
      </StepsDropDown>
      <StepsDropDown
        :icon="AlgorithmsIcon"
        title="Step 4: Invest in algorithms"
        description="Select and invest in algorithms that align with your investment strategy to optimize your portfolio growth"
      >
        <template #before>
          <div class="card-container">
            <div class="title">Explore algorithms</div>
            <div class="description">
              <p>
                C&B offers a variety of investment algorithms tailored to your
                specific investment strategy.
              </p>
              <p>Choose the ones that best align with your financial goals.</p>
            </div>
            <button class="action_btn" :class="{ active: isStep4Allowed }" @click="goToAlgorithm" >
              <span>Go to algorithms</span>
              <ArrowRightIcon />
            </button>
          </div>
        </template>
      </StepsDropDown>
    </div>
    <DepositModal name="step_deposit" @onClose="step2Completed = true" />
  </div>
</template>
