import generateLoadingMutations from '@commons/helpers/store/generateLoadingMutations';
import setData from '@commons/helpers/store/setData';
import setState from '@commons/helpers/store/setState';

import getAlgorithmList from './actions/getAlgorithmList';
import getAlgorithmTransactionList from './actions/getAlgorithmTransactionList';

import getInvestmentDetails from './actions/getInvestmentDetails';
import getAvailableBalance from './actions/getAvailableBalance';
import algorithmAllocation from './actions/algorithmAllocation';
import algorithmReAllocation from './actions/algorithmReAllocation';
import closeAllocatedAlgorithm from './actions/closeAllocatedAlgorithm';

import adapterGetAlgorithmList from '@/services/algorithms/adapters/getAlgorithmList';
import adapterGetAlgorithmTransactionList from '@/services/algorithms/adapters/getAlgorithmTransactionList';
import adapterGetInvestmentDetails from '@/services/algorithms/adapters/getInvestmentDetails';

import adapterGetAvailableBalance from '@/services/algorithms/adapters/getAvailableBalance';
import adapterAlgorithmAllocation from '@/services/algorithms/adapters/algorithmAllocation';
import adapterReAlgorithmAllocation from '@/services/algorithms/adapters/algorithmReAllocation';

const state = {
  algorithmList: setState(adapterGetAlgorithmList),
  algorithm_id: null,
  algorithmTransactionList: setState(adapterGetAlgorithmTransactionList),
  investmentDetails: setState(adapterGetInvestmentDetails),
  availableBalance: setState(adapterGetAvailableBalance),
  algoAllocation: setState(adapterAlgorithmAllocation),
  algoReAllocation: setState(adapterReAlgorithmAllocation),
  closeAllocation: setState(adapterAlgorithmAllocation),
};

const actions = {
  getAlgorithmList,
  getAlgorithmTransactionList,
  getInvestmentDetails,
  getAvailableBalance,
  algorithmAllocation,
  algorithmReAllocation,
  closeAllocatedAlgorithm,
};

const getters = {
  algorithmDetails: (state) => state.algorithmDetails._data?.data,
  algorithm_id: (state) => state.algorithm_id,
  investmentDetails: (state) => state.investmentDetails._data?.data,
  availableBalance: (state) => state.availableBalance._data?.data,
};

const mutations = {
  ...generateLoadingMutations(),
  setData,
  setAlgorithmId(state, id = null) {
    state.algorithm_id = id;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
