import { getOverviewChartService as service } from '../../../../services/dashboard/dashboardService';

const field = 'overview_chart';

export default async (
  { commit },
  { dateRange = null, showDailyNav = null, showExposure = null, showMaxMinNav = null },
) => {
  commit('setIsLoading', { field });

  try {
    const params = {};

    if (dateRange) {
      params.date_range = dateRange;
    }
    if (showDailyNav !== null) {
      params.show_daily_nav = showDailyNav;
    }
    if (showExposure !== null) {
      params.show_exposure = showExposure;
    }
    if (showMaxMinNav !== null) {
      params.show_max_min_nav = showMaxMinNav;
    }

    const { response } = await service({ params });

    if (!response.error) {
      commit('setIsReady', { field });
      commit('setData', {
        _state: field,
        _data: response,
      });
    } else {
      commit('setIsError', { field });
    }

    return response;
  } catch (error) {
    commit('setIsError', { field });
    throw error;
  }
};
