import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const invalidCredentialsReset = () => ({
  success: false,
  message: 'Error validation',
  errors: {
    password: ['The team name must be a string.'],
  },
});

const successfullReset = () => ({
  success: true,
  messages: 'Password updated successfully.',
});

const resetScenarios = [successfullReset, invalidCredentialsReset];

export const mockReset = () => {
  const rand = randomNumber(0, resetScenarios.length);

  if (typeof resetScenarios[rand] !== 'undefined') {
    return resetScenarios[rand]();
  }

  return successfullReset();
};
