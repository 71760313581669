<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M8.9831 5.76339L6.3056 3.08589C6.1963 2.97724 6.04845 2.91626 5.89435 2.91626C5.74024 2.91626 5.59239 2.97724 5.4831 3.08589C5.42842 3.14012 5.38502 3.20463 5.35541 3.27572C5.32579 3.3468 5.31055 3.42305 5.31055 3.50006C5.31055 3.57706 5.32579 3.65331 5.35541 3.72439C5.38502 3.79548 5.42842 3.85999 5.4831 3.91422L8.16643 6.58589C8.2211 6.64012 8.2645 6.70463 8.29412 6.77572C8.32373 6.8468 8.33898 6.92305 8.33898 7.00006C8.33898 7.07706 8.32373 7.15331 8.29412 7.22439C8.2645 7.29548 8.2211 7.35999 8.16643 7.41422L5.4831 10.0859C5.37325 10.195 5.31124 10.3432 5.31069 10.498C5.31014 10.6528 5.37111 10.8015 5.48018 10.9113C5.58925 11.0212 5.73749 11.0832 5.89228 11.0837C6.04708 11.0843 6.19575 11.0233 6.3056 10.9142L8.9831 8.23672C9.31081 7.9086 9.49489 7.46381 9.49489 7.00006C9.49489 6.5363 9.31081 6.09152 8.9831 5.76339Z"
      fill="currentColor"
    />
  </svg>
</template>
