export const closeModal = ({ commit }) => {
  commit('setModals', {
    name: '',
  });
};
export const openModal = ({ commit }, currentModal) => {
  commit('setModals', {
    name: currentModal,
  });
};
