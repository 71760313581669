<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M6.11325 11.0834C5.96022 11.0827 5.81357 11.022 5.70492 10.9142L3.02742 8.23672C2.6997 7.9086 2.51563 7.46381 2.51562 7.00006C2.51562 6.5363 2.6997 6.09152 3.02742 5.76339L5.70492 3.08589C5.81421 2.97724 5.96206 2.91626 6.11617 2.91626C6.27028 2.91626 6.41812 2.97724 6.52742 3.08589C6.58209 3.14012 6.62549 3.20463 6.65511 3.27572C6.68472 3.3468 6.69997 3.42305 6.69997 3.50006C6.69997 3.57706 6.68472 3.65331 6.65511 3.72439C6.62549 3.79548 6.58209 3.85999 6.52742 3.91422L3.84992 6.58589C3.79524 6.64012 3.75185 6.70463 3.72223 6.77572C3.69262 6.8468 3.67737 6.92305 3.67737 7.00006C3.67737 7.07706 3.69262 7.15331 3.72223 7.22439C3.75185 7.29548 3.79524 7.35999 3.84992 7.41422L6.52742 10.0859C6.58209 10.1401 6.62549 10.2046 6.65511 10.2757C6.68472 10.3468 6.69997 10.423 6.69997 10.5001C6.69997 10.5771 6.68472 10.6533 6.65511 10.7244C6.62549 10.7955 6.58209 10.86 6.52742 10.9142C6.47291 10.9683 6.40827 11.0111 6.3372 11.0401C6.26613 11.0691 6.19002 11.0838 6.11325 11.0834Z"
      fill="currentColor"
    />
    <path
      d="M10.1966 11.0834C10.0436 11.0827 9.89692 11.022 9.78827 10.9142L6.28827 7.41422C6.2336 7.35999 6.1902 7.29548 6.16059 7.22439C6.13097 7.15331 6.11572 7.07706 6.11572 7.00006C6.11572 6.92305 6.13097 6.8468 6.16059 6.77572C6.1902 6.70463 6.2336 6.64012 6.28827 6.58589L9.78827 3.08589C9.89756 2.97724 10.0454 2.91626 10.1995 2.91626C10.3536 2.91626 10.5015 2.97724 10.6108 3.08589C10.6654 3.14012 10.7088 3.20463 10.7385 3.27572C10.7681 3.3468 10.7833 3.42305 10.7833 3.50006C10.7833 3.57706 10.7681 3.65331 10.7385 3.72439C10.7088 3.79548 10.6654 3.85999 10.6108 3.91422L7.52494 7.00006L10.6108 10.0859C10.6654 10.1401 10.7088 10.2046 10.7385 10.2757C10.7681 10.3468 10.7833 10.423 10.7833 10.5001C10.7833 10.5771 10.7681 10.6533 10.7385 10.7244C10.7088 10.7955 10.6654 10.86 10.6108 10.9142C10.5563 10.9683 10.4916 11.0111 10.4205 11.0401C10.3495 11.0691 10.2734 11.0838 10.1966 11.0834Z"
      fill="currentColor"
    />
  </svg>
</template>
