<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_648_27260)">
      <path
        d="M17.34 7.43255L14.4375 4.50005C14.3678 4.42975 14.2848 4.37395 14.1934 4.33588C14.102 4.2978 14.004 4.2782 13.905 4.2782C13.806 4.2782 13.708 4.2978 13.6166 4.33588C13.5252 4.37395 13.4422 4.42975 13.3725 4.50005C13.2328 4.64057 13.1544 4.83066 13.1544 5.0288C13.1544 5.22694 13.2328 5.41702 13.3725 5.55755L16.0425 8.25005H0.75C0.551088 8.25005 0.360322 8.32906 0.21967 8.46972C0.0790176 8.61037 0 8.80113 0 9.00005H0C0 9.19896 0.0790176 9.38972 0.21967 9.53038C0.360322 9.67103 0.551088 9.75005 0.75 9.75005H16.0875L13.3725 12.4575C13.3022 12.5273 13.2464 12.6102 13.2083 12.7016C13.1703 12.793 13.1507 12.891 13.1507 12.99C13.1507 13.0891 13.1703 13.1871 13.2083 13.2785C13.2464 13.3699 13.3022 13.4528 13.3725 13.5225C13.4422 13.5928 13.5252 13.6486 13.6166 13.6867C13.708 13.7248 13.806 13.7444 13.905 13.7444C14.004 13.7444 14.102 13.7248 14.1934 13.6867C14.2848 13.6486 14.3678 13.5928 14.4375 13.5225L17.34 10.6125C17.7614 10.1907 17.998 9.6188 17.998 9.02255C17.998 8.42629 17.7614 7.85442 17.34 7.43255Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_648_27260">
        <rect width="18" height="18" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
