import iso from 'iso-3166-1';
export const authorizationStatus = ['Authorize', 'Pending', 'Rejected'];

// export const countries = [
//   'Afghanistan',
//   'Albania',
//   'Algeria',
//   'American Samoa',
//   'Andorra',
//   'Angola',
//   'Anguilla',
//   'Antarctica',
//   'Antigua and Barbuda',
//   'Argentina',
//   'Armenia',
//   'Aruba',
//   'Australia',
//   'Austria',
//   'Azerbaijan',
//   'Bahamas',
//   'Bahrain',
//   'Bangladesh',
//   'Barbados',
//   'Belarus',
//   'Belgium',
//   'Belize',
//   'Benin',
//   'Bermuda',
//   'Bhutan',
//   'Bolivia',
//   'Bonaire, Sint Eustatius and Saba',
//   'Bosnia and Herzegovina',
//   'Botswana',
//   'Bouvet Island',
//   'Brazil',
//   'British Indian Ocean Territory',
//   'Brunei Darussalam',
//   'Bulgaria',
//   'Burkina Faso',
//   'Burundi',
//   'Cabo Verde',
//   'Cambodia',
//   'Cameroon',
//   'Canada',
//   'Cayman Islands',
//   'Central African Republic',
//   'Chad',
//   'Chile',
//   'China',
//   'Christmas Island',
//   'Cocos Islands',
//   'Colombia',
//   'Comoros',
//   'Congo',
//   'Cook Islands',
//   'Costa Rica',
//   'Croatia',
//   'Cuba',
//   'Curaçao',
//   'Cyprus',
//   'Czechia',
//   "Côte d'Ivoire",
//   'Denmark',
//   'Djibouti',
//   'Dominica',
//   'Dominican Republic',
//   'Ecuador',
//   'Egypt',
//   'El Salvador',
//   'Equatorial Guinea',
//   'Eritrea',
//   'Estonia',
//   'Eswatini',
//   'Ethiopia',
//   'Falkland Islands',
//   'Faroe Islands',
//   'Fiji',
//   'Finland',
//   'France',
//   'French Guiana',
//   'French Polynesia',
//   'French Southern Territories',
//   'Gabon',
//   'Gambia',
//   'Georgia',
//   'Germany',
//   'Ghana',
//   'Gibraltar',
//   'Greece',
//   'Greenland',
//   'Grenada',
//   'Guadeloupe',
//   'Guam',
//   'Guatemala',
//   'Guernsey',
//   'Guinea',
//   'Guinea-Bissau',
//   'Guyana',
//   'Haiti',
//   'Heard Island and McDonald Islands',
//   'Holy See',
//   'Honduras',
//   'Hong Kong',
//   'Hungary',
//   'Iceland',
//   'India',
//   'Indonesia',
//   'Iran',
//   'Iraq',
//   'Ireland',
//   'Isle of Man',
//   'Israel',
//   'Italy',
//   'Jamaica',
//   'Japan',
//   'Jersey',
//   'Jordan',
//   'Kazakhstan',
//   'Kenya',
//   'Kiribati',
//   'Korea',
//   'Korea',
//   'Kuwait',
//   'Kyrgyzstan',
//   "Lao People's Democratic Republic",
//   'Latvia',
//   'Lebanon',
//   'Lesotho',
//   'Liberia',
//   'Libya',
//   'Liechtenstein',
//   'Lithuania',
//   'Luxembourg',
//   'Macao',
//   'Madagascar',
//   'Malawi',
//   'Malaysia',
//   'Maldives',
//   'Mali',
//   'Malta',
//   'Marshall Islands',
//   'Martinique',
//   'Mauritania',
//   'Mauritius',
//   'Mayotte',
//   'Mexico',
//   'Micronesia',
//   'Moldova',
//   'Monaco',
//   'Mongolia',
//   'Montenegro',
//   'Montserrat',
//   'Morocco',
//   'Mozambique',
//   'Myanmar',
//   'Namibia',
//   'Nauru',
//   'Nepal',
//   'Netherland',
//   'New Caledonia',
//   'New Zealand',
//   'Nicaragua',
//   'Niger',
//   'Nigeria',
//   'Niue',
//   'Norfolk Island',
//   'Northern Mariana Islands',
//   'Norway',
//   'Oman',
//   'Pakistan',
//   'Palau',
//   'Palestine, State of',
//   'Panama',
//   'Papua New Guinea',
//   'Paraguay',
//   'Peru',
//   'Philippines',
//   'Pitcairn',
//   'Poland',
//   'Portugal',
//   'Puerto Rico',
//   'Qatar',
//   'Republic of North Macedonia',
//   'Romania',
//   'Russian Federation',
//   'Rwanda',
//   'Réunion',
//   'Saint Barthélemy',
//   'Saint Helena, Ascension and Tristan da Cunha',
//   'Saint Kitts and Nevis',
//   'Saint Lucia',
//   'Saint Martin',
//   'Saint Pierre and Miquelon',
//   'Saint Vincent and the Grenadines',
//   'Samoa',
//   'San Marino',
//   'Sao Tome and Principe',
//   'Saudi Arabia',
//   'Senegal',
//   'Serbia',
//   'Seychelles',
//   'Sierra Leone',
//   'Singapore',
//   'Sint Maarten',
//   'Slovakia',
//   'Slovenia',
//   'Solomon Islands',
//   'Somalia',
//   'South Africa',
//   'South Georgia and the South Sandwich Islands',
//   'South Sudan',
//   'Spain',
//   'Sri Lanka',
//   'Sudan',
//   'Suriname',
//   'Svalbard and Jan Mayen',
//   'Sweden',
//   'Switzerland',
//   'Syrian Arab Republic',
//   'Taiwan',
//   'Tajikistan',
//   'Tanzania, United Republic of',
//   'Thailand',
//   'Timor-Leste',
//   'Togo',
//   'Tokelau',
//   'Tonga',
//   'Trinidad and Tobago',
//   'Tunisia',
//   'Turkey',
//   'Turkmenistan',
//   'Turks and Caicos Islands',
//   'Tuvalu',
//   'Uganda',
//   'Ukraine',
//   'United Arab Emirates',
//   'United Kingdom of Great Britain and Northern Ireland',
//   'United States Minor Outlying Islands',
//   'United States of America',
//   'Uruguay',
//   'Uzbekistan',
//   'Vanuatu',
//   'Venezuela',
//   'Viet Nam',
//   'Virgin Islands',
//   'Virgin Islands',
//   'Wallis and Futuna',
//   'Western Sahara',
//   'Yemen',
//   'Zambia',
//   'Zimbabwe',
//   'Åland Islands',
// ];

export const countries = iso.all();
export const httpMethods = {
  delete: 'DELETE',
  get: 'GET',
  post: 'POST',
  put: 'PUT',
};

export const optionsItemsPerPage = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '40',
    value: 40,
  },
  {
    label: '50',
    value: 50,
  },
];

export const riskLevel = ['low', 'medium', 'high'];

export const optionInUse = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 0,
  },
  {
    label: 'All',
    value: undefined,
  },
];

export const sortByAge = ['oldest', 'newest'];

export const algorithmsName = [
  'Palm Jumeirah',
  'Holy Grail',
  'BWFS',
  'Millennium',
  'Marasidrive',
  'Eche',
  'Twilight',
];

export const transactionsType = ['Transfer in', 'Transfer out', 'Withdraw', 'Deposit'];

export const transactionsStatus = ['Pending', 'Rejected', 'Success'];
export const verificationStatus = ['Verified', 'Unverified'];

export const languages = [
  'English',
  'French',
  'German',
  'Italian',
  'Japanese',
  'Korean',
  'Spanish',
];

export const phoneCountryCode = [
  '+971',
  '+1',
  '+44',
  '+81',
  '+91',
  '+92',
  '+86',
  '+55',
  '+49',
  '+39',
  '+81',
  '+82',
  '+34',
  '+33',
  '+61',
  '+64',
];
