<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M10.625 6.875H8.125V4.375C8.125 4.20924 8.05915 4.05027 7.94194 3.93306C7.82473 3.81585 7.66576 3.75 7.5 3.75C7.33424 3.75 7.17527 3.81585 7.05806 3.93306C6.94085 4.05027 6.875 4.20924 6.875 4.375V6.875H4.375C4.20924 6.875 4.05027 6.94085 3.93306 7.05806C3.81585 7.17527 3.75 7.33424 3.75 7.5C3.75 7.66576 3.81585 7.82473 3.93306 7.94194C4.05027 8.05915 4.20924 8.125 4.375 8.125H6.875V10.625C6.875 10.7908 6.94085 10.9497 7.05806 11.0669C7.17527 11.1842 7.33424 11.25 7.5 11.25C7.66576 11.25 7.82473 11.1842 7.94194 11.0669C8.05915 10.9497 8.125 10.7908 8.125 10.625V8.125H10.625C10.7908 8.125 10.9497 8.05915 11.0669 7.94194C11.1842 7.82473 11.25 7.66576 11.25 7.5C11.25 7.33424 11.1842 7.17527 11.0669 7.05806C10.9497 6.94085 10.7908 6.875 10.625 6.875Z"
      fill="currentColor"
    />
  </svg>
</template>
