<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clip-path="url(#clip0_521_45216)">
      <path
        d="M10.8392 1.24872L7.18466 0.0301346C7.065 -0.00971935 6.93565 -0.00971935 6.81599 0.0301346L3.16141 1.24872C2.58035 1.44173 2.0749 1.81295 1.71687 2.30963C1.35884 2.80631 1.16643 3.40319 1.16699 4.01547V7.00039C1.16699 11.4121 6.53366 13.8487 6.76349 13.9502C6.83806 13.9833 6.91874 14.0005 7.00033 14.0005C7.08192 14.0005 7.1626 13.9833 7.23716 13.9502C7.46699 13.8487 12.8337 11.4121 12.8337 7.00039V4.01547C12.8342 3.40319 12.6418 2.80631 12.2838 2.30963C11.9258 1.81295 11.4203 1.44173 10.8392 1.24872ZM11.667 7.00039C11.667 10.1825 7.98091 12.2696 7.00033 12.769C6.01858 12.2714 2.33366 10.1906 2.33366 7.00039V4.01547C2.33369 3.64813 2.44932 3.29012 2.66416 2.99215C2.87899 2.69419 3.18214 2.47138 3.53066 2.3553L7.00033 1.19855L10.47 2.3553C10.8185 2.47138 11.1217 2.69419 11.3365 2.99215C11.5513 3.29012 11.667 3.64813 11.667 4.01547V7.00039Z"
        fill="currentColor"
      />
      <path
        d="M8.92496 4.84196L6.48196 7.29196L5.17296 5.92696C5.12043 5.86952 5.05693 5.82318 4.98621 5.79066C4.91549 5.75814 4.83898 5.7401 4.76118 5.73761C4.68339 5.73512 4.60588 5.74823 4.53322 5.77615C4.46057 5.80408 4.39424 5.84627 4.33814 5.90022C4.28204 5.95418 4.2373 6.01882 4.20657 6.09034C4.17584 6.16185 4.15973 6.23879 4.15919 6.31663C4.15866 6.39446 4.1737 6.47162 4.20345 6.54355C4.23319 6.61548 4.27703 6.68073 4.33238 6.73546L5.67754 8.13546C5.77786 8.24382 5.89912 8.3307 6.03397 8.39086C6.16883 8.45102 6.31448 8.4832 6.46213 8.48546H6.48138C6.62608 8.48594 6.76943 8.45767 6.90311 8.40229C7.0368 8.34692 7.15815 8.26554 7.26013 8.16288L9.75213 5.67087C9.80656 5.61656 9.84975 5.55206 9.87925 5.48105C9.90875 5.41005 9.92398 5.33392 9.92406 5.25703C9.92414 5.18014 9.90908 5.10399 9.87973 5.03292C9.85038 4.96185 9.80731 4.89726 9.753 4.84283C9.69869 4.7884 9.63419 4.74521 9.56318 4.71571C9.49218 4.68621 9.41605 4.67098 9.33916 4.6709C9.26227 4.67082 9.18612 4.68588 9.11505 4.71523C9.04398 4.74458 8.97939 4.78764 8.92496 4.84196Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_521_45216">
        <rect width="14" height="14" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
