// helpers
import generateLoadingMutations from '@commons/helpers/store/generateLoadingMutations';
import setData from '@commons/helpers/store/setData';
import setState from '@commons/helpers/store/setState';
// actions
import setupAntiPhishingCode from './actions/setupAntiPhishingCode';
import changePhoneNumberAction from './actions/changePhoneNumber.action';
import changeEmailAddress from './actions/changeEmailAddress';
import forgotPassword from './actions/forgotPassword.action';
import forgot2fa from './actions/forgot2fa.action';
import initializeStore from './actions/initializeStore.action';
import login from './actions/login.action';
import logout from './actions/logout.action';
import register from './actions/register.action';
import resetPasswordAction from './actions/resetPassword.action';
import setupPhoneNumberAction from './actions/setupPhoneNumber.action';
import userProfile from './actions/profileOverview.action';
import updateUserProfile from './actions/updateUser.action';
import recoverTwoFactorAuthAction from './actions/recoverTwoFactorAuth.action';

// adapter
import adapterLogin from '@/services/auth/adapters/login.adapter';
import adapterLogout from '@/services/auth/adapters/logout';
import adapterForgot from '@/services/auth/adapters/forgot.adapter';
import adapterReset from '@/services/auth/adapters/reset.adapter';
import adapterRegister from '@/services/register/adapters/register.adapter';
import adapterUserOverview from '@/services/profile/adapter/getUserOverview';
import adapterChangeEmailAddress from '@/services/profile/adapter/changeEmailAddress';
import adapterRecoverTwoFactorAuth from '@/services/profile/adapter/recoverTwoFactorAuth';
import setupAntiPhishingCodeAdapter from '@/services/profile/adapter/setupAntiPhishingCode';
import {
  changePhoneNumberAdapter,
  setupPhoneNumberAdapter,
} from '@/services/profile/adapter/setupPhoneNumber';

const state = {
  setupAntiPhishingCode: setState(setupAntiPhishingCodeAdapter),
  changePhoneNumber: setState(changePhoneNumberAdapter),
  changeEmailAddress: setState(adapterChangeEmailAddress),
  forgotPassword: setState(adapterForgot),
  login: setState(adapterLogin),
  logout: setState(adapterLogout),
  resetPassword: setState(adapterReset),
  recoverTwoFactorAuth: setState(adapterRecoverTwoFactorAuth),
  register: setState(adapterRegister),
  setupPhoneNumber: setState(setupPhoneNumberAdapter),
  token: null,
  userData: setState(adapterUserOverview),
  updateUser: setState(adapterUserOverview),
};

const actions = {
  setupAntiPhishingCode,
  changePhoneNumberAction,
  changeEmailAddress,
  forgotPassword,
  forgot2fa,
  initializeStore,
  logout,
  login,
  register,
  resetPasswordAction,
  recoverTwoFactorAuthAction,
  setupPhoneNumberAction,
  updateUserProfile,
  userProfile,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  userData: (state) => state.userData,
  updateUser: (state) => state.updateUser,
  getToken: (state) => state.token,
};

const mutations = {
  ...generateLoadingMutations(),
  setData,
  setToken(state, token = null) {
    state.token = token;
  },
  // it will be remove after integeration
  setUpdateUser(state, newData) {
    state.userData._data.data = {
      ...state.userData._data.data,
      ...newData,
    };
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
