<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M18.9977 21V15C18.9977 14.45 19.4479 14 19.9982 14C20.5485 14 20.9988 14.45 20.9988 15V21C20.9988 21.55 20.5485 22 19.9982 22C19.4479 22 18.9977 21.55 18.9977 21ZM19.9982 23C19.1678 23 18.4974 23.67 18.4974 24.5C18.4974 25.33 19.1678 26 19.9982 26C20.8287 26 21.4991 25.33 21.4991 24.5C21.4991 23.67 20.8287 23 19.9982 23ZM31.5846 27.88C30.8843 29.23 29.4134 30 27.5724 30H12.4341C10.583 30 9.12222 29.23 8.42184 27.88C7.71144 26.52 7.91155 24.78 8.92211 23.32L16.9666 10.6C17.677 9.58 18.7976 9 19.9982 9C21.1989 9 22.3195 9.58 22.9999 10.57L31.0844 23.34C32.0949 24.8 32.285 26.53 31.5746 27.88H31.5846ZM29.4335 24.46C29.4335 24.46 29.4134 24.44 29.4134 24.42L21.339 11.67C21.0488 11.26 20.5485 11 19.9982 11C19.4479 11 18.9477 11.26 18.6375 11.71L10.583 24.42C9.96269 25.3 9.82261 26.26 10.1828 26.95C10.533 27.63 11.3334 28 12.424 28H27.5524C28.643 28 29.4435 27.63 29.7937 26.95C30.1539 26.26 30.0138 25.3 29.4235 24.46H29.4335Z"
      fill="currentColor"
    />
  </svg>
</template>
