import generateLoadingMutations from '@commons/helpers/store/generateLoadingMutations';
import setData from '@commons/helpers/store/setData';
import setState from '@commons/helpers/store/setState';

import calculateSubscription from './actions/calculateSubscription';
import confirmSubscription from './actions/confirmSubscription';

import adapterCalculateSubscription from '@/services/subscription/adapters/calculateSubscription';
import adapterConfirmSubscription from '@/services/subscription/adapters/confirmSubscription';

const state = {
  calculate_subscription: setState(adapterCalculateSubscription),
  confirm_subscription: setState(adapterConfirmSubscription),
};

const actions = {
  calculateSubscription,
  confirmSubscription,
};

const mutations = {
  ...generateLoadingMutations(),
  setData,
};

const getters = {
  calculate_subscription: (state) => state.calculate_subscription,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
