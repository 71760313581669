const webserverUrl = process.env.VUE_APP_BASE_URL_SERVER;
const webserverPort = process.env.VUE_APP_BASE_URL_PORT;

const baseUrl = `${webserverUrl}:${webserverPort}/api/v1`;

export const algorithmsUrls = {
  getAlgorithmList: () => `${baseUrl}/algorithms`,
  getAlgorithmTransactionList: (id) => `${baseUrl}/transactions/${id}`, //borrar
  getInvestmentDetails: (id) => `${baseUrl}/algorithms-investment-details/${id}`, //borrar
  algorithmReAllocation: () => `${baseUrl}/reallocation`,
  getAvailableBalance: (id) => `${baseUrl}/available-balance/${id}`,
  algorithmAllocation: () => `${baseUrl}/allocate`,
  closeAllocatedAlgorithm: (id) => `${baseUrl}/close-allocation/${id}`,
};

export const authUrls = {
  login: () => `${baseUrl}/access/login`,
  logout: () => `${baseUrl}/access/logout`,
  forgotPassword: () => `${baseUrl}/access/password/email`,
  resetPassword: (token) => `${baseUrl}/access/password/reset/?token=${token}`,
  forgot2fa: () => `${baseUrl}/forgot-2fa`,
};

export const dashboardUrls = {
  downloadReport: () => `${baseUrl}/download-report`,
  getAlgorithmList: () => `${baseUrl}/dashboard-algorithms`,
  getAlgorithmPerformance: () => `${baseUrl}/dashboard/algorithm-performance`,
  getOverviewChart: () => `${baseUrl}/dashboard/overview`,
  getNetAssetValue: () => `${baseUrl}/dashboard/nav`,
  getPerformance: () => `${baseUrl}/dashboard/performance`,
  getRiskLevel: () => `${baseUrl}/dashboard/risk`,
  getTransactionList: () => `${baseUrl}/transactions`,
  getTransactionListCount: () => `${baseUrl}/transactions`,
};

export const registerUrls = {
  register: () => `${baseUrl}/access/registration`,
};

export const subscriptionUrls = {
  calculateSubscription: () => `${baseUrl}/calculate-subscription`,
  confirmSubscription: () => `${baseUrl}/subscription`,
};

export const userProfileUrls = {
  getUserOverview: () => `${baseUrl}/user-profile/overview`,
  updateUserProfile: () => `${baseUrl}/user-profile`,
  changePassword: () => `${baseUrl}/user-profile/change-password`,
  setUpPhoneNumber: () => `${baseUrl}/user/phone-number`,
  changeEmailAddress: () => `${baseUrl}/user/change-email`,
  recoverTwoFactorAuth: () => `${baseUrl}/user/recover-2fa`,
  setupAntiPhishingCode: () => `${baseUrl}/user/anti-phishing-code`,
};

export const walletUrls = {
  getPortfolio: () => `${baseUrl}/portfolio`,
  getTotalBalance: () => `${baseUrl}/wallet/total-balance`,
  getWalletList: () => `${baseUrl}/wallet`,
  addWalletAddress: () => `${baseUrl}/wallet`,
  editWalletAddress: (id) => `${baseUrl}/wallet/${id}`,
  getWalletSelectorList: () => `${baseUrl}/WalletSelectorList`,
  withdrawWallet: () => `${baseUrl}/withdraw`,
  deleteWallet: () => `${baseUrl}/wallets`,
  deleteWalletById: (id) => `${baseUrl}/wallet/${id}`,
  markAsDefault: (id) => `${baseUrl}/wallets/${id}/default`,
};
