import { editWalletAddressService as service } from '../../../../services/wallet/walletService';

const field = 'editWalletAddress';

export default async ({ commit, dispatch }, { body }) => {
  commit('setIsLoading', { field });
  try {
    const { response } = await service({ body });

    if (!response.error) {
      commit('setIsReady', { field });
      commit('setData', {
        _state: field,
        _data: response,
      });
      dispatch('getWalletList');
    } else {
      commit('setIsError', { field });
    }

    return response;
  } catch (error) {
    commit('setIsError', { field });
    throw error;
  }
};
