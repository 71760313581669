<script setup>
import { computed, ref } from "vue";
//icons
import ChevronRightIcon from "@/assets/icons/chevron-right.vue";

//styles
import "@/assets/styles/steps_dropdown.scss";

defineProps({
  title: {
    type: String,
  },
  description: {
    type: String,
  },
  icon: {
    type: Object,
  },
  successMessage: {
    default: () => "",
    type: String,
  },
  stepCompleted: {
    default: () => false,
    type: Boolean,
  },
});

const isCollapsed = ref(false);
const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value;
};
const imageStyle = computed(() => {
  return {
    transform: isCollapsed.value ? "rotate(90deg)" : "rotate(0deg)",
  };
});
</script>
<template>
  <div>
    <div class="steps-dropdown">
      <div class="top-row" @click="toggleCollapse">
        <div class="left-group">
          <div
            class="icon"
            :class="stepCompleted ? 'active' : 'inactive'"
          >
            <icon />
          </div>
          <div class="details">
            <div class="title">{{ title }}</div>
            <div class="desc" :class="{ verified: stepCompleted }">
              {{ stepCompleted ? successMessage : description }}
            </div>
          </div>
        </div>
        <div class="button">
          <ChevronRightIcon :style="imageStyle" />
        </div>
      </div>
      <Transition name="fade" mode="out-in">
        <div class="content" v-if="isCollapsed">
          <slot name="after" v-if="stepCompleted"></slot>
          <slot name="before" v-else></slot>
        </div>
      </Transition>
    </div>
  </div>
</template>
