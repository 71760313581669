<script setup>
import { computed, onMounted } from "vue";
import { Tooltip } from "bootstrap";
import { useStore } from "vuex";

//icons
import InfoIcon from "@/assets/icons/info-icon.vue";

//styles
import "@/assets/styles/risk_card.scss";

const store = useStore();
const setupTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip-nav"]'
  );
  tooltipTriggerList.forEach((el) => {
    const title = el.getAttribute("data-bs-title");
    if (title) {
      new Tooltip(el);
    }
  });
};

const risk = computed(() => store.getters["dashboard/risk_level"]._data?.data);

const riskLoading = computed(
  () => store.getters["dashboard/risk_level"]._status._on_loading
);

onMounted(() => {
  setupTooltips();
});
</script>

<template>
  <div class="component_risk_card">
    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          Risk Level
          <InfoIcon
            role="button"
            data-bs-toggle="tooltip-nav"
            data-bs-placement="top"
            data-bs-title="Indicates the overall risk level associated with all investments in the account."
          />
        </div>
      </div>
      <div class="card-body">
        <div class="performance-card-container">
          <span
            :class="[
              `${
                risk.risk_level == 'medium'
                  ? 'medium'
                  : risk.risk_level == 'high'
                  ? 'high'
                  : 'low'
              }`,
              { skeleton: riskLoading },
            ]"
            >{{ risk.risk_level }}</span
          >
          <p :class="{ skeleton: riskLoading }">
            The {{ risk.highest_risk_algorithm }} algorithm shows the highest
            relative risk, with an average portfolio risk of
            <span>{{ risk.risk_percentage }}%</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
