export default [
  {
    title: 'Type',
    class: 'text-left',
    field: 'type',
    sortable: true,
    sortItem: {
      field: 'type',
      order: 'desc',
    },
  },
  {
    title: 'Request Date',
    field: 'request_date',
    sortable: true,
    sortItem: {
      field: 'request_date',
      order: 'desc',
    },
  },

  {
    title: 'Processed Date',
    field: 'processed_date',
    sortable: true,
    sortItem: {
      field: 'processed_date',
      order: 'desc',
    },
  },

  {
    title: 'Amount',
    slotName: 'amountSlot',
    field: 'amount',
    sortable: true,
    class: 'amount',
    sortItem: {
      field: 'amount',
      order: 'desc',
    },
  },
  {
    title: 'Status',
    slotName: 'statusSlot',
    sortable: true,
    field: 'status',
    sortItem: {
      field: 'status',
      order: 'desc',
    },
  },
  {
    title: 'Source',
    field: 'source',
    sortable: true,
    sortItem: {
      field: 'source',
      order: 'desc',
    },
  },
];
