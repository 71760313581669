<script setup>
import { ref, computed, onMounted } from "vue";
import { Tooltip } from "bootstrap";
import { useStore } from "vuex";

//components
import BaseChart from "@commons/components/charts/BaseChart";
import DownloadReportModal from "./Modals/DownloadReportModal.vue";

// helpers
import formatNumber from "@commons/helpers/formatters/formatNumber";

//icons
import ChevronRight from "@/assets/icons/chevron-right.vue";
import ChevronLeft from "@/assets/icons/chevron-left.vue";
import DownloadIcon from "@/assets/icons/download-icon.vue";
import InfoIcon from "@/assets/icons/info-icon.vue";

//styles
import "@/assets/styles/nav_card.scss";

const store = useStore();
const nav = computed(() => store.getters["dashboard/net_asset_value"]._data?.data);

const navLoading = computed(
  () => store.getters["dashboard/net_asset_value"]._status._on_loading
);

const algorithms = computed(() => (nav.value ? nav.value.algorithm_list : []));

const chartSeries = computed(() => {
  if (nav.value && nav.value.chart) {
    return [nav.value.chart.serie1, nav.value.chart.serie2];
  } else {
    return [];
  }
});

const chartOptions = {
  chart: {
    type: "donut",
    width: "200",
  },
  stroke: {
    show: true,
    width: 5,
    colors: ["#1d1e25"],
    lineCap: "round",
  },
  plotOptions: {
    pie: {
      donut: {
        size: "75%",
        labels: {
          show: true,
          total: {
            showAlways: true,
            show: true,

            formatter: function (w) {
              const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
              return formatNumberDynamic(total);
            },
          },
          value: {
            offsetY: "-5",
          },
        },
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    colors: ["#0EB770", "#232429"],
  },
  states: {
    hover: {
      filter: "none",
    },
    active: {
      allowMultipleDataPointsSelection: false,
    },
  },
  selection: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 1025,
      options: {
        chart: {
          height: 170,
        },
        legend: {
          position: "bottom",
        },
      },
    },
    {
      breakpoint: 768,
      options: {
        chart: {
          height: 170,
          width: 125,
        },
      },
    },
    {
      breakpoint: 650,
      options: {
        chart: {
          height: 150,
          width: 105,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              value: {
                fontSize: "12px",
              },
            },
          },
        },
      },
    },
  ],
};

const startIndex = ref(0);
const visibleCount = ref(3);
const selectedAlgorithm = ref("all");

const formatNumberDynamic = (value) => {
  if (value >= 1_000_000) {
    return (value / 1_000_000).toFixed(2) + "M";
  } else if (value >= 1_000) {
    return (value / 1_000).toFixed(2) + "K";
  } else {
    return value.toFixed(2);
  }
};

const visibleAlgorithms = computed(() => {
  if (algorithms.value && algorithms.value.length) {
    return algorithms.value.slice(
      startIndex.value,
      startIndex.value + visibleCount.value
    );
  }
  return [];
});

const prevAlgorithm = () => {
  if (startIndex.value > 0) {
    startIndex.value--;
  }
};

const nextAlgorithm = () => {
  if (startIndex.value < algorithms.value.length - visibleCount.value) {
    startIndex.value++;
  }
};

const selectAlgorithm = (algorithm) => {
  selectedAlgorithm.value = algorithm;
  store.dispatch("dashboard/getNetAssetValue");
};

const setupTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip-nav"]');
  tooltipTriggerList.forEach((el) => {
    const title = el.getAttribute("data-bs-title");
    if (title) {
      new Tooltip(el);
    }
  });
};

onMounted(() => {
  setupTooltips();
});

const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
  }
};
</script>

<template>
  <div class="component_nav_card">
    <div class="card">
      <div class="card-header">
        Net asset value
        <div class="icons">
          <span role="button" @click="modalHandler(true, 'download_report')"
            ><DownloadIcon
          /></span>
          <span
            role="button"
            data-bs-toggle="tooltip-nav"
            data-bs-placement="top"
            data-bs-title="Represents the total net value of the assets in the account, after deducting liabilities."
            ><InfoIcon
          /></span>
        </div>
      </div>
      <div class="card-body">
        <div class="nav">
          <div>
            <BaseChart
              type="donut"
              :options="chartOptions"
              :series="chartSeries"
              height="180"
              width="135"
            />
          </div>
          <div class="nav-values">
            <div>
              <p>Highest</p>
              <p>net asset value</p>
              <span :class="{ skeleton: navLoading }"
                >${{ formatNumber(nav.highest_nav) }}</span
              >
            </div>
            <div>
              <p>Lowest</p>
              <p>net asset value</p>
              <span :class="{ skeleton: navLoading }"
                >${{ formatNumber(nav.lowest_nav) }}</span
              >
            </div>
          </div>
        </div>
        <div class="algo_list">
          <div :class="{ skeleton: navLoading }" class="algorithm-carousel">
            <button
              @click="selectAlgorithm('all')"
              class="all-option"
              :class="{ selected: selectedAlgorithm === 'all' }"
            >
              All
            </button>
            <div class="carousel">
              <button class="prev" @click="prevAlgorithm">
                <ChevronLeft />
              </button>
              <div
                v-for="(algorithm, index) in visibleAlgorithms"
                :key="index"
                @click="selectAlgorithm(algorithm)"
                :class="{ selected: selectedAlgorithm === algorithm }"
              >
                {{ algorithm.name }}
              </div>
              <button class="next" @click="nextAlgorithm">
                <ChevronRight />
              </button>
            </div>
          </div>
        </div>
        <div class="resume" :class="{ skeleton: navLoading }">
          {{ nav.algorithm_name }} accounts for
          <span class="green"> {{ nav.percentage }}%</span> of your
          <span class="green">${{ nav.portfolio }} million portfolio</span>, with a
          current exposure of <span>{{ nav.current_exposure_percentage }}%</span>,
          equivalent to
          <span>${{ nav.current_exposure }}</span>
        </div>
      </div>
    </div>

    <DownloadReportModal />
  </div>
</template>
