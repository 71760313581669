<template>
  <Multiselect
    v-model="innerValue"
    :can-clear="canClear"
    :can-deselect="canDeselect"
    :label="label"
    :options="options"
    :searchable="searchable"
    :placeholder="placeholder"
    :valueProp="valueProp"
    :open-direction="openPlacement"
    ref="commonMultiselect"
  >
    <template v-slot:caret>
      <span class="multiselect-caret" v-if="caret">
        <i class="bi bi-chevron-down"></i>
      </span>
    </template>
  </Multiselect>
</template>

<script>
import { ref, watch } from "vue";
import Multiselect from "@vueform/multiselect";

export default {
  name: "multiselect_input",
  components: {
    Multiselect,
  },
  props: {
    modelValue: [Array, Number, String, Object],
    canDeselect: {
      default: () => false,
      type: [Boolean],
    },
    canClear: {
      default: () => true,
      type: [Boolean],
    },
    caret: {
      default: () => true,
      type: [Boolean],
    },
    label: {
      default: () => "label",
      type: String,
    },
    options: [Array],
    placeholder: {
      default: () => "Select an option",
      type: [String],
    },
    searchable: [Boolean],
    valueProp: {
      default: () => "value",
      type: [String],
    },
    openPlacement: {
      default: () => "bottom",
      type: [String],
    },
  },
  setup(props, { emit }) {
    const innerValue = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newValue) => {
        innerValue.value = newValue;
      }
    );

    watch(innerValue, (newValue) => {
      emit("update:modelValue", newValue);
    });

    return {
      innerValue,
    };
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
