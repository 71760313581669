<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
// components
import AlgorithmCard from "@/components/views/dashboard/AlgorithmCard.vue";
const store = useStore();
const algorithmsData = computed(
  () => store.getters["dashboard/algorithmsList"]._data.data
);
const currentItem = ref(0);
const nextItem = () => {
  if (algorithmsData.value.length - 1 > currentItem.value) {
    currentItem.value = currentItem.value + 1;
  } else {
    currentItem.value = 0;
  }
};
const prevItem = () => {
  if (currentItem.value > 0) {
    currentItem.value = currentItem.value - 1;
  } else {
    currentItem.value = algorithmsData.value.length - 1;
  }
};
onMounted(() => {
  store.dispatch("dashboard/getDashboardAlgorithmsList");
});
</script>
<template>
  <AlgorithmCard
    :algorithm="algorithmsData[currentItem]"
    @nextItem="nextItem"
    @prevItem="prevItem"
  />
</template>
