import { ref, onMounted, onUnmounted } from 'vue';

export function useScreenSize() {
    const isTablet = ref(window.innerWidth <= 1024);
    const isLargeTablet = ref(window.innerWidth <= 1024 && window.innerWidth > 768);
    const isMediumTablet = ref(window.innerWidth <= 768 && window.innerWidth > 600)
    const isSmallTablet = ref(window.innerWidth <= 600 && window.innerWidth > 480)

    const updateIsTablet = () => {
        isTablet.value = window.innerWidth <= 1024;
        isLargeTablet.value = window.innerWidth <= 1024 && window.innerWidth > 768;
        isMediumTablet.value = window.innerWidth <= 768 && window.innerWidth > 600;
        isSmallTablet.value = window.innerWidth <= 600 && window.innerWidth > 480;

    };

    window.addEventListener('resize', updateIsTablet);

    window.addEventListener('unload', () => {
        window.removeEventListener('resize', updateIsTablet);
    });
    return {
        isTablet,
        isLargeTablet,
        isMediumTablet,
        isSmallTablet
    };
}
