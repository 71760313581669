<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
//  components
import BasicModal from "@commons/components/modals/ModalBase.vue";
import Button from "@commons/components/buttons/ButtonComponent.vue";
import DatepickerVue from "@commons/components/inputs/Datepicker.vue";

//composables
import { useCustomToast } from "@/composables/toast/useCustomToast";

//styles
import "@/assets/styles/download_report_modal.scss";

const store = useStore();
const date_range = ref([]);

const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
    resetModal();
  }
};

const downloadReport = async () => {
  try {
    const res = await store.dispatch("dashboard/downloadReport");
    if (res.success) {
      store.dispatch("modals/closeModal");
      useCustomToast({ text: res.data.message, type: "success" });
      return;
    } else {
      useCustomToast({ text: res.data.error, type: "error" });
    }
  } catch (error) {
    console.log(error);
  }
};

const resetModal = () => {
  date_range.value = [];
};
</script>

<template>
  <div class="component_download_report_modal">
    <BasicModal
      headerTitle="Download report"
      name="download_report"
      classNameHeader="card-bg-dark"
      className="download_report"
      :onClose="modalHandler"
    >
      <div class="modal-container">
        <h2>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Iy of type and scrambled it to make a type specimen
          booked.
        </h2>

        <div class="form-group">
          <label>Date range</label>
          <DatepickerVue
            v-model="date_range"
            :clearable="false"
            :range="true"
            id="login_date"
          />
        </div>
      </div>
      <div slot:footer class="modal-footer justify-content-end">
        <div class="btn-container">
          <Button
            text="Cancel"
            type="text"
            className="text-white"
            class="btn-border"
            @click="modalHandler(false)"
          />

          <Button
            type="secondary"
            text="Download"
            @click="downloadReport()"
            :class="{ btn_download: date_range.length }"
            :buttonDisable="!date_range.length"
          />
        </div>
      </div>
    </BasicModal>
  </div>
</template>
