<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clip-path="url(#clip0_135_470)">
      <path
        d="M13.829 13.0044L10.347 9.52245C11.2959 8.36197 11.7624 6.88117 11.6501 5.38636C11.5378 3.89155 10.8552 2.49709 9.74363 1.49142C8.63202 0.485748 7.17638 -0.0541959 5.67783 -0.0167273C4.17928 0.0207412 2.75245 0.632756 1.69248 1.69272C0.632511 2.75269 0.0204971 4.17952 -0.0169715 5.67807C-0.05444 7.17663 0.485504 8.63226 1.49118 9.74388C2.49685 10.8555 3.89131 11.538 5.38612 11.6504C6.88093 11.7627 8.36172 11.2961 9.52221 10.3473L13.0041 13.8292C13.1141 13.9355 13.2615 13.9943 13.4144 13.9929C13.5674 13.9916 13.7137 13.9303 13.8219 13.8221C13.93 13.7139 13.9914 13.5676 13.9927 13.4147C13.994 13.2617 13.9352 13.1144 13.829 13.0044ZM5.83321 10.5001C4.91023 10.5001 4.00797 10.2264 3.24055 9.71364C2.47312 9.20086 1.87498 8.47203 1.52177 7.61931C1.16856 6.76658 1.07614 5.82827 1.25621 4.92303C1.43627 4.01778 1.88073 3.18626 2.53337 2.53362C3.18602 1.88097 4.01754 1.43652 4.92278 1.25645C5.82803 1.07639 6.76634 1.1688 7.61906 1.52201C8.47178 1.87522 9.20062 2.47336 9.7134 3.24079C10.2262 4.00822 10.4999 4.91047 10.4999 5.83345C10.4985 7.0707 10.0064 8.25688 9.1315 9.13175C8.25663 10.0066 7.07046 10.4987 5.83321 10.5001Z"
        fill="#ADAEB0"
      />
    </g>
    <defs>
      <clipPath id="clip0_135_470">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
