<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18 5.99997C17.8125 5.8125 17.5582 5.70718 17.293 5.70718C17.0278 5.70718 16.7735 5.8125 16.586 5.99997L12 10.586L7.414 5.99997C7.22647 5.8125 6.97217 5.70718 6.707 5.70718C6.44184 5.70718 6.18753 5.8125 6 5.99997C5.81253 6.1875 5.70721 6.44181 5.70721 6.70697C5.70721 6.97213 5.81253 7.22644 6 7.41397L10.586 12L6 16.586C5.81253 16.7735 5.70721 17.0278 5.70721 17.293C5.70721 17.5581 5.81253 17.8124 6 18C6.18753 18.1874 6.44184 18.2928 6.707 18.2928C6.97217 18.2928 7.22647 18.1874 7.414 18L12 13.414L16.586 18C16.7735 18.1874 17.0278 18.2928 17.293 18.2928C17.5582 18.2928 17.8125 18.1874 18 18C18.1875 17.8124 18.2928 17.5581 18.2928 17.293C18.2928 17.0278 18.1875 16.7735 18 16.586L13.414 12L18 7.41397C18.1875 7.22644 18.2928 6.97213 18.2928 6.70697C18.2928 6.44181 18.1875 6.1875 18 5.99997Z"
      fill="currentColor"
    />
  </svg>
</template>
