import { helpers } from '@vuelidate/validators';

export const allocateRules = (balance) => {
  return {
    utilizedAmount: {
      min: helpers.withMessage(
        'You do not have enough funds to invest in this algorithm. Please add sufficient funds to your wallet before proceeding.',
        (value) => {
          return value <= balance;
        },
      ),
    },
  };
};
export const increaseAllocateRules = (balance) => {
  return {
    utilizedAmount: {
      min: helpers.withMessage(
        'You do not have enough funds to increase your investment in the algorithm. Please add more funds to your wallet before proceeding.',
        (value) => {
          return value <= balance;
        },
      ),
    },
  };
};
export const reduceAllocateRules = (balance) => {
  return {
    utilizedAmount: {
      min: helpers.withMessage(
        `You can reduce upto ${balance} of your investment from the algorithm.`,
        (value) => {
          return value <= balance;
        },
      ),
    },
  };
};
