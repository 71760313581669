<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.62491 8.93192C6.56633 8.87382 6.51984 8.80469 6.48811 8.72853C6.45637 8.65237 6.44004 8.57067 6.44004 8.48817C6.44004 8.40566 6.45637 8.32397 6.48811 8.24781C6.51984 8.17164 6.56633 8.10252 6.62491 8.04442L9.49366 5.18192C9.55224 5.12381 9.59874 5.05469 9.63047 4.97853C9.6622 4.90236 9.67854 4.82067 9.67854 4.73817C9.67854 4.65566 9.6622 4.57397 9.63047 4.49781C9.59874 4.42164 9.55224 4.35252 9.49366 4.29442C9.37656 4.17801 9.21815 4.11267 9.05304 4.11267C8.88792 4.11267 8.72951 4.17801 8.61241 4.29442L5.74366 7.16317C5.39254 7.51473 5.19531 7.99129 5.19531 8.48817C5.19531 8.98504 5.39254 9.4616 5.74366 9.81317L8.61241 12.6819C8.72883 12.7974 8.88595 12.8625 9.04991 12.8632C9.13217 12.8636 9.21371 12.8479 9.28985 12.8168C9.366 12.7857 9.43526 12.7398 9.49366 12.6819C9.55224 12.6238 9.59874 12.5547 9.63047 12.4785C9.6622 12.4024 9.67854 12.3207 9.67854 12.2382C9.67854 12.1557 9.6622 12.074 9.63047 11.9978C9.59874 11.9216 9.55224 11.8525 9.49366 11.7944L6.62491 8.93192Z"
      fill="currentColor"
    />
  </svg>
</template>
