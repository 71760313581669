<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M6.18341 7.41422C6.12874 7.35999 6.08534 7.29548 6.05573 7.22439C6.02611 7.15331 6.01087 7.07706 6.01087 7.00006C6.01087 6.92305 6.02611 6.8468 6.05573 6.77572C6.08534 6.70463 6.12874 6.64012 6.18341 6.58589L8.86091 3.91422C8.91559 3.85999 8.95899 3.79548 8.9886 3.72439C9.01822 3.65331 9.03346 3.57706 9.03346 3.50006C9.03346 3.42305 9.01822 3.3468 8.9886 3.27572C8.95899 3.20463 8.91559 3.14012 8.86091 3.08589C8.75162 2.97724 8.60377 2.91626 8.44966 2.91626C8.29556 2.91626 8.14771 2.97724 8.03841 3.08589L5.36091 5.76339C5.0332 6.09152 4.84912 6.5363 4.84912 7.00006C4.84912 7.46381 5.0332 7.9086 5.36091 8.23672L8.03841 10.9142C8.14707 11.022 8.29372 11.0827 8.44675 11.0834C8.52352 11.0838 8.59962 11.0691 8.67069 11.0401C8.74177 11.0111 8.80641 10.9683 8.86091 10.9142C8.91559 10.86 8.95899 10.7955 8.9886 10.7244C9.01822 10.6533 9.03346 10.5771 9.03346 10.5001C9.03346 10.423 9.01822 10.3468 8.9886 10.2757C8.95899 10.2046 8.91559 10.1401 8.86091 10.0859L6.18341 7.41422Z"
      fill="currentColor"
    />
  </svg>
</template>
