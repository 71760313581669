import generateRandomNumber from '@commons/helpers/generators/generateRandomNumber';

const successfullChangePhoneNumber = () => ({
  success: true,
  data: {
    message: 'Phone number authentication has been successfully updated.',
  },
});
const failedChangePhoneNumber = () => ({
  success: false,
  data: {
    message:
      'Failed to update phone number authentication. Please check the provided details and try again.',
  },
});
const changePhoneNumberScenario = [successfullChangePhoneNumber, failedChangePhoneNumber];
export const changePhoneNumber = () => {
  const rand = generateRandomNumber(0, changePhoneNumberScenario.length);
  if (typeof changePhoneNumberScenario[rand] !== 'undefined') {
    return changePhoneNumberScenario[rand]();
  }
  return successfullChangePhoneNumber();
};
