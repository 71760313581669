import { walletUrls } from '@/api/apiUrls';
import { httpMethods } from '../../config/constants';
import http from '@/helpers/http';

// Adapters
import adapterAddWalletAddress from './adapters/addWalletAddress';
import adapterGetPortfolio from './adapters/getPortfolio';
import adapterGetTotalBalance from './adapters/getTotalBalance';
import adapterGetWalletList from './adapters/getWalletList';
import adapterGetWalletSelectorList from './adapters/getWalletSelectorList';
import adapterDeleteWallet from './adapters/deleteWallet';
import adapterMarkAsDefault from './adapters/markAsDefault';
import adapterWithdrawWallet from './adapters/withdrawWallet';

// Mockdata
import addWalletAddressMock from './mocks/addWalletAddress';
import editWalletAddressMock from './mocks/editWalletAddress';
import deleteWalletMock from './mocks/deleteWallet';
import getPortfolioMock from './mocks/getPortfolio';
import getTotalBalanceMock from './mocks/getTotalBalance';
import getWalletListMock from './mocks/getWalletList';
import getWalletSelectorListMock from './mocks/getWalletSelectorList';
import markAsDefaultMock from './mocks/markAsDefault';

import { getWithdrawWalletMock } from './mocks/withdrawWallet';

const getPortfolioService = async ({ params }) => {
  return await http({
    method: httpMethods.get,
    urlService: walletUrls.getPortfolio(),
    params,
    mockdata: getPortfolioMock,
    adapter: adapterGetPortfolio,
    debug: false,
  });
};

const getTotalBalanceService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: walletUrls.getTotalBalance(),
    mockdata: getTotalBalanceMock,
    adapter: adapterGetTotalBalance,
    debug: false,
  });
};

const getWalletListService = async ({ params }) => {
  return await http({
    method: httpMethods.get,
    urlService: walletUrls.getWalletList(),
    params,
    mockdata: getWalletListMock,
    adapter: adapterGetWalletList,
    debug: false,
  });
};

const getWalletSelectorListService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: walletUrls.getWalletSelectorList(),
    mockdata: getWalletSelectorListMock,
    adapter: adapterGetWalletSelectorList,
    debug: true,
  });
};

const withdrawService = async (body) => {
  return await http({
    method: httpMethods.post,
    urlService: walletUrls.withdrawWallet(),
    body,
    mockdata: getWithdrawWalletMock,
    adapter: adapterWithdrawWallet,
    debug: true,
  });
};

const deleteService = async () => {
  return await http({
    method: httpMethods.delete,
    urlService: walletUrls.deleteWallet(),
    mockdata: deleteWalletMock,
    adapter: adapterDeleteWallet,
    debug: false,
  });
};

const deleteByIdService = async (id) => {
  return await http({
    method: httpMethods.delete,
    urlService: walletUrls.deleteWalletById(id),
    mockdata: deleteWalletMock,
    adapter: adapterDeleteWallet,
    debug: false,
  });
};

const markAsDefaultService = async (id) => {
  return await http({
    method: httpMethods.delete,
    urlService: walletUrls.markAsDefault(id),
    mockdata: markAsDefaultMock,
    adapter: adapterMarkAsDefault,
    debug: true,
  });
};

const addWalletAddressService = async ({ body }) => {
  return await http({
    method: httpMethods.post,
    urlService: walletUrls.addWalletAddress(),
    mockdata: addWalletAddressMock,
    adapter: adapterAddWalletAddress,
    body,
    debug: false,
  });
};

const editWalletAddressService = async ({ body }) => {
  return await http({
    method: httpMethods.put,
    urlService: walletUrls.editWalletAddress(body.id),
    mockdata: editWalletAddressMock,
    adapter: adapterAddWalletAddress,
    body: {
      address: body.address,
    },
    debug: false,
  });
};

export {
  getPortfolioService,
  getTotalBalanceService,
  getWalletListService,
  getWalletSelectorListService,
  withdrawService,
  deleteService,
  deleteByIdService,
  markAsDefaultService,
  addWalletAddressService,
  editWalletAddressService,
};
