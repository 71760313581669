import { verificationStatus } from '@/config/constants';
import generateRandomNumber from '@commons/helpers/generators/generateRandomNumber';

export default () => ({
  success: true,
  data: {
    verification_status: verificationStatus[generateRandomNumber(0, 2)],
    username: 'Jane Doe',
    country: 'United States of America',
    phone_number: '971*****123',
    email: 'ger***@gmail.com',
    user_id: '000147894564',
  },
});
