<script setup>
import _ from "lodash";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
//  components
import BasicModal from "@commons/components/modals/ModalBase.vue";
import Button from "@commons/components/buttons/ButtonComponent.vue";
import DatepickerVue from "@commons/components/inputs/Datepicker.vue";
import DollarCurrencyIcon from "@/assets/icons/dollar-curr-icon.vue";
import InputGroup from "@commons/components/inputs/InputGroup.vue";
import Multiselect from "@commons/components/inputs/Multiselect.vue";
// icons
import SearchIcon from "@/assets/icons/search-icon.vue";
// styles
import "@/assets/styles/transaction_filters.scss";
import formatDate from "@commons/helpers/formatters/formatDate";

const store = useStore();
const emit = defineEmits(["fetchByFilters"]);
const transactionListCount = computed(
  () => store.getters["dashboard/transactionListCount"]._data.paginate.records
);
const transactionListCountLoading = computed(
  () => store.getters["dashboard/transactionListCount"]._status._on_loading
);
const statusLoading = computed(
  () => store.state["dashboard"]["transactionList"]._status._on_loading
);
const filters = ref({
  search: "",
  type: "",
  from: "",
  to: "",
  request_date: "",
  processed_date: "",
  status: "",
  amount: "",
});

const disableButton = computed(() => {
  return !!(
    filters.value.search ||
    filters.value.type ||
    filters.value.from ||
    filters.value.to ||
    filters.value.request_date ||
    filters.value.processed_date ||
    filters.value.status ||
    filters.value.amount
  );
});

const filterTransactions = async () => {
  try {
    const filterValues = {
      search: filters.value.search,
      type: filters.value.type,
      from: filters.value.from,
      to: filters.value.to,
      status: filters.value.status,
      amount: filters.value.amount,
      processed_date_start:
        filters.value.processed_date.length > 0
          ? formatDate(filters.value.processed_date[0], "YYYY/MM/DD")
          : null,
      processed_date_end:
        filters.value.processed_date.length > 0
          ? formatDate(filters.value.processed_date[1], "YYYY/MM/DD")
          : null,
      request_date_start:
        filters.value.request_date.length > 0
          ? formatDate(filters.value.request_date[0], "YYYY/MM/DD")
          : null,
      request_date_end:
        filters.value.request_date.length > 0
          ? formatDate(filters.value.request_date[1], "YYYY/MM/DD")
          : null,
    };
    emit("fetchByFilters", filterValues);
  } catch (error) {
    console.log(error);
  }
};

const modalHandler = (open = false, modalName) => {
  if (open) {
    store.dispatch("modals/openModal", modalName);
  } else {
    store.dispatch("modals/closeModal");
  }
};

const resetFilters = () => {
  filters.value = {
    search: "",
    type: "",
    from: "",
    to: "",
    request_date: "",
    processed_date: "",
    status: "",
    amount: "",
  };
  emit("fetchByFilters", filters.value);
};
const getTransactionListCount = () => {
  store.dispatch("dashboard/getTransactionListCount", {
    page: 1,
    per_page: 1,
    search: filters.value.search,
    type: filters.value.type,
    from: filters.value.from,
    to: filters.value.to,
    status: filters.value.status,
    amount: filters.value.amount,
    processed_date_start:
      filters.value.processed_date.length > 0
        ? formatDate(filters.value.processed_date[0], "YYYY/MM/DD").toString()
        : null,
    processed_date_end:
      filters.value.processed_date.length > 0
        ? formatDate(filters.value.processed_date[1], "YYYY/MM/DD").toString()
        : null,
    request_date_start:
      filters.value.request_date.length > 0
        ? formatDate(filters.value.request_date[0], "YYYY/MM/DD").toString()
        : null,
    request_date_end:
      filters.value.request_date.length > 0
        ? formatDate(filters.value.request_date[1], "YYYY/MM/DD").toString()
        : null,
  });
};
const getTransactionListCountDebounce = _.debounce(getTransactionListCount, 300);
watch(() => filters.value, getTransactionListCountDebounce, {
  immediate: true,
  deep: true,
});
</script>

<template>
  <div class="component-transaction-filters">
    <BasicModal
      headerTitle="Filters"
      name="transaction_filters"
      classNameHeader="card-bg-dark"
      className="transaction_filters"
      :onClose="modalHandler"
    >
      <div class="modal-container">
        <div class="form">
          <InputGroup
            v-model="filters.search"
            placeholder="Search"
            id="search"
            iconPosition="left"
            inputGroupClass="mb-0"
          >
            <template #inputIcon>
              <SearchIcon />
            </template>
          </InputGroup>
          <div class="inputs">
            <div class="form-group type">
              <label>Type</label>
              <Multiselect
                v-model="filters.type"
                :can-clear="false"
                class="form-control"
                type="number"
                placeholder="Select type"
                :options="['Deposit', 'Withdrawal', 'Transfer in', 'Transfer out']"
              />
            </div>

            <div class="inputs-50">
              <div class="form-group">
                <label>From</label>
                <Multiselect
                  v-model="filters.from"
                  :can-clear="false"
                  class="form-control"
                  type="number"
                  placeholder="from"
                  :options="['wallet']"
                />
              </div>
              <div class="form-group">
                <label>To</label>
                <Multiselect
                  v-model="filters.to"
                  :can-clear="false"
                  class="form-control"
                  type="number"
                  placeholder="to"
                  :options="['BWFS']"
                />
              </div>
            </div>
          </div>
          <div class="inputs">
            <div class="form-group">
              <label>Request date</label>
              <DatepickerVue
                v-model="filters.request_date"
                :clearable="false"
                :range="true"
              />
            </div>
            <div class="form-group">
              <label>Processed date</label>
              <DatepickerVue
                v-model="filters.processed_date"
                :clearable="false"
                :range="true"
              />
            </div>
          </div>
          <div class="inputs">
            <div class="form-group">
              <label>Status</label>
              <Multiselect
                v-model="filters.status"
                :can-clear="false"
                class="form-control"
                type="number"
                placeholder="Select status"
                :options="['Success', 'Pending', 'Rejected']"
              />
            </div>

            <div class="form-group">
              <label>Amount</label>
              <InputGroup
                v-model="filters.amount"
                placeholder="0.00"
                iconPosition="left"
                inputGroupClass="mb-0"
              >
                <template #inputIcon>
                  <DollarCurrencyIcon />
                </template>
              </InputGroup>
            </div>
          </div>
        </div>

        <div class="divider"></div>
        <div class="bottom_sec">
          <div class="title_bottom_sec">Search results</div>
          <div class="text_bottom_sec">
            According to the filters you selected, we found
            <span :class="{ skeleton: transactionListCountLoading }">{{
              transactionListCount
            }}</span>
            results that match your criteria.
          </div>
        </div>
      </div>
      <div slot:footer class="modal-footer">
        <Button text="Clear all" type="text" className="text_btn" @click="resetFilters" />

        <Button
          text="Cancel"
          type="text"
          className="text-white"
          class="btn-border"
          @click="modalHandler(false)"
        />

        <Button
          type="secondary"
          text="Apply"
          @click="filterTransactions"
          :isLoading="statusLoading"
          class="btn_filter"
          :buttonDisable="!disableButton"
        />
      </div>
    </BasicModal>
  </div>
</template>
