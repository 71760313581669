<script setup>
import { computed, onMounted } from "vue";
import { Tooltip } from "bootstrap";
import { useStore } from "vuex";

//components
import BaseChart from "@commons/components/charts/BaseChart";

//helpers
import formatNumber from "@commons/helpers/formatters/formatNumber";

//icons
import ArrowIncrease from "@/assets/icons/arrow-increase.vue";
import InfoIcon from "@/assets/icons/info-icon.vue";

//styles
import "@/assets/styles/performance_card.scss";

const store = useStore();
const performance = computed(() => store.getters["dashboard/performance"]._data?.data);

const chartSeries = computed(() => {
  return performance.value && performance.value.radial_bar_percentage
    ? [performance.value.radial_bar_percentage]
    : [];
});
const chartOptions = {
  chart: {
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      inverseOrder: false,
      startAngle: 0,
      endAngle: 360,
      offsetX: 0,
      offsetY: 0,
      hollow: {
        size: "75%",
        background: "transparent",
        margin: 0,
      },
      track: {
        show: true,
        startAngle: undefined,
        endAngle: undefined,
        background: "#232429",
        strokeWidth: "100%",
        opacity: 1,
        margin: 0,
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 0,
          opacity: 0,
        },
      },
      dataLabels: {
        show: true,
        name: {
          show: false,
        },
        value: {
          show: true,
          fontSize: "18px",
          fontFamily: "Roboto",
          fontWeight: 700,
          color: "#fff",
          offsetY: 10,
          formatter: function (val) {
            return val + "%";
          },
        },
      },
    },
  },
  responsive: [
    {
      breakpoint: 1025,
      options: {
        chart: {
          height: 120,
          width: 80,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "80%",
              margin: 0,
            },
            dataLabels: {
              value: {
                fontSize: "14px",
                fontWeight: 700,
                offsetY: 5,
              },
            },
          },
        },
      },
    },
  ],
  fill: {
    colors: ["#0EB770"],
  },
  stroke: {
    lineCap: "round",
  },
};
const emit = defineEmits(["focus-nav-card"]);

const performanceLoading = computed(
  () => store.getters["dashboard/performance"]._status._on_loading
);

const setupTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip-performance"]'
  );
  tooltipTriggerList.forEach((el) => {
    const title = el.getAttribute("data-bs-title");
    if (title) {
      new Tooltip(el);
    }
  });
};

const focusNavCard = () => {
  emit("focus-nav-card");
};

onMounted(() => {
  setupTooltips();
});
</script>

<template>
  <div class="component_performance_card">
    <div class="card">
      <div class="d-flex justify-content-end align-items-center">
        <span
          class="info-tooltip"
          role="button"
          data-bs-toggle="tooltip-performance"
          data-bs-placement="top"
          data-bs-title="Measures the total performance of the account, including all investments and strategies."
          ><InfoIcon
        /></span>
      </div>

      <div class="card-header">Performance</div>
      <p class="info-desc-card">Performance since investment inception</p>
      <div class="card-body">
        <div class="performance-card-container">
          <div class="info">
            <p class="info-desc">Performance since investment inception</p>
            <div class="value">
              <p :class="{ skeleton: performanceLoading }">
                ${{ formatNumber(performance.performance) }}
              </p>
              <div>
                <span :class="{ skeleton: performanceLoading }"
                  >{{ performance.performance_percentage }}%</span
                >
                <ArrowIncrease v-if="!performanceLoading" />
              </div>
            </div>
            <a @click="focusNavCard">Detail</a>
          </div>
          <div class="chart">
            <BaseChart
              type="radialBar"
              height="150"
              width="110"
              :options="chartOptions"
              :series="chartSeries"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
