<script setup>
import { computed, useSlots } from "vue";
import { useStore } from "vuex";

import IconClose from "../../assets/images/icons/icon-close.vue";

const props = defineProps({
  name: String,
  displayCloseFromHeader: {
    default: () => true,
    type: Boolean,
  },
  canCloseFromHeader: {
    default: () => true,
    type: Boolean,
  },
  classNameBody: String,
  classNameContent: String,
  classNameFooter: String,
  classNameHeader: String,
  className: String,
  headerTitle: String,
  isCentered: {
    default: () => true,
    type: Boolean,
  },
  onClose: Function,
});

const store = useStore();
const slots = useSlots();
const isVisible = computed(() => {
  const currentModal = store.getters["modals/currentModal"];
  return currentModal && currentModal.name === props.name;
});

const close = () => {
  if (props.canCloseFromHeader) store.dispatch("modals/closeModal");
  props.onClose?.();
};
const noHeader = computed(() => !props.headerTitle && !slots.header);
</script>

<template>
  <Teleport to="body">
    <transition name="modal">
      <div
        class="modal fade show"
        :class="className"
        :style="{ display: isVisible && 'block' }"
        v-if="isVisible"
      >
        <div class="modal-dialog" :class="`${isCentered ? 'modal-dialog-centered' : ''}`">
          <div class="modal-content" :class="classNameContent">
            <div
              :class="[
                'modal-header',
                { 'justify-content-end': noHeader },
                classNameHeader,
              ]"
            >
              <template v-if="$slots.header">
                <slot name="header"></slot>
              </template>
              <template v-else-if="headerTitle">
                <h5 class="modal-title">{{ headerTitle }}</h5>
              </template>
              <div
                class="close"
                type="button"
                @click="close"
                v-if="displayCloseFromHeader"
              >
                <IconClose />
              </div>
            </div>
            <div class="modal-body" :class="classNameBody">
              <slot></slot>
            </div>
            <div class="modal-footer" v-if="$slots.footer" :class="classNameFooter">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
